import { Flex, Box } from '@chakra-ui/react';
import MainContainer from '../UI/MainContainer/MainContainer';
import styles from './index.module.scss';
import { useTheme } from '../../hooks/useTheme';
import { Deposit } from './Deposit';
import { Readme } from './Readme';
import React from 'react';

export function EarlyBirdComponent() {
  return (
    <>
      <Box className={useTheme(styles.container)}>
        <MainContainer className={styles.contentContainer}>
          <Flex className={styles.mainFlex}>
            <Deposit />
            <Box className={styles.depositSkeleton} />
            <Readme />
          </Flex>
        </MainContainer>
      </Box>
    </>
  );
}
