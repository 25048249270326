import React from 'react';
import { Box } from '@chakra-ui/react';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import { TextCarouselItem } from './TextCarouselItem';
// import { PriceApiResult } from '../../types';

type whyUsIntroType = {
  imgSrc: string;
  title: string;
  intro: string;
  desc: string;
};

type Prop = {
  items: Array<whyUsIntroType>;
};

export function TextCarousel({ items }: Prop) {
  const [sliderRef] = useKeenSlider(
    {
      loop: true,
      drag: false
      // vertical: true
    },
    [
      (slider) => {
        let timeout: number | NodeJS.Timeout | undefined;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          // if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 2500);
        }
        slider.on('created', () => {
          // slider.container.addEventListener('mouseover', () => {
          //   mouseOver = true;
          //   clearNextTimeout();
          // });
          // slider.container.addEventListener('mouseout', () => {
          //   mouseOver = false;
          //   nextTimeout();
          // });
          nextTimeout();
        });
        slider.on('dragStarted', clearNextTimeout);
        slider.on('animationEnded', nextTimeout);
        slider.on('updated', nextTimeout);
      }
    ]
  );

  return (
    <Box ref={sliderRef} className="keen-slider">
      {[...items, ...items].map((item, idx) => (
        <TextCarouselItem key={idx} item={item} />
      ))}
    </Box>
  );
}
