import {
  Box,
  FormControl,
  FormErrorMessage,
  Grid,
  Input,
  InputGroup,
  InputRightElement,
  useToast
} from '@chakra-ui/react';
import styles from './Withdraw.module.scss';
import { CommonBox } from '../../components/UI/CommonBox';
import { useMemo, useState } from 'react';
import { CommonButton } from '../../components/UI/CommonButton';
import { useTranslation } from 'react-i18next';
import { requestApiGate } from '../../service/common';
import { useWallet } from '../../store/wallet-context';
import { useQuery } from '@tanstack/react-query';
import { requestBalance } from '../../service/balance';
import { requestWithdraw } from '../../service/chain';
import { formatEther, parseEther } from 'ethers/lib/utils.js';
import { truncateNumber } from '../../utils/utils';

export function Withdraw({ afterWithdraw }: { afterWithdraw?: () => void }) {
  //@ts-ignore
  const { t } = useTranslation();

  const [count, setCount] = useState('');

  const [loading, setLoading] = useState(false);

  const toast = useToast();

  const { accountAddress, isConnected } = useWallet();

  const { isSuccess: userQuerySuccess, data: userQueryData } = useQuery({
    queryKey: ['userQuery', accountAddress],
    queryFn: () => {
      return requestApiGate();
    },
    enabled: isConnected
  });

  const {
    isSuccess: balanceQuerySuccess,
    data: balanceQueryData,
    refetch: refetchBalanceQuery
  } = useQuery({
    queryKey: ['balance'],
    queryFn: () => {
      return requestBalance([userQueryData.usdt.tokenId]);
    },
    enabled: isConnected && userQuerySuccess
  });

  const balance = useMemo(() => {
    return balanceQueryData?.balances[0]?.balance
      ? truncateNumber(formatEther(balanceQueryData?.balances[0]?.balance), 2)
      : '0';
  }, [balanceQueryData?.balances[0]?.balance]);

  const insufficientError = useMemo(() => {
    return balance && count && +count > +balance;
  }, [count, balance]);

  const withdraw = async () => {
    if (!balanceQuerySuccess) {
      console.error('balanceQuerySuccess failed');
      return;
    }
    if (+count <= 0) {
      toast({
        title: t('Please input amount', 'Please input amount'),
        status: 'error',
        duration: 3000,
        isClosable: true
      });
      return;
    }
    try {
      setLoading(true);
      await requestWithdraw(userQueryData?.usdt.id, {
        amount: parseEther(count).toString(),
        toWallet: accountAddress
      });
      await refetchBalanceQuery();
      if (afterWithdraw) {
        await afterWithdraw();
      }
      setLoading(false);
      toast({
        title: t(
          'Withdraw successfully, please check your wallet later',
          'Withdraw successfully, please check your wallet later'
        ),
        status: 'success',
        duration: 3000,
        isClosable: true
      });
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  return (
    <Grid rowGap="10px">
      <Box className={styles.title}>{t('Withdraw', 'Withdraw')}</Box>
      <CommonBox className={styles.accountBalance}>
        {t('Account Balance', 'Account Balance')}:
        <Box as="span" className={styles.balance}>
          {balance} USDT
        </Box>
      </CommonBox>
      <FormControl isInvalid={insufficientError}>
        <InputGroup>
          <Input
            type="number"
            placeholder={t('Input withdraw amount', 'Input withdraw amount')}
            className={styles.input}
            borderRadius="1rem"
            borderColor="#23262F"
            value={count}
            onChange={(e) => setCount(e.target.value)}
          />
          <InputRightElement className={styles.max} onClick={() => setCount(balance)}>
            {t('Max', 'Max')}
          </InputRightElement>
        </InputGroup>
        {!!insufficientError && (
          <FormErrorMessage>{t('Insufficient balance', 'Insufficient balance')}</FormErrorMessage>
        )}
      </FormControl>
      <CommonButton
        name={t('Withdraw', 'Withdraw')}
        disabled={insufficientError || !count}
        loading={loading}
        thick
        className={styles.withdrawButton}
        handleClick={withdraw}
      />
    </Grid>
  );
}
