import React from 'react';
import { useTheme } from '../../hooks/useTheme';
import MainContainer from '../UI/MainContainer/MainContainer';
import styles from './Application.module.scss';
import ApplicationCard from './ApplicationCard';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Application = () => {
  //@ts-ignore
  const { t } = useTranslation();
  const applicationList = [
    {
      imgSrc: '/assets/logo.mp4',
      title: t('Prosper Node', 'Prosper Node'),
      desc: t(
        'Join ProsperEX\'s next big leap. Embrace opportunity, innovation, and a shared vision of prosperity. Be more than just a participant; become a pioneer. Dive in, and let\'s prosper together!',
        'Join ProsperEX\'s next big leap. Embrace opportunity, innovation, and a shared vision of prosperity. Be more than just a participant; become a pioneer. Dive in, and let\'s prosper together!'
      ),
      buttonName: t('Get node now', 'Get node now')
    },
    {
      imgSrc: require('../../assets/image/application/affiliatePrograms.png'),
      title: t('Affiliate Programs', 'Affiliate Programs'),
      desc: t(
        'A universal decentralized exchange, blending DeFi and AI, designed to empower leveraged trading across all assets with unparalleled security and user experience.',
        'A universal decentralized exchange, blending DeFi and AI, designed to empower leveraged trading across all assets with unparalleled security and user experience.'
      ),
      buttonName: t('Coming Soon', 'Coming Soon')
    }
  ];

  const navigate = useNavigate();
  return (
    <div className={useTheme(styles.applicationWrapper)}>
      <MainContainer className={styles.applicationContainer}>
        <ApplicationCard item={applicationList[0]} buttonEnabled onClick={() => navigate('node-sale')} isProsperNode />
        <ApplicationCard item={applicationList[1]} />
      </MainContainer>
    </div>
  );
};

export default Application;
