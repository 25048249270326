import React from 'react';
import { Box } from '@chakra-ui/react';
// import NodeSaleComponent from '../../components/NodeSaleComponent';
import NewNodeSaleComponent from '../../components/NewNodeSaleComponent';

export default function NodeSale() {
  return (
    <Box>
      {/* <NodeSaleComponent /> */}
      <NewNodeSaleComponent />
    </Box>
  );
}
