import { useState, useEffect } from 'react';
import { OtcBill } from '../../utils/types';
import { useContracts } from '../useContracts';
import { formatter } from './utils';

export const useOtcBills = () => {
  const [bills, setBills] = useState<OtcBill[]>([]);
  const contracts = useContracts();
  const manager = contracts?.manager;
  const managerAddress = manager?.address;
  const fetchBills = async () => {
    const manager = contracts?.manager;
    if (!manager) return;
    const bills = await manager.orderBookUsdtForPpe();
    const formattedBills = bills.map(formatter);
    setBills(
      formattedBills
        .filter((bill) => bill.status === 'Listing')
        .sort((a, b) => parseFloat(b.buyPrice) - parseFloat(a.buyPrice))
    );
  };
  useEffect(() => {
    fetchBills();
  }, [managerAddress]);
  return { bills, fetchBills };
};
