import React from 'react';
import { Box, Divider, Flex, Img } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../hooks/useTheme';
import styles from './index.module.scss';
import { useOtcHistorys } from '../../../hooks/otc/useOtcHistory';
import { CommonInput } from '../../../components/UI/CommonInput';
import ppeIcon from '../../../assets/image/otc/ppe.png';
import menuIcon from '../../../assets/image/otc/menu.png';
import { ReactComponent as WarningIcon } from '../../../assets/image/otc/warning.svg';
import { CommonButton } from '../../../components/UI/CommonButton';
import { useCreateOrder } from '../../../hooks/otc/useCreateOrder';
import { formatEther, parseEther } from 'ethers/lib/utils.js';
import { Approvable } from '../../../components/UI/Approvable';
import { useContracts } from '../../../hooks/useContracts';
import { useTokens } from '../../../hooks/useTokens';
import { truncateNumber } from '../../../utils/utils';
import { BigNumber } from 'ethers';
import { useLatesOtcPrice } from '../../../hooks/otc/useLatesOtcPrice';
import { useMessage } from '../../../hooks/useToast';

export default function CreateOrder() {
  //@ts-ignore
  const { t } = useTranslation();
  const makeOrder = useCreateOrder();
  const message = useMessage();
  const contracts = useContracts();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { price: ppeLastPrice } = useLatesOtcPrice();
  const ppePrice = ppeLastPrice || '-';
  const tokenQueries = useTokens();
  const usdtBalance = tokenQueries.isSuccess ? truncateNumber(formatEther(tokenQueries.data.usdt.balance)) : '0';
  const histories = useOtcHistorys();
  const handleGoToOtcHome = () => {
    window.location.href = '/otc?page=home';
  };

  const handleGoToHistory = () => {
    window.location.href = '/otc?page=history';
  };

  const [inputPrice, setInputPrice] = React.useState<string>('');
  const [inputAmount, setInputAmount] = React.useState<string>('');
  const [inputMode, setInputMode] = React.useState<'ppe' | 'usdt'>('ppe');

  let orderPrice = BigNumber.from(parseEther(inputPrice || '0'));
  let orderAmount = BigNumber.from(0);
  let orderTotal = BigNumber.from(0);

  if (inputMode === 'ppe') {
    orderAmount = BigNumber.from(parseEther(inputAmount || '0'));
    orderTotal = orderPrice.mul(orderAmount).div(parseEther('1'));
  } else if (inputMode === 'usdt') {
    orderTotal = BigNumber.from(parseEther(inputAmount || '0'));
    orderAmount = orderPrice.gt(0) ? orderTotal.mul(parseEther('1')).div(orderPrice) : BigNumber.from(0);
  }

  const handleToggleInputMode = () => {
    setInputMode(inputMode === 'ppe' ? 'usdt' : 'ppe');
  };

  const createOrder = async () => {
    console.log('createOrder');
    setIsLoading(true);
    try {
      const tx = await makeOrder(orderTotal, orderAmount);
      const receipt = await tx.wait();
      message.success(t('Create order successfully', 'Create order successfully'));
      setIsLoading(false);
    } catch (error) {
      message.error(t('Create order failed', 'Create order failed'));
      setIsLoading(false);
    }
  };

  return (
    <Flex className={useTheme(styles.container)}>
      <Flex className={styles.back}>
        <Box onClick={handleGoToOtcHome}>
          {'< '} {t('Back', 'Back')}
        </Box>
      </Flex>
      <Flex className={styles.contentWrapper}>
        <Flex justifyContent="space-between" marginBottom={4}>
          <Flex alignItems="center">
            <Img className={styles.ppeIcon} src={ppeIcon} />
            <Box fontWeight={600} fontSize={'24px'}>
              {t('PPE', 'PPE')}
            </Box>
          </Flex>
          <Img className={styles.menuIcon} src={menuIcon} onClick={handleGoToHistory} />
        </Flex>
        <Flex className={styles.contentCard}>
          <Flex justifyContent="space-between" mb={4}>
            <Box>{t('Price', 'Price')}</Box>
            <Box>1 PPE ≈ {ppePrice} USDT</Box>
          </Flex>
          <CommonInput value={inputPrice} onChange={(e) => setInputPrice(e.target.value)} type="number" />
          <Flex my={4}>
            <Box>{t('Amount', 'Amount')}: </Box>
            <Box className={styles.inputMode} onClick={handleToggleInputMode}>
              {inputMode === 'ppe' ? 'PPE' : 'USDT'}
            </Box>
          </Flex>
          <CommonInput value={inputAmount} onChange={(e) => setInputAmount(e.target.value)} type="number" />
          <Box className={styles.usdtBalance}>Balance: {usdtBalance} USDT</Box>

          <Divider mb={4} />
          <Flex justifyContent="space-between">
            <Box>{t('Order Price', 'Order Price')}: </Box>
            <Box>{truncateNumber(formatEther(orderPrice))}</Box>
          </Flex>
          <Flex justifyContent="space-between" my={4} alignItems="center">
            <Box>{t('Order Amount', 'Order Amount')}: </Box>
            <Box>{truncateNumber(formatEther(orderAmount))}</Box>
          </Flex>
          <Flex justifyContent="space-between" mb={4}>
            <Box>{t('Order Total', 'Order Total')}: </Box>
            <Box>{truncateNumber(formatEther(orderTotal))}</Box>
          </Flex>
          <Approvable
            tokenContract={contracts.usdt}
            needAmount={parseEther(inputAmount || '0')}
            tokenName="USDT"
            className={styles.createBtn}>
            <CommonButton
              name={t('Create Order', 'Create Order')}
              className={styles.createBtn}
              handleClick={createOrder}
              loading={isLoading}
            />
          </Approvable>
        </Flex>

        <Flex className={styles.noticeCard}>
          <Flex>
            <WarningIcon />
            <Box fontWeight={700} fontSize="24px" ml={2}>
              {t('Notice', 'Notice')}
            </Box>
          </Flex>
          <Flex>
            {t(
              'Please note that transactions involving digital assets are final and irreversible. Double-check the amount, price, and recipient address before initiating a transaction, as errors cannot be rectified once confirmed.',
              'Please note that transactions involving digital assets are final and irreversible. Double-check the amount, price, and recipient address before initiating a transaction, as errors cannot be rectified once confirmed.'
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
