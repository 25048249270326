import { CommonButton } from '../UI/CommonButton';
import { useTheme } from '../../hooks/useTheme';
import React from 'react';

import styles from './ApplicationCard.module.scss';

type Prop = {
  item: {
    imgSrc: string;
    title: string;
    desc: string;
    buttonName: string;
  };
  isProsperNode?: boolean;
  onClick?: () => void;
  buttonEnabled?: boolean;
};

const ApplicationCard = (props: Prop) => {
  const { item, onClick, buttonEnabled } = props;
  const { imgSrc, title, desc, buttonName } = item;
  return (
    <div className={useTheme(styles.applicationCardWrapper)}>
      <div className={styles.mediaWrapper}>
        {!props.isProsperNode ? (
          <img src={imgSrc} alt={desc} className={styles.image} />
        ) : (
          <video src={imgSrc} className={styles.video} autoPlay muted playsInline loop>
            您的浏览器不支持 video 标签。
          </video>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <h1>{title}</h1>
        <p>{desc}</p>
        <CommonButton
          className={styles.button}
          handleClick={onClick}
          disabled={!buttonEnabled}
          name={buttonName}
        />
      </div>
    </div>
  );
};

export default ApplicationCard;
