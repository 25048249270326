import { CloseIcon } from '@chakra-ui/icons';
import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import styles from './index.module.css';
import classNames from 'classnames';
import { useTheme } from '../../../hooks/useTheme';

type ModalProps = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  children?: React.ReactNode;
  className?: string;
  autoClose?: boolean;
  closable?: boolean;
};

export function CommonModal({ isOpen, setIsOpen, children, className, autoClose, closable = true }: ModalProps) {
  if (!isOpen) return null;
  return (
    <>
      <Box className={styles.overlay} onClick={autoClose ? () => setIsOpen(false) : () => {}} />
      <Box className={useTheme(styles.centered)}>
        <Box className={classNames(styles.modal, className)}>
          {closable && (
            <Flex className={styles.closeBtnFlex}>
              <button onClick={() => setIsOpen(false)}>
                <CloseIcon boxSize={6} />
              </button>
            </Flex>
          )}
          <Box className={classNames(styles.modalContent, !closable && styles.closeBtn)}>{children}</Box>
        </Box>
      </Box>
    </>
  );
}
