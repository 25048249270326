import React from 'react';
import { Whyus } from '../components/Whyus';
// import { Welcome } from '../components/Welcome';
import { Trends } from '../components/Trends';
import { Banner } from '../components/Banner';
import ProsperEx from '../components/ProsperEx/ProsperEx';
import Application from '../components/Application/Application';
import SignUp from '../components/Signup/SignUp';
import SponsorAndBack from '../components/SponsorAndBack/SponsorAndBack';

export default function Home() {
  return (
    <div className="Home" style={{ overflow: 'clip' }}>
      <div id="home">
        <Banner />
      </div>
      <div id="trends">
        <Trends />
      </div>
      <div id="whyus">
        <Whyus />
      </div>
      <div id="prosperEx">
        <ProsperEx />
      </div>
      <div id="application">
        <Application />
      </div>
      <div id="sponsors">
        <SponsorAndBack />
      </div>
      <div id="signUp">
        <SignUp />
      </div>
      {/* <div id="welcome">
        <Welcome />
      </div> */}
    </div>
  );
}
