import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import styles from './index.module.scss';
import { useTheme } from '../../hooks/useTheme';
// import { CommonButton } from '../UI/CommonButton';
import MainContainer from '../UI/MainContainer/MainContainer';
// import ChainDataCard from './ChainDataCard';
import { Carousel } from './Carousel';
import { PriceCardSkeleton } from './PriceCardSkeleton';
import { usePrice } from '../../hooks/usePrice';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { ReactComponent as AppStoreDark } from '../../assets/image/banner/appStore-dark.svg';
import { ReactComponent as AppStore } from '../../assets/image/banner/appStore.svg';
import { ReactComponent as GooglePlay } from '../../assets/image/banner/googlePlay.svg';
import { ReactComponent as GooglePlayDark } from '../../assets/image/banner/googlePlay-dark.svg';
// import { ReactComponent as Enterprise } from '../../assets/image/banner/enterprise.svg';
// import { ReactComponent as EnterpriseDark } from '../../assets/image/banner/enterprise-dark.svg';

import { useTranslation } from 'react-i18next';

export function Banner() {
  //@ts-ignore
  const { t } = useTranslation();
  const chainData = [
    { title: t('Liquidity Scource', 'Liquidity Scource'), data: '380' },
    { title: t('Total Volume', 'Total Volume'), data: '$380B+' },
    { title: t('Total Wallets', 'Total Wallets'), data: '2.5M+' },
    { title: t('Total Trades', 'Total Trades'), data: '100M+' }
  ];

  const navigate = useNavigate();
  const { isSuccess, data: result } = usePrice();
  const [themeName] = useLocalStorage('color-mode', 'light');

  return (
    <>
      <Box className={useTheme(styles.container)}>
        <MainContainer className={styles.contentContainer}>
          <Flex className={styles.mainFlex}>
            <Box className={styles.worldImg}>
              <img src={require('../../assets/image/banner/computer.png')} alt="" />
            </Box>
            <Flex className={styles.textContainer}>
              <Box className={styles.title}>
                {t('Your Highway Express to Prosperity', 'Your Highway Express to Prosperity')}{' '}
              </Box>
              <Box className={styles.description}>
                {t(
                  'A universal decentralized exchange, blending RWA and AI, designed to empower leveraged trading across all assets with unparalleled security and user experience.',
                  'A universal decentralized exchange, blending RWA and AI, designed to empower leveraged trading across all assets with unparalleled security and user experience.'
                )}
              </Box>
              {/* <CommonButton
                handleClick={() => navigate('/earlybird')}
                name={'Join Early Bird'}
                className={styles.joinBtn}
              /> */}
              {/* <CommonButton
                handleClick={undefined}
                name={'Join ProsperEx Now'}
                className={styles.joinBtn}
              /> */}
              <div className={styles.appWrapper}>
                <a
                  href="https://play.google.com/store/apps/details?id=com.prosperex.android_dex"
                  target="_blank"
                  rel="noopener noreferrer">
                  {themeName === 'dark' ? <GooglePlayDark /> : <GooglePlay />}
                </a>
                <a href="https://testflight.apple.com/join/yF58LlbU" rel="noopener noreferrer" target={'_blank'}>
                  {themeName === 'dark' ? <AppStoreDark /> : <AppStore />}
                </a>
                {/* <a
                  href="https://test.guludeveloper.com/kWy0?a1d5MA=="
                  className={styles.qrCodeWrapper}
                  rel="noopener noreferrer"
                  target={'_blank'}>
                  {themeName === 'dark' ? <EnterpriseDark /> : <Enterprise />}
                </a> */}
              </div>
            </Flex>
            {isSuccess ? (
              <div>
                {/* <Flex className={styles.chainDataWrapper}>
                  {chainData.map((data) => (
                    <ChainDataCard title={data.title} data={data.data} key={data.data} />
                  ))}
                </Flex> */}
                <Flex className={styles.priceCardOuterContainer}>
                  <Carousel items={result!} />
                </Flex>
              </div>
            ) : (
              <Flex className={styles.priceCardContainer} alignItems="center" mt="6rem">
                {new Array(3).fill(0).map((_, index) => {
                  return <PriceCardSkeleton key={`price-skeleton-${index}`} />;
                })}
              </Flex>
            )}
          </Flex>
        </MainContainer>
      </Box>
    </>
  );
}
