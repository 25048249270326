import React from 'react';

import styles from './index.module.scss';

type whyUsIntroType = {
  imgSrc: string;
  title: string;
  intro: string;
  desc: string;
};

const IntroCard = ({ imgSrc, title, intro, desc }: whyUsIntroType) => {
  return (
    <div className={styles.dexDesc}>
      <div className={styles.introCardImgWrapper}>
        <img src={imgSrc} alt={title} />{' '}
      </div>
    </div>
  );
};

export default IntroCard;
