import { Box, Divider, Flex } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import styles from './index.module.scss';
import MainContainer from '../UI/MainContainer/MainContainer';
import classnames from 'classnames';
import { useLocalStorage } from 'usehooks-ts';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface socialType {
  name: string;
  src: string;
  link: string | undefined;
}

let socialItems: Array<socialType> = [
  // {
  //   name: 'Docs',
  //   src: require('../../assets/image/social/docs.png'),
  //   link: 'https://docs.prosperex.xyz/'
  // },
  {
    name: 'Twitter',
    src: require('../../assets/image/social/twitter.png'),
    link: 'https://twitter.com/prosper_ex'
  },
  {
    name: 'Telegram',
    src: require('../../assets/image/social/telegram.png'),
    link: 'https://t.me/prosper_ex'
  },
  {
    name: 'Medium',
    src: require('../../assets/image/social/medium.png'),
    link: 'https://prosperex.medium.com/'
  }
];

export function Footer() {
  //@ts-ignore
  const { t } = useTranslation();
  const location = window.location.pathname;
  const isTreasuryPage = location.indexOf('treasury') !== -1;
  const menuItems = [
    {
      name: t('Complaint form', 'Complaint form'),
      link: 'https://forms.gle/tdmEFA4HMq5ghJDQ6'
    },
    {
      name: t('Terms and Conditions', 'Terms and Conditions'),
      link: '/terms-and-conditions'
    }
  ];
  const navigate = useNavigate();
  const [themeName, setThemeName] = useLocalStorage('color-mode', 'light');
  const [switchItem, setSwitchItem] = useState({
    name: 'switch',
    src: require('../../assets/image/social/Switch_Dark.png'),
    link: undefined
  });

  const toggleColorMode = () => {
    if (themeName === 'light') {
      setThemeName('dark');
    } else {
      setThemeName('light');
    }
  };

  useEffect(() => {
    if (themeName === 'light') {
      setSwitchItem({ name: 'switch', src: require('../../assets/image/social/Switch_Dark.png'), link: undefined });
    } else {
      setSwitchItem({ name: 'switch', src: require('../../assets/image/social/Switch_Light.png'), link: undefined });
    }

    if (socialItems.length < 5) {
      socialItems.push(switchItem);
    } else {
      socialItems[4] = switchItem;
    }
  }, [themeName]);

  if (isTreasuryPage) {
    return null;
  }
  return (
    <>
      <Box className={classnames(styles.container, 'theme-dark')}>
        <MainContainer className={styles.contentContainer}>
          <Flex className={styles.mainFlex}>
            <Box className={styles.logo}></Box>
            <Flex className={styles.menuContainer}>
              {menuItems.map((item) => (
                <Link
                  key={item.name}
                  className={styles.menuItem}
                  to={item.link}
                  target={item.link.indexOf('http') !== -1 ? '_blank' : '_self'}
                  rel={item.link.indexOf('http') !== -1 ? 'noopener noreferrer' : 'none'}>
                  {item.name}
                </Link>
              ))}
            </Flex>
          </Flex>
          <Divider colorScheme="whiteAlpha" />
          <Flex className={styles.copyrightFlex} alignItems="center" py="1.5rem" flexWrap="wrap" color="whiteAlpha.500">
            <Box className={styles.copyright}>Copyright © 2023. All rights reserved</Box>
            <Flex className={styles.socialContainer} gap="1.5rem">
              {socialItems.map((item, index) => (
                <a
                  key={`social-${index}`}
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ cursor: 'pointer' }}>
                  <img
                    src={item.src}
                    alt={item.name}
                    onClick={item.name !== 'switch' ? () => {} : () => toggleColorMode()}
                    width="30px"
                    height="30px"
                  />
                </a>
              ))}
            </Flex>
          </Flex>
        </MainContainer>
      </Box>
    </>
  );
}
