import { withdrawListResult } from '../../utils/types';
import { tokenAxios } from '../index';

export const requestWithdraw = async (tokenOnChainId: string, payload: { amount: string; toWallet: string }) => {
  const result = await tokenAxios.post(`/chain/tokensOnChain/${tokenOnChainId}/withdraw`, payload);
  return result.data;
};

export const requestWithdrawList = async () => {
  const result = await tokenAxios.get<withdrawListResult[]>('/chain/withdrawals', null);
  return result.data;
};
