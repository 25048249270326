import React from 'react';
import { useTheme } from '../../hooks/useTheme';
import ExCard from './ExCard';
// import { Box, SimpleGrid } from '@chakra-ui/react';
import MainContainer from '../UI/MainContainer/MainContainer';
import styles from './ProsperEx.module.scss';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';

const ProsperEx = () => {
  //@ts-ignore
  const { t } = useTranslation();
  const [themeName] = useLocalStorage('color-mode', 'light');

  const exList = [
    {
      imgSrc: require(`../../assets/image/prosperEx/${
        themeName === 'light' ? 'Light mode/' : 'Dark mode/'
      }Low fees.png`),
      title: t('Low Fees, No Gas Costs', 'Low Fees, No Gas Costs'),
      detail: t('Enjoy the best user experiences', 'Enjoy the best user experiences')
    },
    {
      imgSrc: require(`../../assets/image/prosperEx/${
        themeName === 'light' ? 'Light mode/' : 'Dark mode/'
      }HIgh leverage.png`),
      title: t('High Leverage', 'High Leverage'),
      detail: t('Up to 125x', 'Up to 125x')
    },
    {
      imgSrc: require(`../../assets/image/prosperEx/${themeName === 'light' ? 'Light mode/' : 'Dark mode/'}Zero.png`),
      title: t('Zero Slippage', 'Zero Slippage'),
      detail: t('High Liquidity', 'High Liquidity')
    },
    {
      imgSrc: require(`../../assets/image/prosperEx/${
        themeName === 'light' ? 'Light mode/' : 'Dark mode/'
      }Private.png`),
      title: t('Private', 'Private'),
      detail: t('Maintain anonymity and trade freely', 'Maintain anonymity and trade freely')
    },
    {
      imgSrc: require(`../../assets/image/prosperEx/${themeName === 'light' ? 'Light mode/' : 'Dark mode/'}Secure.png`),
      title: t('Secure', 'Secure'),
      detail: t('Your wallet, Your funds', 'Your wallet, Your funds')
    },
    {
      imgSrc: require(`../../assets/image/prosperEx/${
        themeName === 'light' ? 'Light mode/' : 'Dark mode/'
      }Diverse.png`),
      title: t('Diverse Market', 'Diverse Market'),
      detail: t(
        'Crypto, Stock, Freigh Exchanges, comendity and more',
        'Crypto, Stock, Freigh Exchanges, comendity and more'
      )
    }
  ];
  return (
    <div className={useTheme(styles.prosperExWrapper)}>
      <MainContainer className={styles.prosperExMainContainer}>
        <h1>At ProsperEX</h1>

        <div className={styles.exCardWrapper}>
          {exList.slice(0, 3).map((item) => (
            <ExCard key={item.imgSrc} imgSrc={item.imgSrc} title={item.title} detail={item.detail} />
          ))}
        </div>
        <div className={styles.exCardWrapper}>
          {exList.slice(3, 6).map((item) => (
            <ExCard key={item.imgSrc} imgSrc={item.imgSrc} title={item.title} detail={item.detail} />
          ))}
        </div>
        {/* <SimpleGrid columns={[2, null, 3]}>
          {exList.map((item) => (
            <Box>
              <ExCard key={item.imgSrc} imgSrc={item.imgSrc} title={item.title} detail={item.detail} />
            </Box>
          ))}
        </SimpleGrid> */}
      </MainContainer>
    </div>
  );
};

export default ProsperEx;
