import { unlockResult, unlockableResult } from '../../utils/types';
import { tokenAxios } from '../index';

export const requestUnlock = async (tokenId: string) => {
  const result = await tokenAxios.post<unlockResult>(`/linearUnlock/unlock?tokenId=${tokenId}`);
  return result.data;
};

export const requestUnlockable = async (tokenId: string) => {
  const result = await tokenAxios.get<unlockableResult>(`/linearUnlock/unlockable?tokenId=${tokenId}`);
  return result.data;
};
