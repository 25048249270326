import {
  Flex,
  Box,
  Grid,
  Divider,
  FormControl,
  FormErrorMessage,
  useToast,
  Image,
  NumberInput,
  NumberInputField
} from '@chakra-ui/react';
import styles from './Deposit.module.scss';
import { useTranslation } from 'react-i18next';
import { CommonBox } from '../UI/CommonBox';
import { CommonButton } from '../UI/CommonButton';
import QRCode from 'react-qr-code';
import { useBalance } from 'wagmi';
import { useWallet } from '../../store/wallet-context';
import { useMemo, useState } from 'react';
import copyIcon from '../../assets/image/node/copy.svg';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useQuery } from '@tanstack/react-query';
import { requestApiGate } from '../../service/common';
import { parseEther } from 'ethers/lib/utils.js';
import erc20Abi from '../../abi/erc20.json';
import { ethers } from 'ethers';

export function Deposit({ buyAddress }: { buyAddress: string }) {
  //@ts-ignore
  const { t } = useTranslation();

  const { accountAddress, isConnected } = useWallet();

  const [loading, setLoading] = useState(false);

  const { isSuccess: userQuerySuccess, data: userQueryData } = useQuery({
    queryKey: ['userQuery', accountAddress],
    queryFn: () => {
      return requestApiGate();
    },
    enabled: isConnected
  });

  // const usdtAddress = process.env.REACT_APP_USDT_ADDRESS! as `${'0x'}${string}`;
  const usdtAddress = userQueryData?.usdt.tokenAddress;

  const {
    data: usdtBalance,
    isSuccess: usdtBalanceQuerySuccess,
    refetch: refetchBalance
  } = useBalance({
    address: accountAddress,
    token: usdtAddress,
    enabled: isConnected && userQuerySuccess
  });

  const [count, setCount] = useState('');

  const toast = useToast();

  const balance = useMemo(() => {
    return (+usdtBalance?.formatted).toFixed(2) ?? 0;
  }, [usdtBalance?.formatted]);

  const insufficientError = useMemo(() => {
    return usdtBalance && count && +usdtBalance?.formatted < +count;
  }, [count, usdtBalance]);

  const handleCopy = () => {
    toast({
      description: t('Copied to clipboard', 'Copied to clipboard'),
      status: 'success',
      duration: 1000,
      isClosable: false
    });
  };

  const deposit = async () => {
    if (!usdtBalanceQuerySuccess) {
      console.error('usdtBalanceQuery failed');
      return;
    }
    if (+count === 0 || !/^\d+(?=\.{0,1}\d+$|$)/.test(count)) {
      toast({
        title: t('Please input amount', 'Please input amount'),
        status: 'error',
        duration: 3000,
        isClosable: true
      });
      return;
    }
    const ethereum = window.ethereum!;
    const usdtAbi = erc20Abi;

    try {
      // Use ethers.js to transfer USDT to address
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(ethereum as any);
      const signer = provider.getSigner();
      const usdtContract = new ethers.Contract(usdtAddress, usdtAbi, signer);
      const transferTx = await usdtContract.transfer(userQueryData?.depositAddress, parseEther(count));
      await transferTx.wait();
      await refetchBalance();
      setLoading(false);
      toast({
        title: t(
          'USDT transferred successfully hint',
          'USDT transferred successfully, please check your usdt balance from profile page. It takes 15 block confirermation to arrive at the account, which is expected to take 1-2 minutes.'
        ),
        status: 'success',
        duration: 5000,
        isClosable: true
      });
    } catch (error) {
      setLoading(false);
      console.error('Failed to transfer USDT:', error);
    }
  };

  return (
    <Grid rowGap="0.625rem" className={styles.container}>
      <Box className={styles.title}>{t('Deposit USDT', 'Deposit USDT')}</Box>
      <CommonBox className={styles.balance}>
        {t('Wallet Balance', 'Wallet Balance')}: <Box as="span">{balance} USDT</Box>
      </CommonBox>
      <FormControl isInvalid={insufficientError}>
        <NumberInput>
          <NumberInputField
            className={styles.input}
            placeholder={t('Input deposit amount', 'Input deposit amount')}
            borderColor="#23262F"
            borderRadius="16px"
            value={count}
            onChange={(e) => {
              setCount(e.target.value);
            }}
          />
        </NumberInput>
        {insufficientError && (
          <FormErrorMessage>{t('Insufficient USDT balance', 'Insufficient USDT balance')}</FormErrorMessage>
        )}
      </FormControl>
      <CommonButton
        name={t('Deposit', 'Deposit')}
        disabled={insufficientError || !count}
        thick
        className={styles.depositButton}
        loading={loading}
        handleClick={deposit}
      />
      <Divider borderColor="#777E90" />
      <Flex justifyContent="center" flexDirection="column" alignItems="center" rowGap="12px">
        <QRCode
          value={buyAddress}
          size={120}
          style={{
            background: 'white',
            padding: '0.3rem',
            borderRadius: '0.3rem'
          }}
        />
        <Box textAlign="center" maxWidth="200px">
          {t('You can also deposit by scaning the QR Code', 'You can also deposit by scaning the QR Code')}
        </Box>
      </Flex>
      <Divider borderColor="#777E90" />
      <CommonBox>
        <Flex justifyContent="space-between" gap="10px" alignItems="center">
          <Box className={styles.address}>
            {t('deposit address', 'deposit address')}:
            <br />
            <Box as="span" fontWeight={600}>
              {buyAddress}
            </Box>
          </Box>
          <CopyToClipboard text={buyAddress} onCopy={handleCopy}>
            <Image src={copyIcon} cursor="pointer" />
          </CopyToClipboard>
        </Flex>
      </CommonBox>

      <Box className={styles.noticeFlex}>
        <Flex className={styles.noticeTitle}>
          <img src={require('../../assets/image/earlybird/notice-icon.png')} alt="earlybird" />
          {t('Notice', 'Notice')}
        </Flex>
        <Box className={styles.noticeDetail}>
          {t(
            'Please note that transactions involving digital assets are final and irreversible. Double-check the amount, price, and recipient address before initiating a transaction, as errors cannot be rectified once confirmed.',
            'Please note that transactions involving digital assets are final and irreversible. Double-check the amount, price, and recipient address before initiating a transaction, as errors cannot be rectified once confirmed.'
          )}
        </Box>
      </Box>
    </Grid>
  );
}
