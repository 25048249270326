import React from 'react';
import { Box } from '@chakra-ui/react';
import './Carousel.module.scss';
import IntroCard from './IntroCard';

type whyUsIntroType = {
  imgSrc: string;
  title: string;
  intro: string;
  desc: string;
};
type Prop = {
  item: whyUsIntroType;
};
export function CarouselItem({ item }: Prop) {
  return (
    <Box className="keen-slider__slide">
      <IntroCard {...item} />
    </Box>
  );
}
