import { useState, useEffect } from 'react';
import { useContracts } from '../useContracts';
import { formatEther, parseEther } from 'ethers/lib/utils.js';
import { BigNumber } from 'ethers';

export const useLatesOtcPrice = () => {
  const [price, setPrice] = useState<string>('');
  const contracts = useContracts();
  const manager = contracts?.manager;
  const managerAddress = manager?.address;
  const fetchPrice = async () => {
    const manager = contracts?.manager;
    if (!manager) return;
    const lastBill = await manager.getLastPriceUsdtForPpe();
    if (lastBill && BigNumber.from(lastBill.takerToken).gt(0)) {
      // maker token is usdt
      // taker token is ppe
      const priceFixed18 = parseEther('1').mul(lastBill.makerVolume).div(lastBill.takerVolume);
      setPrice(formatEther(priceFixed18));
    }
  };
  useEffect(() => {
    fetchPrice();
  }, [managerAddress]);
  return { price, fetchPrice };
};
