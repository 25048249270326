import React from 'react';
import { Box } from '@chakra-ui/react';
import styles from './Benifits.module.scss';
import { useTranslation } from 'react-i18next';
import MainContainer from '../UI/MainContainer/MainContainer';
import { useTheme } from '../../hooks/useTheme';

export function Benefits() {
  const { t } = useTranslation();
  const benefits = [
    t(
      'Each node receives 10,000 PPE tokens, distributed linearly over 18 months.',
      'Each node receives 10,000 PPE tokens, distributed linearly over 18 months.'
    ),
    t(
      'Nodes enjoy a 50% discount on exchange transaction fees and shared transaction fee commissions.',
      'Nodes enjoy a 50% discount on exchange transaction fees and shared transaction fee commissions.'
    ),
    t(
      'Nodes will receive priority airdrops of newly listed tokens on exchanges.',
      'Nodes will receive priority airdrops of newly listed tokens on exchanges.'
    ),
    t(
      'Nodes can participate in Initial Exchange Offerings (IEOs).',
      'Nodes can participate in Initial Exchange Offerings (IEOs).'
    ),
    // eslint-disable-next-line quotes
    t(
      // eslint-disable-next-line quotes
      "Nodes can participate in ProsperEx's Decentralized Autonomous Organization(DAO) governance.",
      // eslint-disable-next-line quotes
      "Nodes can participate in ProsperEx's Decentralized Autonomous Organization(DAO) governance."
    )
  ];
  return (
    <MainContainer className={useTheme(styles.container)}>
      {/* <img src={require('../../assets/image/node/ppeIcon.png')} alt="" /> */}
      <Box className={styles.detailWrapper}>
        <Box className={styles.title}>{t('Get the benefits of Nodes', 'Get the benefits of Nodes')}</Box>
        {/* <Flex className={styles.miniNodeBenefits}>
          <Box className={styles.miniNodeBenefitsDetail}>
            <Trans i18nKey={'mininodebenefit-1'}>
              Each <span>subnode</span> receives 1,000 PPE tokens, distributed linearly over 18 months.
            </Trans>
          </Box>
          <Box className={styles.miniNodeBenefitsDetail}>
            <Trans i18nKey={'mininodebenefit-2'}>
              In the future, a certain amount of <span>subnodes</span> can be combine into 1 Node, enjoying all the
              rights and benefits of Node.
            </Trans>
          </Box>
        </Flex> */}
        <Box className={styles.detail}>
          <ul className={styles.benefits}>
            {benefits.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </Box>
      </Box>
    </MainContainer>
  );
}
