import React from 'react';
import { IERC20 } from '../../../typechain';
import { BigNumber, BigNumberish, ethers } from 'ethers';
import { useWallet } from '../../../store/wallet-context';
import { useContracts } from '../../../hooks/useContracts';
import { useQuery } from '@tanstack/react-query';
import { CommonButton } from '../CommonButton';
import { useTranslation } from 'react-i18next';
import { useMessage } from '../../../hooks/useToast';
import { formatEther } from 'ethers/lib/utils.js';

type Props = {
  children: JSX.Element;
  needAmount: BigNumberish;
  tokenName: string;
  tokenContract?: IERC20;
  className?: string;
};
export function Approvable(props: Props) {
  const { t } = useTranslation();
  const message = useMessage();
  const [isApproving, setIsApproving] = React.useState(false);
  const { accountAddress } = useWallet();
  const contracts = useContracts();
  const managerAddress = contracts?.manager?.address;
  const { children, tokenContract, needAmount, tokenName, className } = props;

  const fetchInfo = async () => {
    const allowance = await tokenContract.allowance(accountAddress, managerAddress);
    const balance = await tokenContract.balanceOf(accountAddress);
    console.log('allowance', formatEther(allowance).toString());
    console.log('balance', formatEther(balance).toString());

    return {
      allowance,
      balance
    };
  };
  const query = useQuery({
    queryKey: ['tokenInfo', tokenName],
    queryFn: fetchInfo,
    staleTime: 1000 * 60 * 5, // 5 minutes
    enabled: !!tokenContract
  });

  let needApprove = false;
  if (query.isSuccess) {
    const { allowance } = query.data;
    needApprove = BigNumber.from(needAmount).gt(allowance);
  }

  if (!accountAddress || !needAmount || !needApprove) {
    return children;
  }

  const handleApprove = async () => {
    if (query.data.balance.eq(0)) {
      message.error(t('Insufficient balance', 'Insufficient balance'));
      return;
    }
    setIsApproving(true);
    try {
      const tx = await tokenContract.approve(managerAddress, ethers.constants.MaxUint256);
      await tx.wait();
      query.refetch();
      message.success(t('Approve successfully', 'Approve successfully'));
      setIsApproving(false);
    } catch (error) {
      message.error(t('Approve failed', 'Approve failed'));
      setIsApproving(false);
    }
  };

  return (
    <CommonButton
      name={t('Approve', 'Approve') + ' ' + tokenName}
      handleClick={handleApprove}
      className={className}
      loading={isApproving}
    />
  );
}
