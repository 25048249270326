import { Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';
import React from 'react';
import styles from './index.module.css';
import { useTheme } from '../../../hooks/useTheme';
import classNames from 'classnames';

type ModalProps = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  children?: React.ReactNode;
  className?: string;
  autoClose?: boolean;
  closable?: boolean;
  closeOnOverlayClick?: boolean;
  width?: string;
};

export function CommonModalNew({
  isOpen,
  closeOnOverlayClick = false,
  children,
  closable = true,
  width = '355px',
  className,
  setIsOpen
}: ModalProps) {
  return (
    <Modal
      autoFocus={false}
      isOpen={isOpen}
      isCentered
      closeOnOverlayClick={closeOnOverlayClick}
      onClose={() => setIsOpen(false)}>
      <ModalOverlay />
      <ModalContent width={width} borderRadius="1.5rem" maxWidth="100vw">
        <Box className={classNames(useTheme(styles.modal), className)}>
          <ModalBody padding="2.5rem 1.25rem 1.25rem">{children}</ModalBody>
          {closable && <ModalCloseButton />}
        </Box>
      </ModalContent>
    </Modal>
  );
}
