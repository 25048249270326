import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { useLocation } from 'react-router-dom';
import { ScrollToTop } from './hooks/useScrollToTop';
import { useLocalStorage } from 'usehooks-ts';

function App() {
  const location = useLocation();
  const path = location.pathname;
  const isEaryBird = path.indexOf('/earlybird') > -1;
  const navigate = useNavigate();
  const [loggedInTimestamp] = useLocalStorage('__PROSPER_EX__sign_timestamp', 0);

  useEffect(() => {
    // 每过一段时间要重新验证
    const timer = setInterval(() => {
      if (loggedInTimestamp && Date.now() - loggedInTimestamp - 10 * 60 * 1000 > 0) {
        navigate('/login');
      }
    }, 60 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, [loggedInTimestamp]);

  return (
    <div>
      <ScrollToTop />

      <Header />
      <Outlet />
      {!isEaryBird && <Footer />}
    </div>
  );
}

export default App;
