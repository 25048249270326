import React from 'react';
import { Box, Flex, Spinner } from '@chakra-ui/react';
import './index.css';
import classNames from 'classnames';

type CommonButtonProps = {
  handleClick?: any;
  name: string;
  outline?: boolean;
  thick?: boolean;
  thin?: boolean;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
};
export function CommonButton({ handleClick, name, outline, className, disabled, loading, thick, thin }: CommonButtonProps) {
  return (
    <Box
      className={classNames(
        'JoinButtonBox',
        outline && 'outlinedButton',
        disabled && 'disabledButton',
        loading && 'disabledButton',
        thick && 'thickButton',
        thin && 'thinButton',
        className
      )}
      onClick={disabled || loading ? () => {} : handleClick}
    >
      <Flex lineHeight="2.5rem" justifyContent="center" gap={2}>
        {name}
        {loading && (
          <Box mt={1}>
            <Spinner />
          </Box>
        )}
      </Flex>
    </Box>
  );
}
