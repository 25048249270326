import React from 'react';
import { Box, Flex, Img } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { CommonButton } from '../../../components/UI/CommonButton';
import { useTheme } from '../../../hooks/useTheme';
import styles from './OtcDesk.module.scss';
import { useOtcBills } from '../../../hooks/otc/useOtcBills';
import menuIcon from '../../../assets/image/otc/menu.png';
import bookIcon from '../../../assets/image/otc/book.png';
import ppeIcon from '../../../assets/image/otc/ppe.png';
import usdtIcon from '../../../assets/image/otc/usdt.png';
import { CommonModal } from '../../../components/UI/CommonModal';
import { useContracts } from '../../../hooks/useContracts';
import { useMessage } from '../../../hooks/useToast';
import { Approvable } from '../../../components/UI/Approvable';
import { formatEther, parseEther } from 'ethers/lib/utils.js';
import { useTokens } from '../../../hooks/useTokens';
import { truncateNumber } from '../../../utils/utils';
import { OtcBill } from '../../../utils/types';
import { useWallet } from '../../../store/wallet-context';
import { useLatesOtcPrice } from '../../../hooks/otc/useLatesOtcPrice';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useLogin } from '../../../hooks/useLogin';

export default function OtcDesk() {
  //@ts-ignore
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const loggedIn = useLogin();
  const { bills, fetchBills } = useOtcBills();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { accountAddress } = useWallet();
  const contracts = useContracts();
  const tokenQueries = useTokens();

  const { price: ppeLastPrice, fetchPrice } = useLatesOtcPrice();
  const ppePrice = ppeLastPrice || '-';

  const ppeBalance = tokenQueries.isSuccess ? truncateNumber(formatEther(tokenQueries.data.ppe.balance)) : '0';
  const message = useMessage();
  const [selectedTicket, setSelectedTicket] = React.useState<OtcBill | null>(null);

  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const handleGoToCreateOrder = () => {
    window.location.href = '/otc?page=create';
  };

  const handleGoToHistory = () => {
    window.location.href = '/otc?page=history';
  };

  const handleSell = async () => {
    const manager = contracts.manager;
    console.log('manager', manager);

    if (!manager || !selectedTicket) return;
    setIsLoading(true);
    try {
      const tx = await manager.take(selectedTicket.ticketId);
      const receipt = await tx.wait();
      console.log('receipt', receipt);
      message.success(t('Sell successfully', 'Sell successfully'));
      setIsLoading(false);
      setIsModalOpen(false);
      fetchBills();
      fetchPrice();
    } catch (error) {
      console.error(error);
      message.error(t('Sell failed', 'Sell failed'));
      setIsLoading(false);
    }
  };
  return (
    <Box className={useTheme(styles.container)}>
      <Flex className={styles.header}>
        <Box className={styles.title}>{t('OTC Desk', 'OTC Desk')}</Box>
        <Flex>
          <CommonButton
            className={styles.createOrderBtn}
            name={isMobile ? '+' : t('Create an order', 'Create an order') }
            handleClick={handleGoToCreateOrder}
          />
          <Img className={styles.historyIcon} src={menuIcon} onClick={handleGoToHistory} />
        </Flex>
      </Flex>
      <Flex className={styles.tableContainer}>
        <Flex className={styles.tableHeader}>
          <Flex alignItems="center">
            <Img className={styles.bookIcon} src={bookIcon} />
            <Box className={styles.tableTitle}>{t('Orderbook', 'Orderbook')}</Box>
          </Flex>
          <Flex>
            <Img className={styles.ppeIcon} src={ppeIcon} />
            <Box fontWeight={700}>PPE:</Box>
            <Box className={styles.price}>{ppePrice} USDT</Box>
          </Flex>
        </Flex>
        <Box>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>{t('PPE Amount', 'PPE Amount')}</th>
                <th>{t('Buy Price', 'Buy Price')}</th>
                <th>{t('USDT Amount', 'USDT Amount')}</th>
                <th>{t('Operation', 'Operation')}</th>
              </tr>
            </thead>
            <tbody>
              {bills.map((row, rowIndex) => (
                <tr key={`row-${rowIndex}`}>
                  <td>{row.ppeAmount}</td>
                  <td>$ {row.buyPrice}</td>
                  <td>
                    <Flex justifyContent="center">
                      <Img className={styles.usdtIcon} src={usdtIcon} />
                      {row.usdtAmount}
                    </Flex>
                  </td>
                  {row.maker !== accountAddress ? (
                    <td
                      className={styles.sell}
                      onClick={() => {
                        setIsModalOpen(true);
                        setSelectedTicket(row);
                      }}>
                      {t('Sell', 'Sell')}
                    </td>
                  ) : (
                    <td>-</td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Flex>

      <CommonModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} className={styles.modal}>
        <Flex className={styles.modalTitle}>{t('Sell PPE', 'Sell PPE')}</Flex>
        <Flex justifyContent="space-between" mb={4}>
          <Box>{t('Balance', 'Balance')}: </Box>
          <Box>{ppeBalance} PPE</Box>
        </Flex>
        <Box>{t('Order amount', 'Order amount')}</Box>
        <Flex className={styles.modalOrderAmount}>{selectedTicket?.ppeAmount} PPE</Flex>
        <Approvable
          needAmount={parseEther(selectedTicket?.ppeAmount || '0')}
          tokenName={'PPE'}
          tokenContract={contracts.ppe}
          className={styles.modalConfirmBtn}>
          <CommonButton className={styles.modalConfirmBtn} name={t('Sell', 'Sell')} thick handleClick={handleSell} loading={isLoading} />
        </Approvable>
      </CommonModal>
    </Box>
  );
}
