import React from 'react';
import { Box } from '@chakra-ui/react';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import styles from './Carousel.module.scss';
import { CarouselItem } from './CarouselItem';
import { PriceApiResult } from '../../types';

type Prop = {
  items: Array<PriceApiResult>;
};

export function Carousel({ items }: Prop) {
  const [sliderRef] = useKeenSlider({
    loop: false,
    renderMode: 'performance',
    drag: true,
    slides: {
      perView: 4.8
    },
    breakpoints: {
      '(max-width: 1440px)': {
        slides: {
          perView: 4.8
        }
      },
      '(max-width: 1024px)': {
        slides: {
          perView: 3.2
        }
      },
      '(max-width: 768px)': {
        slides: {
          perView: 2.2
        }
      },
      '(max-width: 600px)': {
        slides: {
          perView: 1.2
        }
      }
    }
  });

  return (
    <Box className={styles.carouselContainer}>
      <Box className={styles.carouselContent}>
        <Box ref={sliderRef} className="keen-slider">
          {items.map((item, idx) => (
            <CarouselItem key={idx} item={item} />
          ))}
        </Box>
      </Box>
    </Box>
  );
}
