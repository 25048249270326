import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { useWallet } from '../store/wallet-context';

export const useLogin = () => {
  const navigate = useNavigate();
  const { accountAddress } = useWallet();
  const [loggedInTimestamp, setLoggedInTimestamp] = useLocalStorage('__PROSPER_EX__sign_timestamp', 0);

  useEffect(() => {
    // 每过一段时间要重新验证
    if (Date.now() - loggedInTimestamp - 10 * 60 * 1000 > 0 || !accountAddress) {
      navigate('/login');
    }
  }, []);
};
