import { graphic } from 'echarts';
import { useLocalStorage } from 'usehooks-ts';

type Prop = {
  chartData: number[];
  trend: 'up' | 'down';
};

export const getChartOptions = ({ chartData, trend }: Prop) => {
  const [themeName] = useLocalStorage('color-mode', 'light');
  const isLightMode = themeName === 'light';
  return {
    xAxis: [
      {
        type: 'category',
        splitLine: { show: false },
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: { show: false }
      }
    ],
    yAxis: [
      {
        show: false,
        type: 'log'
      }
    ],
    series: [
      {
        type: 'line',
        showSymbol: false,
        itemStyle: {
          color: trend === 'down' ? '#F74E2C' : '#06B470'
        },
        areaStyle: {
          color:
            trend === 'down'
              ? new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#F74E2C'
                },
                {
                  offset: 1,
                  color: isLightMode ? 'white' : 'transparent'
                }
              ])
              : new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#06B470'
                },
                {
                  offset: 1,
                  color: isLightMode ? 'white' : 'transparent'
                }
              ])
        },
        data: chartData
      }
    ]
  };
};
