import { ethers } from 'ethers';

import { useContracts } from './useContracts';
import { useWallet } from '../store/wallet-context';
import { useQuery } from '@tanstack/react-query';
import { useToast } from '@chakra-ui/react';
import { useBlockNumber } from 'wagmi';
import { useEffect, useRef } from 'react';
// import { truncateNumber } from '../utils/utils';

interface Token {
  symbol: string;
  decimals: number;
  allowance: ethers.BigNumber;
  balance: ethers.BigNumber;
  approved: boolean;
  approve: () => Promise<ethers.ContractReceipt | undefined>;
}

export const useTokens = () => {
  const toast = useToast();
  const contracts = useContracts();
  const blockNumber = useBlockNumber({ watch: true });
  const blockNumberRef = useRef(blockNumber);
  const { usdt, ppe, manager } = contracts;
  const { accountAddress } = useWallet();
  const tokenContracts = [usdt, ppe];

  const tokenQueries = useQuery({
    queryKey: ['tokenQueries'],
    queryFn: async () => {
      const queryPromises = tokenContracts.map(async (tokenContract) => {
        const balanceBigNumber = await tokenContract.balanceOf(accountAddress!);
        const decimals = await tokenContract.decimals();
        const symbol = await tokenContract.symbol();
        const allowance = await tokenContract.allowance(accountAddress!, manager!.address);
        const approved = allowance.gt(0);
        const approveToken = async () => {
          if (balanceBigNumber.eq(0)) {
            toast({
              title: `${symbol} balance is 0.`,
              status: 'error',
              duration: 1500,
              isClosable: false
            });
            return;
          }
          const tx = await tokenContract.approve(manager!.address, ethers.constants.MaxUint256);
          const receipt = await tx.wait();
          return receipt;
        };
        return {
          symbol,
          balance: balanceBigNumber,
          allowance,
          approved,
          decimals,
          approve: approveToken
        } satisfies Token;
      });
      const results = await Promise.all(queryPromises);
      return {
        usdt: results[0],
        ppe: results[1]
      };
    },
    enabled: !!accountAddress && !(manager == null) && !contracts.ppe && !contracts.usdt
  });

  useEffect(() => {
    if (blockNumber.isSuccess && blockNumberRef.current.data !== blockNumber.data) {
      blockNumberRef.current = blockNumber;
      tokenQueries.refetch();
    }
  }, [blockNumber]);

  return tokenQueries;
};
