import { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Divider,
  Img,
  useToast
} from '@chakra-ui/react';
import { HamburgerIcon, SmallCloseIcon } from '@chakra-ui/icons';
import UserIcon from '../../assets/image/profile/user.svg';
import UserDarkIcon from '../../assets/image/profile/user-dark.svg';
import styles from './index.module.scss';
import { useLocalStorage } from 'usehooks-ts';
import { useTheme } from '../../hooks/useTheme';
import MainContainer from '../UI/MainContainer/MainContainer';
import { useNavigate, Link } from 'react-router-dom';
import { truncAddress, useWindowDimensions } from '../../utils/utils';
import { CommonButton } from '../UI/CommonButton';
import { useWallet } from '../../store/wallet-context';
import DarkIcon from '../../assets/image/social-svg/moon.svg';
import LightIcon from '../../assets/image/social-svg/sun.svg';
import avatar from '../../assets/image/profile/user.png';
import React from 'react';
import LangButton from '../LangButton/LangButton';
import { useTranslation } from 'react-i18next';
import { requestAuth, requestLogin } from '../../service/auth';
import { useSignMessage } from 'wagmi';
import { requestRegistered } from '../../service/account';
import { ethers } from 'ethers';
import { WalletsPanel } from '../MultiWalletButton/WalletsPanel';
// import { CommonButton } from '../UI/CommonButton';

const menuItems = [
  {
    name: 'Home',
    link: '/'
  },
  // {
  //   name: 'Products',
  //   link: '/'
  // },
  // {
  //   name: 'Features',
  //   link: '/'
  // },
  {
    name: 'Trades',
    link: '/'
  },
  {
    name: 'Treasury',
    link: 'treasury'
  },
  // {
  //   name: 'T-BOND',
  //   link: '/'
  // },
  // {
  //   name: 'Earlybird',
  //   link: 'earlybird'
  // },
  {
    name: 'Node',
    link: 'node-sale'
  },
  {
    name: 'OTC',
    link: 'otc'
  },

  {
    name: 'Download',
    link: '/'
  }
];
// const socialItems = [
//   {
//     name: 'facebook',
//     src: {
//       light: require('../../assets/image/social-svg/light/facebook.svg').default,
//       dark: require('../../assets/image/social-svg/dark/facebook.svg').default
//     }
//   },
//   {
//     name: 'Twitter',
//     src: {
//       light: require('../../assets/image/social-svg/light/twitter.svg').default,
//       dark: require('../../assets/image/social-svg/dark/twitter.svg').default
//     }
//   },
//   {
//     name: 'instagram',
//     src: {
//       light: require('../../assets/image/social-svg/light/instagram.svg').default,
//       dark: require('../../assets/image/social-svg/dark/instagram.svg').default
//     }
//   }
// ];

interface socialType {
  name: string;
  src: string;
  link: string | undefined;
}

let socialItems: Array<socialType> = [
  // {
  //   name: 'Docs',
  //   src: require('../../assets/image/social/docs.png'),
  //   link: 'https://docs.prosperex.xyz/'
  // },
  {
    name: 'Twitter',
    src: require('../../assets/image/social/twitter.png'),
    link: 'https://twitter.com/prosper_ex'
  },
  {
    name: 'Telegram',
    src: require('../../assets/image/social/telegram.png'),
    link: 'https://t.me/prosper_ex'
  },
  {
    name: 'Medium',
    src: require('../../assets/image/social/medium.png'),
    link: 'https://prosperex.medium.com/'
  }
];

const BREAKPOINT = 768;
export function Header() {
  const { t } = useTranslation();
  t('Home', 'Home');
  t('Trades', 'Trades');
  t('Node', 'Node');
  t('Treasury', 'Treasury');
  const { isConnected, accountAddress, disconnect, connect } = useWallet();
  const { width } = useWindowDimensions();
  const isMobile = width < BREAKPOINT;
  const navigate = useNavigate();
  const [themeName, setThemeName] = useLocalStorage('color-mode', 'light');
  const [loggedIn, setLoggedIn] = useLocalStorage('__PROSPER_EX__loggedIn', false);
  const [userInfo, setUserInfo] = useLocalStorage('__PROSPER_EX__userInfo', {});
  const [loggedInTimestamp, setLoggedInTimestamp] = useLocalStorage('__PROSPER_EX__sign_timestamp', 0);
  const { signMessageAsync } = useSignMessage();
  const isDark = themeName === 'dark';
  const [menuCollapsed, setMenuCollapsed] = useState(width < BREAKPOINT || false);
  const [isWalletsPanelOpen, setIsWalletsPanelOpen] = React.useState(false);
  const location = window.location.pathname;
  const toast = useToast();
  const isNodeSalePage = location.indexOf('node-sale') !== -1;
  const toggleColorMode = () => {
    if (themeName === 'light') {
      setThemeName('dark');
    } else {
      setThemeName('light');
    }
  };

  useEffect(() => {
    if (width > BREAKPOINT) {
      setMenuCollapsed(false);
    } else {
      setMenuCollapsed(true);
    }
  }, [width]);

  const toggleMenu = () => {
    if (width < BREAKPOINT) {
      setMenuCollapsed(!menuCollapsed);
    }
  };

  const links = menuItems.map((item, idx) => {
    if (item.name.indexOf('Download') !== -1) {
      return (
        <Popover key={idx} trigger="hover">
          <PopoverTrigger>
            <p style={{ cursor: 'pointer', width: 'max-content' }}>{t('Download', 'Download')}</p>
          </PopoverTrigger>
          <PopoverContent className={styles.PopoverContent}>
            <PopoverBody className={styles.download} onClick={toggleMenu}>
              <a
                href="https://play.google.com/store/apps/details?id=com.prosperex.android_dex"
                target="_blank"
                rel="noopener noreferrer">
                Android Google Store(1.0.1)
              </a>
            </PopoverBody>
            <hr />
            <PopoverBody className={styles.download}>
              <a
                href="https://www.prosperex.xyz/app/prosperex.apk"
                target="_blank"
                rel="noopener noreferrer"
                onClick={toggleMenu}>
                Download Android APK(1.0.4)
              </a>
            </PopoverBody>
            <hr />
            <PopoverBody className={styles.download} onClick={toggleMenu}>
              <a
                href="https://testflight.apple.com/join/yF58LlbU"
                rel="noopener noreferrer"
                target={'_blank'}
                onClick={toggleMenu}>
                IOS(Test Flight)(1.0.0)
              </a>
            </PopoverBody>
            {/* <hr /> */}
            {/* <PopoverBody className={styles.download} onClick={toggleMenu}>
              <a
                href="https://test.guludeveloper.com/kWy0?a1d5MA=="
                rel="noopener noreferrer"
                target={'_blank'}
                onClick={toggleMenu}>
                IOS Download(1.0.0)
              </a>
            </PopoverBody> */}
          </PopoverContent>
        </Popover>
      );
    } else if (item.name.indexOf('T-BOND') !== -1 || item.name.indexOf('Trades') !== -1) {
      return (
        <Popover key={idx} trigger="hover">
          <PopoverTrigger>
            <p style={{ cursor: 'pointer', width: 'max-content' }}>{item.name}</p>
          </PopoverTrigger>
          <PopoverContent className={styles.PopoverContent}>
            <PopoverBody className={styles.download} style={{ textAlign: 'center' }}>
              Coming Soon ···
            </PopoverBody>
          </PopoverContent>
        </Popover>
      );
    } else {
      return (
        <Link
          key={idx}
          className={styles.menuItem}
          to={item.link}
          target={item.link.indexOf('http') !== -1 ? '_blank' : '_self'}
          rel={item.link.indexOf('http') !== -1 ? 'noopener noreferrer' : 'none'}
          onClick={toggleMenu}>
          {t(item.name, item.name)}
        </Link>
      );
    }
  });

  const handleLogin = () => {
    navigate('/login');
    setMenuCollapsed(true);
  };
  const handleRegister = () => {
    navigate('/register');
    setMenuCollapsed(true);
  };

  // 为了和 通过登录页来登录 作区分
  const [isHeaderLogin, setIsHeaderLogin] = useState(false);

  useEffect(() => {
    if (isConnected && isHeaderLogin) {
      login(accountAddress);
    }
  }, [isConnected, isHeaderLogin]);

  const handleSetIsHeaderLogin = () => {
    setIsHeaderLogin(true);
  };

  const handleAvatarClick = async () => {
    try {
      if (isConnected && loggedIn) {
        navigate('/profile');
        setMenuCollapsed(true);
      } else {
        if (isConnected) {
          login(accountAddress);
        } else {
          setIsWalletsPanelOpen(true);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const login = async (address) => {
    try {
      if (isHeaderLogin) {
        setIsHeaderLogin(false);
      }
      const registerResult = await requestRegistered(address);
      const authResult = await requestAuth(address);
      if (registerResult) {
        const signature = await signMessageAsync({ message: String(authResult.messageToSign) });
        const declaredPublicKey = ethers.utils.recoverPublicKey(
          ethers.utils.hashMessage(authResult.messageToSign),
          signature
        );
        const loginResult = await requestLogin({
          signedMessage: authResult.messageToSign,
          declaredPublicKey,
          signature,
          inviteCode: null
        });
        setUserInfo(loginResult);
        setLoggedIn(true);
        setLoggedInTimestamp(Date.now());
        navigate('/profile');
        setMenuCollapsed(true);
      } else {
        navigate('/register?to=profile');
      }
    } catch (err) {
      toast({
        title: 'Login failed',
        description: 'Please try again later',
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    }
  };

  const userIcon = isDark ? UserDarkIcon : UserIcon;
  return (
    <>
      <Box className={useTheme(styles.container)}>
        <MainContainer className={styles.contentContainer}>
          <Flex className={styles.mainFlex}>
            <div className={styles.logoAndBurgerWrapper}>
              <Box className={styles.logo} onClick={() => navigate('/')} />
              <div className={styles.burgerWrapper}>
                {menuCollapsed ? (
                  <Flex gap={4}>
                    {isMobile && <LangButton />}
                    <HamburgerIcon boxSize={7} onClick={toggleMenu} className={styles.hamburger} />
                  </Flex>
                ) : (
                  <SmallCloseIcon boxSize={7} onClick={toggleMenu} className={styles.hamburger} />
                )}
              </div>
            </div>

            {!isMobile && (
              <>
                <LangButton />
                <Flex className={styles.menuContainer}>{links}</Flex>
              </>
            )}
            {isMobile && !menuCollapsed && (
              <>
                <Flex className={styles.menuFlex}>
                  <Flex className={styles.mobileMenuContainer}>{links}</Flex>
                  <Flex className={styles.mobileMenuFooterWrapper}>
                    {!isConnected ? (
                      <>
                        <CommonButton handleClick={handleLogin} name={'Login'} thick />
                        <CommonButton handleClick={handleRegister} name={'Register'} thick outline />
                      </>
                    ) : (
                      <Flex className={styles.personal}>
                        <img className={styles.avatar} src={avatar} onClick={handleAvatarClick} />
                        <Box className={styles.connectStatus}>{t('Connected', 'Connected')}</Box>
                        <Box className={styles.address}>{truncAddress(accountAddress!)}</Box>
                        <Box
                          className={styles.logout}
                          onClick={() => {
                            disconnect();
                            setMenuCollapsed(true);
                          }}>
                          {t('Logout', 'Logout')}
                        </Box>
                      </Flex>
                    )}
                    <Divider />
                    <Flex className={styles.socialWrapper}>
                      <Flex className={styles.socialContainer}>
                        {socialItems.map((item, index) => {
                          return (
                            <a
                              key={`social-${index}`}
                              href={item.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ cursor: 'pointer' }}>
                              <img src={item.src} alt="" key={item.name} width="32px" height="32px" />
                            </a>
                          );
                        })}
                        <Box onClick={() => toggleColorMode()}>
                          {themeName === 'dark' ? (
                            <img src={DarkIcon} alt="" width="32px" height="32px" />
                          ) : (
                            <img src={LightIcon} alt="" width="32px" height="32px" />
                          )}
                        </Box>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </>
            )}

            {width > BREAKPOINT && (
              <Flex gap="1rem" onClick={handleAvatarClick} cursor="pointer">
                {/* <Flex gap="0.4rem" height="2.5rem" alignItems="center" className={styles.themeSwitch}>
                  <SunIcon />
                  <Switch id="isChecked" isChecked={themeName === 'dark'} onChange={toggleColorMode} />
                  <MoonIcon />
                </Flex> */}
                {/* <CommonButton handleClick={undefined} name={'Join now'} /> */}
                <Img src={userIcon} alt="" width="40px" height="40px" />
              </Flex>
            )}
          </Flex>
        </MainContainer>
      </Box>
      <WalletsPanel isOpen={isWalletsPanelOpen} setIsOpen={setIsWalletsPanelOpen} afterLogin={handleSetIsHeaderLogin} />
    </>
  );
}
