import React from 'react';
import { Box } from '@chakra-ui/react';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import styles from './Carousel.module.scss';
import { CarouselItem } from './CarouselItem';
import { PriceApiResult } from '../../types';

type Prop = {
  items: Array<PriceApiResult>;
};

export function Carousel({ items }: Prop) {
  const animation = { duration: 3000, easing: (t: any) => t };
  const [sliderRef] = useKeenSlider({
    loop: true,
    renderMode: 'precision',
    drag: true,
    slides: {
      perView: 8
    },
    breakpoints: {
      '(max-width: 768px)': {
        slides: {
          perView: 2.5
        }
      }
    },

    created(s) {
      s.moveToIdx(1, true, animation);
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 1, true, animation);
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 1, true, animation);
    }
  });

  return (
    <Box className={styles.carouselContainer}>
      <Box className={styles.carouselContent}>
        <Box ref={sliderRef} className="keen-slider">
          {[...items, ...items].map((item, idx) => (
            <CarouselItem key={idx} item={item} />
          ))}
        </Box>
      </Box>
    </Box>
  );
}
