import { ethers } from 'ethers';
import { useNameservice } from '../store/nameservice-context';
import {
  type ERC20,
  type ManagerInterface,
  ManagerInterface__factory,
  ERC20__factory
} from '../typechain';
import { PPEInterface__factory } from '../typechain/factories/contracts/token/ppe';
import { PPEInterface } from '../typechain/contracts/token/ppe/PPEInterface';

type ContractStatus = 'loading' | 'success' | 'error';

interface Contracts {
  status: ContractStatus;
  usdt?: ERC20;
  ppe?: PPEInterface;
  manager?: ManagerInterface;
}

export const useContracts = (): Contracts => {
  const ns = useNameservice();
  
  if (ns === null || window.ethereum == null) {
    return {
      status: 'loading'
    };
  }
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum as any);
    const managerAddress = ns.Manager;
    const manager = ManagerInterface__factory.connect(managerAddress, provider.getSigner());
    const ppeAddress = ns.ppe;
    const ppe = PPEInterface__factory.connect(ppeAddress, provider.getSigner());
    const usdtAddress = ns.usdt;
    const usdt = ERC20__factory.connect(usdtAddress, provider.getSigner());

    return {
      status: 'success',
      manager,
      ppe,
      usdt,
    };
  } catch (error) {
    return {
      status: 'error'
    };
  }

};
