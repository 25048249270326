/* eslint-disable indent */
import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useTheme } from '../../hooks/useTheme';
import styles from './index.module.scss';
import OtcDesk from './otcDesk/OtcDesk';

import { useLocation, useNavigate } from 'react-router-dom';
import OtcHistory from './history/History';
import CreateOrder from './createOrder';
import { useWallet } from '../../store/wallet-context';

export default function Otc() {
  const location = useLocation();
  const navigate = useNavigate();
  const { isConnected } = useWallet();
  const queryParams = new URLSearchParams(location.search);
  const currentPage = queryParams.get('page');
  let content = null;
  switch (currentPage) {
    case 'home':
      content = <OtcDesk />;
      break;
    case 'history':
      content = <OtcHistory />;
      break;
    case 'create':
      content = <CreateOrder />;
      break;
    default:
      content = <OtcDesk />;
      break;
  }

  useEffect(() => {
    if (!isConnected) {
      navigate('/login?referrer=otc');
    }
  }, [isConnected]);

  return <Box className={useTheme(styles.container)}>{content}</Box>;
}
