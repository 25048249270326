import { Flex } from '@chakra-ui/react';
import styles from './TermsAndConditions.module.scss';
import React from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { CommonButton } from '../UI/CommonButton';
import { useWallet } from '../../store/wallet-context';
import { useTranslation } from 'react-i18next';
type Props = {
  onAgree: () => void;
};

export function TermsAndConditions({ onAgree }: Props) {
  const { t } = useTranslation();
  const { accountAddress } = useWallet();
  const [agreeed, setAgreed] = useLocalStorage(
    `__PROSPER_EX__agreedNodeSaleTermsAndConditions_${accountAddress}`,
    false
  );
  return (
    <Flex className={styles.container}>
      {t(
        'We offer limited amount of nodes worldwide at a cost of $1200 each',
        'We offer limited amount of nodes worldwide at a cost of $1200 each'
      )}
      {!agreeed && (
        <CommonButton
          className={styles.agreeButton}
          handleClick={() => {
            setAgreed(true);
            onAgree();
          }}
          name={t('I agree', 'I agree')}
        />
      )}
    </Flex>
  );
}
