import { Flex, Box, useToast } from '@chakra-ui/react';
import styles from './Deposit.module.scss';
import { CopyIcon } from '@chakra-ui/icons';
import QRCode from 'react-qr-code';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useState } from 'react';
import React from 'react';
import { CommonModal } from '../UI/CommonModal';
import { TermsAndConditions } from './TermsAndConditions';
import { useLocalStorage } from 'usehooks-ts';
import classNames from 'classnames';

const addresses = [
  '0xFAdA68CAC312B91DA6fa1337b50E853472151a6e',
  '0x16A1606DBEe3288B22C8b63B121B6557475Cce08',
  '0xE4F1676a00Fc78F1A004c2aFc8A1B795c5f5D8dD',
  '0xa6D10A3186473b8d841e39A4249B7F8E734573aA',
  '0xe6f2EFF3f9cF103c264b44D2B3b959Eca625C068',
  '0x7889D19cC15EbbCCD85B814798eb06011ED4a92A',
  '0x414067863E16134E1E7112Af3F9d7429A55A2863',
  '0x131eeedE47871bdB763e38810FcF4D1Fee335676',
  '0x5A08504A5b380BB2d6cf312d95B5301B4CBa3405',
  '0x6dFbF9277c060CaEe8B33Cd3EcD4A6cf7E240Bad',
  '0x2E3EC9Effc104761567F4FeEee753De9356bc26a',
  '0xC0c25218732f1b1F6B7ADB71261964B3227e32a0',
  '0x5CB5e06Fa6Cf4b35FF2918e109f562b9F56afDCb',
  '0x2fA3BAAA7b68003c4c103f38D04C2B752ef12BAf',
  '0x324e17093e7b4E654F12A498fdBb62F378612791',
  '0x62a17F3470FDfdD6cc5DD01Bf4AF782e130AB71c',
  '0x4D8BAC8b79236a8C9e29cc019FAaa4d4bb2b6af6',
  '0x06252363C93f5118dBC94Ab05479e10b93762E32',
  '0xA90f7B3Bd21921d6A5B30a44b1e823716c7fb0EC',
  '0xB316dDE7DD81C4b5F9b8Fe377C72Bf89298831Ed',
  '0xfd3f636B993E6de4097ABCF448a1FcE5c79f41b5',
  '0x71aEfe92CDa5D71dB855b8b42a56B2e7608D32B5',
  '0x00Fe135f62Bc80bca545FB831c62dffbB652894D',
  '0x314BFfe4979b29e725f2EfEdE5f27742A993D2AF',
  '0x768C09e41e870B9A8a53AB0cb9eF340A38d914d6',
  '0x5BC1f310eC0e9aaa27b742E9ea417730bf69125B',
  '0x01b9bA08f06b2dca9Fbb722ac27Bb2FD328E5240',
  '0x369a119c5b3d370A1494F27225BffF35C7F6FFe3',
  '0x9d7401ED34C7536d1fAeB043677E7Bd47161a530',
  '0xBB2601E3994438d20D52fc59ecE29AFE3FCeB30f',
  '0x29038AA3CfaE5480ed80692f61230fF89577744b',
  '0xC794139d827E1F173fe85f7193708Ca0796F9A5D',
  '0x078A38dEDcdF2d9D2f09585b4f7a35005E3D56E0',
  '0xc1086Fa61FC2780EF2500dbe331939F9a62f71d2',
  '0x04976cd2d8E1fd83c2af7E4CBbB9b4Eb301D4A89',
  '0xDb83B9a2fc81A77E5700331739aC88fB61C7022B',
  '0xF328B35e264d5881200e0Dc4Df1Ce502aC63BdCc',
  '0x9a949c9CDB57cf78Ad2Baf1736B2E14871036623',
  '0x46d5e4FFFf9A780D9d449a37cf5F5F2007f72193',
  '0xD22d908Ec67d911D72c99aDa0b9FB04cE80ea4De',
  '0x2Fd33Cb83F0627613c908c68f2e0cce9Afd355F8',
  '0x75197E9C6c0583c2692c28E143b2431976e9407d',
  '0x2aF038e6420186f67c6cabAA4aF7881a9CD473F3',
  '0xC1927794D97BB697d2Fe01B167B74bFC7566Bb8C',
  '0x2Cb55d1F62B335D8653A1733e5350b9Fa25Cdcd4',
  '0xad7D6C7F93Bd42d5793ddb32873ed4C28e2C6B4D',
  '0x5Cf651556244591304aD593bF49a27Fc4A247ca2',
  '0x1247de8b84B6c036cA9c729e8D7c0C09ec8eF6bb',
  '0xdE7e7bB22b2494C7B31141b539a7A234757528f3'
];

export function Deposit() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [agreeed, setAgreed] = useLocalStorage('__PROSPER_EX__agreedTermsAndConditions', false);

  const randomIndex = Math.floor(Math.random() * addresses.length);
  const [address, setAddress] = useState(addresses[randomIndex]);
  const toast = useToast();
  const handleCopy = () => {
    toast({
      title: 'Address copied to clipboard!',
      status: 'success',
      duration: 3000,
      isClosable: true
    });
  };
  return (
    <>
      <Flex className={styles.mainFlex}>
        <Box className={styles.title}> Early Bird Plan</Box>

        <Flex className={styles.priceFlex}>
          <Flex className={styles.priceLabel}>
            <img src={require('../../assets/image/earlybird/price-icon.png')} alt="earlybird" />
            <Box className={styles.priceLabelText}>Early Price:</Box>
          </Flex>
          <Flex className={styles.priceItemsFlex}>
            <Box className={styles.priceItem}>
              <span>10000 USDT</span>
              /10 Nodes
            </Box>
            {/* <Box className={styles.priceItem}>
              <span>1000 USDT</span>
              /each
            </Box> */}
          </Flex>
        </Flex>

        {agreeed ? (
          <Flex
            className={classNames(styles.termsAndConditions, styles.agreed)}
            alignItems="center"
            gap={2}
            justifyContent="center"
          >
            <img src={require('../../assets/image/earlybird/tick.png')} alt="" width={'30px'} />
            You have agreeed to
            <span onClick={() => setIsOpen(true)}>Terms and Conditions</span>
          </Flex>
        ) : (
          <Flex className={styles.termsAndConditions} justifyContent="center" gap={1}>
            Before joining you need to agree to <span onClick={() => setIsOpen(true)}>Terms and Conditions</span>
          </Flex>
        )}

        {agreeed && (
          <>
            <Flex className={styles.depositDescFlex}>
              <Box className={styles.depositDesc}>Deposit by using the address below</Box>
              <Box className={styles.depositTip}>Min 10 Nodes</Box>
              <Flex className={styles.depositAddress}>
                <Box className={styles.depositAddressText}>{address}</Box>
                <CopyToClipboard text={address} onCopy={handleCopy}>
                  <CopyIcon className={styles.copyIcon} style={{ cursor: 'pointer' }} />
                </CopyToClipboard>
              </Flex>
            </Flex>

            <Flex className={styles.infoFlex}>
              <Flex className={styles.qrCodeFlex}>
                <Box className={styles.qrCode}>
                  <QRCode
                    value={address}
                    size={120}
                    style={{
                      background: 'white',
                      padding: '0.3rem',
                      borderRadius: '0.3rem'
                    }}
                  />
                </Box>
                <Box className={styles.qrCodeDesc}>You can copy the address or scan the QR code</Box>
              </Flex>
              <Flex className={styles.noticeFlex}>
                <Flex className={styles.noticeTitle}>
                  <img src={require('../../assets/image/earlybird/notice-icon.png')} alt="earlybird" />
                  Notice
                </Flex>
                <Box className={styles.noticeDetail}>
                  Payments are final and non-refundable. Send exactly 10,000 USDT; underpayments get 0 nodes,
                  overpayments won't be refunded. Use your BSC (BEP-20) wallet only, not exchange accounts, as nodes
                  will be sent to it directly.
                </Box>
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
      <CommonModal isOpen={isOpen} setIsOpen={setIsOpen} className={styles.nftModal}>
        <TermsAndConditions onAgree={() => setIsOpen(false)} />
      </CommonModal>
    </>
  );
}
