import React from 'react';

import { useTheme } from '../../hooks/useTheme';
import { useLocalStorage } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';
import MainContainer from '../UI/MainContainer/MainContainer';

import styles from './SponsorAndBack.module.scss';

const SponsorAndBack = () => {
  //@ts-ignore
  const { t } = useTranslation();
  const [themeName] = useLocalStorage('color-mode', 'light');

  const sponsorsList = [
    {
      id: '1',
      sponsorName: 'EMURGO',
      imgSrc: require(`../../assets/image/sponsor/${themeName === 'light' ? 'light mode' : 'dark mode'}/emurgo.png`)
    },
    {
      id: '2',
      sponsorName: 'Central Research',
      imgSrc: require(`../../assets/image/sponsor/${
        themeName === 'light' ? 'light mode' : 'dark mode'
      }/centralResearch.png`)
    },
    {
      id: '3',
      sponsorName: 'AC Capital',
      imgSrc: require(`../../assets/image/sponsor/${themeName === 'light' ? 'light mode' : 'dark mode'}/acCapital.png`)
    },
    {
      id: '4',
      sponsorName: 'Roaming Capital',
      imgSrc: require(`../../assets/image/sponsor/${
        themeName === 'light' ? 'light mode' : 'dark mode'
      }/roamingCapital.png`)
    },
    {
      id: '5',
      sponsorName: 'Magnus Capital',
      imgSrc: require(`../../assets/image/sponsor/${
        themeName === 'light' ? 'light mode' : 'dark mode'
      }/magnusCapital.png`)
    }
  ];

  const backList = [
    {
      id: '1',
      backName: 'BitMart',
      imgSrc: require(`../../assets/image/back/${themeName === 'light' ? 'light mode' : 'dark mode'}/bitmart.png`)
    },
    {
      id: '2',
      backName: 'Binance Smart Chain',
      imgSrc: require(`../../assets/image/back/${themeName === 'light' ? 'light mode' : 'dark mode'}/bsc.png`)
    },
    {
      id: '3',
      backName: 'Chain Catcher',
      imgSrc: require(`../../assets/image/back/${themeName === 'light' ? 'light mode' : 'dark mode'}/chainCatcher.png`)
    },
    {
      id: '4',
      backName: 'Galxe',
      imgSrc: require(`../../assets/image/back/${themeName === 'light' ? 'light mode' : 'dark mode'}/galxe.png`)
    },
    {
      id: '5',
      backName: '金色财经',
      imgSrc: require(`../../assets/image/back/${themeName === 'light' ? 'light mode' : 'dark mode'}/goldenEcon.png`)
    },
    {
      id: '6',
      backName: 'Hypex',
      imgSrc: require(`../../assets/image/back/${themeName === 'light' ? 'light mode' : 'dark mode'}/hypex.png`)
    },
    {
      id: '7',
      backName: 'imToken',
      imgSrc: require(`../../assets/image/back/${themeName === 'light' ? 'light mode' : 'dark mode'}/imToken.png`)
    },
    {
      id: '8',
      backName: 'Metaera',
      imgSrc: require(`../../assets/image/back/${themeName === 'light' ? 'light mode' : 'dark mode'}/metaera.png`)
    },
    {
      id: '9',
      backName: 'polygon',
      imgSrc: require(`../../assets/image/back/${themeName === 'light' ? 'light mode' : 'dark mode'}/polygon.png`)
    },
    {
      id: '10',
      backName: 'Token Pocket',
      imgSrc: require(`../../assets/image/back/${themeName === 'light' ? 'light mode' : 'dark mode'}/tokenpocket.png`)
    },
    {
      id: '11',
      backName: 'Wallet Connect',
      imgSrc: require(`../../assets/image/back/${themeName === 'light' ? 'light mode' : 'dark mode'}/walletConnect.png`)
    }
  ];

  return (
    <div className={useTheme(styles.sponsorWrapper)}>
      <MainContainer className={styles.sponsorAndBackContainer}>
        <h2>{t('Investors', 'Investors')}</h2>
        <div className={styles.investorsIconWrapper}>
          {sponsorsList.map((item, index) => (
            <img key={item.id} src={item.imgSrc} alt={item.sponsorName} />
          ))}
        </div>
      </MainContainer>
      <MainContainer>
        <hr />
      </MainContainer>
      <MainContainer className={styles.sponsorAndBackContainer}>
        <h2>{t('Partners', 'Partners')}</h2>
        <div className={styles.iconWrapper}>
          {backList.map((item) => (
            <img key={item.id} src={item.imgSrc} alt={item.backName} />
          ))}
        </div>
      </MainContainer>
    </div>
  );
};

export default SponsorAndBack;
