import React from 'react';
import { Box } from '@chakra-ui/react';
import './Carousel.module.scss';
import TextCard from './TextCard';

type whyUsIntroType = {
  imgSrc: string;
  title: string;
  intro: string;
  desc: string;
};
type Prop = {
  item: whyUsIntroType;
};
export function TextCarouselItem({ item }: Prop) {
  return (
    <Box className="keen-slider__slide">
      <TextCard {...item} />
    </Box>
  );
}
