import { tokenAxios } from '../index';
import { PurchaseNodeInfo, performanceResult, purchaseResult } from '../../utils/types';

const businessName = process.env.REACT_APP_BUSINESS_NAME;

export const requestPurchaseNode = async () => {
  const result = await tokenAxios.get<PurchaseNodeInfo[]>(`/purchaseNode?businessName=${businessName}`);
  return result.data;
};

export const requestPurchaseBusiness = async () => {
  const result = await tokenAxios.post(`/purchaseNode/getPurchaseNodeBusiness?businessName=${businessName}`);
  return result.data;
};

export const requestPerformance = async () => {
  const result = await tokenAxios.get<performanceResult>(`/purchaseNode/${businessName}/performance`);
  return result.data;
};

export const requestPurchase = async (payload: { purchaseNodeAmount: number }) => {
  const result = await tokenAxios.post<purchaseResult>('/purchaseNode/purchase', {
    businessName,
    purchaseNodeAmount: payload.purchaseNodeAmount
  });
  return result.data;
};
