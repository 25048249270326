import { Tree, TreeObject } from '@jlguenego/tree';

type AssetInfo = {
  cost: string;
  cost18: string;
  nodeAmount: number;
};

export type GroupInfo = {
  user: {
    id: number;
    walletAddress: string;
  };
  self: AssetInfo;
  group: AssetInfo;
  count: number;
  hierarchy: number;
  next: GroupInfo[];
};

export type GroupInfoNode = {
  user: {
    id: number;
    walletAddress: string;
  };
  hierarchy: number;
  orgStructure: number;
  self: AssetInfo;
  group: AssetInfo;
};

export const toNode = (data: GroupInfo): TreeObject<GroupInfoNode> => {
  return {
    node: {
      user: data.user,
      hierarchy: data.hierarchy,
      orgStructure: data.count,
      self: data.self,
      group: data.group
    },
    children: data.next.map(toNode)
  };
};

export const convertToTree = (groupInfo: GroupInfo) => {
  const tree = Tree.fromObject<GroupInfoNode>(toNode(groupInfo));
  return tree;
};

export const countTreeNodes = (tree: GroupInfo) => {};

export const findSubTreeById = (tree: Tree<GroupInfoNode>, address: string) => {
  if (compareAddress(tree.node.user.walletAddress, address)) {
    return tree;
  }
  let found: Tree<GroupInfoNode> | undefined;
  tree.children.forEach((child) => {
    if (!found) {
      found = findSubTreeById(child, address);
    }
  });
  return found;
};

export const compareAddress = (a: string, b: string) => {
  if(!a || !b) return false;
  return a.toLowerCase() === b.toLowerCase();
};
