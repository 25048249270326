import { Input, useToast } from '@chakra-ui/react';
import { CommonButton } from '../UI/CommonButton';
import MainContainer from '../UI/MainContainer/MainContainer';
import emailjs from '@emailjs/browser';
import { useTheme } from '../../hooks/useTheme';
import React from 'react';

import styles from './SignUp.module.scss';
import { useTranslation } from 'react-i18next';

const SignUp = () => {
  //@ts-ignore
  const { t } = useTranslation();
  const [email, setEmail] = React.useState('');
  const handleChange = (e: any) => setEmail(e.target.value);

  const [loading, setLoading] = React.useState<boolean>(false);
  const toast = useToast();
  const toastIdRef = React.useRef<any>();
  function closeLoadingToast() {
    setLoading(false);
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  }

  const handleSubmit = async () => {
    if (loading) return;
    const emailError = !email || !email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
    if (emailError) {
      toast({
        description: t('Please check your inputs.', 'Please check your inputs.'),
        status: 'error',
        duration: 2000,
        isClosable: false
      });
    } else {
      const serviceId = process.env.REACT_APP_SERVICE_ID;
      const messageTemplateId = process.env.REACT_APP_MESSAGE_SUBSCRIBE_TEMPLATE_ID;
      // const thanksTemplateId = process.env.REACT_APP_THANKS_SUBSCRIBE_TEMPLATE_ID;
      const pubkey = process.env.REACT_APP_PUBKEY;
      if (!serviceId || !messageTemplateId || !pubkey) {
        console.error('Missing env variables');
        return;
      }
      setLoading(true);
      // toastIdRef.current = toast({
      //   description: 'loading...',
      //   status: 'success',
      //   duration: 60_000
      // });

      await emailjs
        .send(
          serviceId,
          messageTemplateId,
          {
            from_name: 'prosperex landing page',
            to_name: 'prosperex',
            message: email
          },
          pubkey
        )
        .catch((err) => {
          console.error(err);
          closeLoadingToast();
          toast({
            description: 'fail to send email, please try again later',
            status: 'error',
            duration: 3_000
          });
        })
        .then(
          (result) => {
            closeLoadingToast();
            toast({
              description: 'Thanks for your subscription',
              status: 'success',
              duration: 2000
            });
          },
          (error) => {
            closeLoadingToast();
          }
        );

      // emailjs
      //   .send(
      //     serviceId,
      //     thanksTemplateId,
      //     {
      //       from_name: name,
      //       from_address: email
      //     },
      //     pubkey
      //   )
    }
  };
  return (
    <div className={useTheme(styles.signUpWrapper)}>
      <MainContainer className={styles.mainContainer}>
        <div className={styles.contentWrapper}>
          <img src={require('../../assets/image/signup/email.png')} alt="email" />
          <h2>
            {t('Join the future of trading.', 'Join the future of trading.')} <br />
            <span>
              {t('Experience', 'Experience')} <br />
              {t('ProsperEX today', 'ProsperEX today')}
            </span>
          </h2>
        </div>
        <div className={styles.inputWrapper}>
          <Input
            onChange={handleChange}
            value={email}
            placeholder={t('Type your Email to join us', 'Type your Email to join us')}
            className={styles.input}
            focusBorderColor={'none'}
          />
          <CommonButton
            handleClick={handleSubmit}
            name={t('Subscribe', 'Subscribe')}
            className={styles.subscribe}
            loading={loading}
          />
        </div>
      </MainContainer>
    </div>
  );
};

export default SignUp;
