import { formatEther, parseEther } from 'ethers/lib/utils.js';
import { ManagerType } from '../../typechain/contracts/manager/ManagerInterface';
import { truncateNumber, bytes32ToString } from '../../utils/utils';
import { OtcBill } from '../../utils/types';

export const formatter = (bill: ManagerType.OTCPurchaseTicketStructOutput): OtcBill => {
  return {
    ppeAmount: truncateNumber(formatEther(bill.takerVolume)),
    buyPrice: truncateNumber(formatEther(bill.makerVolume.mul(parseEther('1')).div(bill.takerVolume))),
    usdtAmount: truncateNumber(formatEther(bill.makerVolume)),
    createdAt: new Date(bill.makerTimestamp.toNumber() * 1000).toLocaleString(),
    ticketId: bill.ticketId.toString(),
    status: bytes32ToString(bill.status),
    maker: bill.maker,
  };
};
