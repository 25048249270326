import axios from 'axios';
import { ClaimPpeResult, PendingClaimPpeResult, RegisterResult, UserInfo, withdrawReadyResult } from './types';
import { GroupInfo, toNode } from './groupUtils';
import { Tree } from '@jlguenego/tree';
export interface AuthResult {
  walletAddress: string;
  isRegister: boolean;
  nonce: number;
}

const baseUrl = process.env.REACT_APP_API_URL!;

const authUrl = baseUrl + '/auth';
export const requestAuth = async (address: string) => {
  const result = await axios.post<AuthResult>(`${authUrl}/${address}`);
  return result.data;
};

const loginUrl = baseUrl + '/login';
export const requestLogin = async (walletAddress: string, signature: string) => {
  const result = await axios.post<AuthResult>(loginUrl, { walletAddress, signature });
  return result.data;
};

export const requestRegisger = async (walletAddress: string, signature: string, inviteCode: string) => {
  const result = await axios.post<RegisterResult>(loginUrl, { walletAddress, signature, inviteCode });
  return result.data;
};

type RefType = {
  walletAddress: string;
  nodeAmount: number;
  cost: string;
}
type InvitationType = {
  group: {
    count: number;
    list: RefType[];
    bill: RefType[];
    amount: string;
  };
  direct: {
    count: number;
    list: RefType[];
    bill: RefType[];
    amount: string;
  };
  
}
const userInvitationUrl = baseUrl + '/user/invitation';
export const requestInvitationInfo = async (address: string) => {
  const result = await axios.get<InvitationType>(`${userInvitationUrl}/${address}`);
  return result.data;
};

type NodeInfo = {
  totalAmount: number;
  sold: number;
  price: number;
  startTime: number;
  round: number;
};
const userUrl = baseUrl + '/user';
export const requestUserInfo = async (address: string) => {
  const result = await axios.get<UserInfo>(`${userUrl}/${address}`);
  return result.data;
};

const nodeUrl = baseUrl + '/node';
export const requestNodeInfo = async (): Promise<NodeInfo> => {
  const result = await axios.get<NodeInfo>(nodeUrl);
  const data = result.data;
  // add 100 fake
  return {
    ...data,
    sold: 2100
  };
};

const claimUrl = baseUrl + '/user/claim';
export const requestClaimPpe = async (address: string, signature: string) => {
  const result = await axios.post<ClaimPpeResult>(`${claimUrl}/${address}`, { signature });
  return result.data;
};
const pendingClaimUrl = baseUrl + '/user/claim/pending';
export const requestPendingClaimPpe = async (address: string) => {
  const result = await axios.get<PendingClaimPpeResult>(`${pendingClaimUrl}/${address}`);
  return result.data;
};
const withdrawReadyUrl = baseUrl + '/user/claim/status';
export const requestWithdrawReadyStatus = async (address: string) => {
  const result = await axios.get<withdrawReadyResult>(`${withdrawReadyUrl}/${address}`);
  return result.data;
};

// group

const gourpInfoUrl = baseUrl + '/user/invitation';
export const requestGroupInfo = async (address: string) => {
  const result = await axios.get<GroupInfo>(`${gourpInfoUrl}/${address}/tree`);
  const data = result.data;
  // const data: GroupInfo = {
  //   user: {
  //     id: 1,
  //     walletAddress: '0xA0EA2cdF55B5F03c236F94263D15BC4B19eB253a'
  //   },
  //   self: {
  //     cost: '100',
  //     cost18: '100',
  //     nodeAmount: 1
  //   },
  //   group: {
  //     cost: '100',
  //     cost18: '100',
  //     nodeAmount: 1
  //   },
  //   count: 1,
  //   hierarchy: 2,
  //   next: [
  //     {
  //       user: {
  //         id: 1,
  //         walletAddress: '0xA0EA2cdF55B5F03c236F94263D15BC4B19eB253b'
  //       },
  //       self: {
  //         cost: '100',
  //         cost18: '100',
  //         nodeAmount: 1
  //       },
  //       group: {
  //         cost: '100',
  //         cost18: '100',
  //         nodeAmount: 1
  //       },
  //       count: 1,
  //       hierarchy: 2,
  //       next: [
  //         {
  //           user: {
  //             id: 1,
  //             walletAddress: '0xA0EA2cdF55B5F03c236F94263D15BC4B19eB2534'
  //           },
  //           self: {
  //             cost: '100',
  //             cost18: '100',
  //             nodeAmount: 1
  //           },
  //           group: {
  //             cost: '100',
  //             cost18: '100',
  //             nodeAmount: 1
  //           },
  //           count: 1,
  //           hierarchy: 2,
  //           next: []
  //         },
  //         {
  //           user: {
  //             id: 1,
  //             walletAddress: '0xA0EA2cdF55B5F03c236F94263D15BC4B19eB2539'
  //           },
  //           self: {
  //             cost: '100',
  //             cost18: '100',
  //             nodeAmount: 1
  //           },
  //           group: {
  //             cost: '100',
  //             cost18: '100',
  //             nodeAmount: 1
  //           },
  //           count: 1,
  //           hierarchy: 2,
  //           next: []
  //         }
  //       ]
  //     },
  //     {
  //       user: {
  //         id: 1,
  //         walletAddress: '0xA0EA2cdF55B5F03c236F94263D15BC4B19eB253c'
  //       },
  //       self: {
  //         cost: '100',
  //         cost18: '100',
  //         nodeAmount: 1
  //       },
  //       group: {
  //         cost: '100',
  //         cost18: '100',
  //         nodeAmount: 1
  //       },
  //       count: 1,
  //       hierarchy: 2,
  //       next: []
  //     }
  //   ]
  // };
  return Tree.fromObject(toNode(data));
};
