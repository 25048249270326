import React, { useRef } from 'react';
import { CommonButton } from '../UI/CommonButton';
import { useWallet } from '../../store/wallet-context';
import { truncAddress } from '../../utils/utils';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import { useWeb3Modal } from '@web3modal/react';
import styles from './index.module.scss';
// import { useConnect } from 'wagmi';
import { WalletsPanel } from './WalletsPanel';

const MultiWalletButton = () => {
  //@ts-ignore
  const { t } = useTranslation();
  const { open: openWeb3Modal } = useWeb3Modal();
  const [isWalletsPanelOpen, setIsWalletsPanelOpen] = React.useState(false);
  const [updated, setUpdated] = React.useState(false);
  const ref = useRef<HTMLDivElement>(null);
  // console.log('ref', ref);
  const walletButton = ref?.current
    ?.querySelector('w3m-button')
    ?.shadowRoot?.querySelector('w3m-connect-button')
    ?.shadowRoot?.querySelector('wui-connect-button')
    ?.shadowRoot?.querySelector('button');
  // console.log('walletButton', walletButton);
  if (walletButton) {
    walletButton.classList.add(styles.walletBtn);
    walletButton.style.width = '100%';
    walletButton.style.height = '60px';
    walletButton.style.backgroundColor = '#394dee';
  }
  setTimeout(() => {
    if (!updated) {
      setUpdated(true);
    }
  }, 1);

  const handleConnect = async () => {
    await openWeb3Modal();
  };
  const handleOpenWallletsPanel = async () => {
    await setIsWalletsPanelOpen(true);
  };

  return (
    <Box>
      <Box className={styles.walletBtnContainer} ref={ref}>
        <WalletButton onClick={handleOpenWallletsPanel} />
      </Box>
      <WalletsPanel isOpen={isWalletsPanelOpen} setIsOpen={setIsWalletsPanelOpen} />
    </Box>
  );
};

const WalletButton = ({ onClick }: { onClick: () => void }) => {
  //@ts-ignore
  const { t } = useTranslation();
  const { connect, disconnect, accountAddress, isConnected } = useWallet();
  if (accountAddress && isConnected) {
    return (
      <CommonButton name={truncAddress(accountAddress)} handleClick={disconnect} thick className={styles.walletBtn} />
    );
  }
  return (
    <CommonButton
      name={t('Connect Wallet', 'Connect Wallet')}
      thick
      handleClick={onClick}
      className={styles.walletBtn}
    />
  );
};

export default MultiWalletButton;
