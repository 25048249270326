import React from 'react';
import MainContainer from '../UI/MainContainer/MainContainer';
import { Flex, Box } from '@chakra-ui/react';
import styles from './index.module.scss';
import { useTheme } from '../../hooks/useTheme';
import { Sale } from './Sale';
import { Benefits } from './Benifits';
import { useTimer } from '../../hooks/useTimer';
import { useTranslation } from 'react-i18next';

export default function NewNodeSaleComponent() {
  //@ts-ignore
  const isMobile = window.matchMedia('(max-width: 700px)').matches;
  const countdown = useTimer({
    deadline: new Date('2024-02-20T14:00:00+08:00')
  });
  const { t } = useTranslation();

  return (
    <Box position="relative" className={useTheme(styles.nodeSaleWrapper)}>
      <Box className={styles.container}>
        <MainContainer className={styles.contentWrapper}>
          <Box className={styles.title}>
            {/* <Trans i18nKey={'Get your first Mini Node'}>
              Get your first <span> subnode </span>
            </Trans> */}
            {t('Get your first Prosper Node', 'Get your first Prosper Node')}
          </Box>
          {!isMobile ? (
            <Flex className={styles.contentContainer}>
              <Sale countdown={countdown} />
              <img src={require('../../assets/image/node/newNodeSaleImage.png')} />
            </Flex>
          ) : (
            <Flex className={styles.contentContainer}>
              <Flex className={styles.mobileContainer}>
                <img src={require('../../assets/image/node/newNodeSaleImage.png')} />
                <Sale countdown={countdown} />
              </Flex>
            </Flex>
          )}
        </MainContainer>
      </Box>
      <Benefits />
    </Box>
  );
}
