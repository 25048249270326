import { useState, useEffect } from 'react';
import { OtcBill } from '../../utils/types';
import { useContracts } from '../useContracts';
import { useWallet } from '../../store/wallet-context';
import { formatter } from './utils';

export const useOtcHistorys = () => {
  const [buys, setBuys] = useState<OtcBill[]>([]);
  const [sells, setSells] = useState<OtcBill[]>([]);

  const contracts = useContracts();
  const { accountAddress } = useWallet();
  const manager = contracts?.manager;
  const managerAddress = manager?.address;
  useEffect(() => {
    const fetchBills = async () => {
      const manager = contracts?.manager;
      if (!manager) return;
      const buys = await manager.listMakerUsdtForPpe(accountAddress);
      const sells = await manager.listTakerUsdtForPpe(accountAddress);

      const formattedBuys = buys.map(formatter);
      const formattedSells = sells.map(formatter);

      setBuys(
        formattedBuys
          .sort((bill) => (bill.status === 'Listing' ? -1 : 1))
          .sort((a, b) => parseFloat(b.buyPrice) - parseFloat(a.buyPrice))
      );
      setSells(
        formattedSells
          .sort((bill) => (bill.status === 'Listing' ? -1 : 1))
          .sort((a, b) => parseFloat(b.buyPrice) - parseFloat(a.buyPrice))
      );
    };
    fetchBills();
  }, [managerAddress, accountAddress]);
  return { buys, sells };
};
