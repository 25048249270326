import React, { useEffect } from 'react';
import styles from './index.module.scss';
import { Box, useToast } from '@chakra-ui/react';
import MainContainer from '../../components/UI/MainContainer/MainContainer';
import { CommonInput } from '../../components/UI/CommonInput';
import { CommonButton } from '../../components/UI/CommonButton';
import { useTheme } from '../../hooks/useTheme';
import { useWallet } from '../../store/wallet-context';
import { useSignMessage } from 'wagmi';
import { verifyMessage } from 'ethers/lib/utils.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import classNames from 'classnames';
import { makeErrorToast, makeSuccessToast } from '../../utils/utils';
import { useTranslation } from 'react-i18next';
import { requestAuth, requestLogin } from '../../service/auth';
import { requestRegistered } from '../../service/account';
import { ethers } from 'ethers';
import WalletButton from '../../components/WalletButton/WalletButton';
import MultiWalletButton from '../../components/MultiWalletButton';

export default function Register() {
  //@ts-ignore
  const { t } = useTranslation();
  const { accountAddress, isConnected } = useWallet();
  const [loggedIn, setLoggedIn] = useLocalStorage('__PROSPER_EX__loggedIn', false);
  const [loggedInTimestamp, setLoggedInTimestamp] = useLocalStorage('__PROSPER_EX__sign_timestamp', 0);
  const [userInfo, setUserInfo] = useLocalStorage('__PROSPER_EX__userInfo', {});
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const refCode = searchParams.get('ref') || '';
  const to = searchParams.get('to') || '';
  const { signMessageAsync } = useSignMessage();
  const navigate = useNavigate();
  const toast = useToast();
  const [invitationCode, setInvitationCode] = React.useState<string>(refCode);
  const handleInvitationCodeChange = (e: any) => {
    setInvitationCode(e.target.value);
  };

  useEffect(() => {
    if (accountAddress) {
      requestRegistered(accountAddress).then((registerResult) => {
        if (registerResult === true) {
          navigate('/login');
        }
      });
    }
  }, [accountAddress]);

  useEffect(() => {
    setLoggedInTimestamp(null);
  }, []);

  const handleRegister = async (connectResult: any) => {
    if (!invitationCode) {
      makeErrorToast(t('Please input your invitation code', 'Please input your invitation code'), toast);
      return;
    }
    const address = accountAddress!;
    const authResult = await requestAuth(address);
    const signature = await signMessageAsync({ message: String(authResult.messageToSign) });

    // debug
    const recoverAddress = verifyMessage(String(authResult.messageToSign), signature);
    // debug

    try {
      const declaredPublicKey = ethers.utils.recoverPublicKey(
        ethers.utils.hashMessage(authResult.messageToSign),
        signature
      );
      const loginResult = await requestLogin({
        signedMessage: authResult.messageToSign,
        declaredPublicKey,
        signature,
        inviteCode: invitationCode
      });
      makeSuccessToast('Register success', toast);
      setUserInfo(loginResult);
      setLoggedIn(true);
      setLoggedInTimestamp(Date.now());
      if (to === 'profile') {
        navigate('/profile');
      } else {
        navigate('/');
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        makeErrorToast(error.response.data.message, toast);
      } else {
        makeErrorToast('Register failed', toast);
      }
    }
  };
  return (
    <Box className={useTheme(styles.container)}>
      <MainContainer className={classNames(styles.contentContainer, styles.mainContainer)}>
        <Box className={styles.container}>
          <Box className={styles.title}>{t('Register', 'Register')}</Box>
          <Box className={styles.inputWrapper}>
            <Box className={styles.inputPrompt}>{t('Input your invitation code', 'Input your invitation code')}</Box>
            <CommonInput
              className={styles.invitationCodeInput}
              value={invitationCode}
              onChange={handleInvitationCodeChange}
              placeholder={t('Invitation code', 'Invitation code')}
            />
            <WalletButton />
            <MultiWalletButton />
            <CommonButton handleClick={handleRegister} name={t('Register', 'Register')} thick disabled={!isConnected} />
          </Box>
          <Box className={styles.gotoLogin}>
            {t('I already have an account', 'I already have an account')}, <a href="login">{t('Login', 'Login')}</a>
          </Box>
        </Box>
      </MainContainer>
    </Box>
  );
}
