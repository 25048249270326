import { Account, AccountInviteesInfo } from '../../utils/types';
import { tokenAxios } from '../index';
import { bsc } from 'wagmi/chains';

const businessName = process.env.REACT_APP_BUSINESS_NAME;
const miniNodeBusinessName = process.env.REACT_APP_MINI_NODE_BUSINESS_NAME;

export const requestAccount = async () => {
  const result = await tokenAxios.get<Account>('/account');
  return result.data;
};

export const requestAccountInvitees = async (payload: { take: number; skip: number }) => {
  const result = await tokenAxios.get<AccountInviteesInfo>(
    `/account/invitees?take=${payload.take}&skip=${payload.skip}&purchaseNodeBusinessName=${businessName}`
  );
  return result.data;
};

export const requestAccountInviteesMini = async (payload: { take: number; skip: number }) => {
  const result = await tokenAxios.get<AccountInviteesInfo>(
    `/account/invitees?take=${payload.take}&skip=${payload.skip}&purchaseNodeBusinessName=${miniNodeBusinessName}`
  );
  return result.data;
};

export const requestAccountUmbrella = async (payload: { take: number; skip: number }) => {
  const result = await tokenAxios.get<AccountInviteesInfo>(
    `/account/umbrella?take=${payload.take}&skip=${payload.skip}&purchaseNodeBusinessName=${miniNodeBusinessName}`
  );
  return result.data;
};

export const requestRegistered = async (walletAddress: string) => {
  const result = await tokenAxios.get<boolean>(`/account/chains/${bsc.id}/wallets/${walletAddress}/hasRegistered`);
  return result.data;
};
