/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  ManagerInterface,
  ManagerInterfaceInterface,
} from "../../../contracts/manager/ManagerInterface";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes32",
        name: "ticketId",
        type: "bytes32",
      },
    ],
    name: "TicketWithdraw",
    type: "event",
  },
  {
    inputs: [],
    name: "accessControl",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "otcTicketId",
        type: "uint256",
      },
    ],
    name: "cancel",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "domainSeparator",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getLastPriceUsdtForPpe",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "ticketId",
            type: "uint256",
          },
          {
            internalType: "bytes32",
            name: "status",
            type: "bytes32",
          },
          {
            internalType: "address",
            name: "maker",
            type: "address",
          },
          {
            internalType: "address",
            name: "makerToken",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "makerVolume",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "makerTimestamp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "cancelTimestamp",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "taker",
            type: "address",
          },
          {
            internalType: "address",
            name: "takerToken",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "takerVolume",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "takerTimestamp",
            type: "uint256",
          },
        ],
        internalType: "struct ManagerType.OTCPurchaseTicket",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "bytes32",
            name: "ticketId",
            type: "bytes32",
          },
          {
            internalType: "address",
            name: "who",
            type: "address",
          },
          {
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "deadline",
            type: "uint256",
          },
          {
            internalType: "bytes32",
            name: "category",
            type: "bytes32",
          },
        ],
        internalType: "struct ManagerType.WithdrawRequestV2",
        name: "param",
        type: "tuple",
      },
    ],
    name: "getWithdrawRequestV2DataHash",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "bytes32",
            name: "ticketId",
            type: "bytes32",
          },
          {
            internalType: "address",
            name: "who",
            type: "address",
          },
          {
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "deadline",
            type: "uint256",
          },
          {
            internalType: "bytes32",
            name: "category",
            type: "bytes32",
          },
        ],
        internalType: "struct ManagerType.WithdrawRequestV2",
        name: "param",
        type: "tuple",
      },
    ],
    name: "getWithdrawRequestV2Hash",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getWithdrawRequestV2TypeHash",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "who",
        type: "address",
      },
    ],
    name: "listMakerUsdtForPpe",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "ticketId",
            type: "uint256",
          },
          {
            internalType: "bytes32",
            name: "status",
            type: "bytes32",
          },
          {
            internalType: "address",
            name: "maker",
            type: "address",
          },
          {
            internalType: "address",
            name: "makerToken",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "makerVolume",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "makerTimestamp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "cancelTimestamp",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "taker",
            type: "address",
          },
          {
            internalType: "address",
            name: "takerToken",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "takerVolume",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "takerTimestamp",
            type: "uint256",
          },
        ],
        internalType: "struct ManagerType.OTCPurchaseTicket[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "who",
        type: "address",
      },
    ],
    name: "listTakerUsdtForPpe",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "ticketId",
            type: "uint256",
          },
          {
            internalType: "bytes32",
            name: "status",
            type: "bytes32",
          },
          {
            internalType: "address",
            name: "maker",
            type: "address",
          },
          {
            internalType: "address",
            name: "makerToken",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "makerVolume",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "makerTimestamp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "cancelTimestamp",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "taker",
            type: "address",
          },
          {
            internalType: "address",
            name: "takerToken",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "takerVolume",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "takerTimestamp",
            type: "uint256",
          },
        ],
        internalType: "struct ManagerType.OTCPurchaseTicket[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "makerVolume",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "takerVolume",
        type: "uint256",
      },
    ],
    name: "makeUsdtForPpe",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "orderBookUsdtForPpe",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "ticketId",
            type: "uint256",
          },
          {
            internalType: "bytes32",
            name: "status",
            type: "bytes32",
          },
          {
            internalType: "address",
            name: "maker",
            type: "address",
          },
          {
            internalType: "address",
            name: "makerToken",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "makerVolume",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "makerTimestamp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "cancelTimestamp",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "taker",
            type: "address",
          },
          {
            internalType: "address",
            name: "takerToken",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "takerVolume",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "takerTimestamp",
            type: "uint256",
          },
        ],
        internalType: "struct ManagerType.OTCPurchaseTicket[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newAssociatedOperator",
        type: "address",
      },
      {
        internalType: "bool",
        name: "flag",
        type: "bool",
      },
    ],
    name: "setAssociatedOperator",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "otcTicketId",
        type: "uint256",
      },
    ],
    name: "take",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32[]",
        name: "ticketIds",
        type: "bytes32[]",
      },
    ],
    name: "ticketStatus",
    outputs: [
      {
        internalType: "bool[]",
        name: "",
        type: "bool[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "bytes32",
            name: "ticketId",
            type: "bytes32",
          },
          {
            internalType: "address",
            name: "who",
            type: "address",
          },
          {
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "deadline",
            type: "uint256",
          },
          {
            internalType: "bytes32",
            name: "category",
            type: "bytes32",
          },
        ],
        internalType: "struct ManagerType.WithdrawRequestV2",
        name: "param",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
        ],
        internalType: "struct ManagerType.Signature",
        name: "signature",
        type: "tuple",
      },
    ],
    name: "verifyWithdrawRequestV2",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "bytes32",
            name: "ticketId",
            type: "bytes32",
          },
          {
            internalType: "address",
            name: "who",
            type: "address",
          },
          {
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "deadline",
            type: "uint256",
          },
          {
            internalType: "bytes32",
            name: "category",
            type: "bytes32",
          },
        ],
        internalType: "struct ManagerType.WithdrawRequestV2",
        name: "param",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
        ],
        internalType: "struct ManagerType.Signature",
        name: "signature",
        type: "tuple",
      },
    ],
    name: "withdrawV2",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class ManagerInterface__factory {
  static readonly abi = _abi;
  static createInterface(): ManagerInterfaceInterface {
    return new utils.Interface(_abi) as ManagerInterfaceInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ManagerInterface {
    return new Contract(address, _abi, signerOrProvider) as ManagerInterface;
  }
}
