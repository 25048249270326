import React from 'react';
import { Box, Button, Input, InputGroup, InputLeftElement, Spinner, useToast } from '@chakra-ui/react';
import { useTheme } from '../../hooks/useTheme';
import MainContainer from '../../components/UI/MainContainer/MainContainer';
import ChartIcon from '../../assets/image/group/chart.svg';
import { AiOutlineLeft, AiOutlinePlusSquare, AiOutlineMinusSquare, AiOutlineSearch } from 'react-icons/ai';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makeErrorToast, truncAddress, truncateNumber } from '../../utils/utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ethers } from 'ethers';
import { useWallet } from '../../store/wallet-context';
import { useQuery } from '@tanstack/react-query';
import { requestGroupInfo } from '../../utils/api';
import { GroupInfoNode, compareAddress, findSubTreeById } from '../../utils/groupUtils';
import { Tree } from '@jlguenego/tree';
import { useWindowScroll } from '../../hooks/useWindowScroll';

export default function Group() {
  // @ts-ignore
  const { t } = useTranslation();
  const toast = useToast();
  const { isConnected, accountAddress } = useWallet();
  const windowScroll = useWindowScroll();
  // const accountAddress = '0xF5031502ffeA0D679d20062710B2F55e32a4FA69';
  const [isTreeLoading, setIsTreeLoading] = React.useState<boolean>(false);
  const [inputSearch, setInputSearch] = React.useState<string>('');
  const [expandedAddresses, setExpandedAddresses] = React.useState<string[]>([]);
  const [selectedNode, setSelectedNode] = React.useState<GroupInfoNode>(null);
  const [groupTree, setGroupTree] = React.useState<Tree<GroupInfoNode> | null>(null);
  const [cachedAddresses, setCachedAddresses] = React.useState<string[]>([]);
  const handleToggleExpandAddress = (address: string) => {
    if (expandedAddresses.includes(address)) {
      setExpandedAddresses(expandedAddresses.filter((item) => item !== address));
    } else {
      setExpandedAddresses([...expandedAddresses, address]);
    }
  };

  const groupInfoQuery = useQuery({
    queryKey: ['group', accountAddress],
    queryFn: async () => {
      const tree = await requestGroupInfo(accountAddress);
      setGroupTree(tree);
      return tree;
    },
    enabled: isConnected && !!accountAddress
  });

  const handleSearch = async () => {
    if (!inputSearch) {
      groupInfoQuery.refetch();
      return;
    }
    if (inputSearch.length !== 42) {
      makeErrorToast(t('Invalid address', 'Invalid address'), toast);
      return;
    }
    const clickedNodeInfo = await requestGroupInfo(inputSearch);
    setGroupTree(clickedNodeInfo);
    setSelectedNode(clickedNodeInfo.node);
    setExpandedAddresses([inputSearch]);
  };

  const handleReferalClicked = async (item: GroupInfoNode) => {
    // request tree info if not cached
    setSelectedNode(item);
    const isCached = cachedAddresses.findIndex((address) => compareAddress(address, item.user.walletAddress)) !== -1;
    if (!isCached) {
      setIsTreeLoading(true);
      const subTree = findSubTreeById(groupTree, item.user.walletAddress);
      const clickedNodeInfo = await requestGroupInfo(item.user.walletAddress);
      subTree.children = clickedNodeInfo.children;
      setCachedAddresses([...cachedAddresses, item.user.walletAddress]);
      setIsTreeLoading(false);
    }
    handleToggleExpandAddress(item.user.walletAddress);
  };

  const statitsticsData = [
    {
      label: t('Address', 'Address'),
      value: groupTree ? truncAddress(groupTree.node.user.walletAddress) : '-'
    },
    {
      label: t('Spent', 'Spent'),
      value: (
        <>
          USDT:
          <span className={styles.accentValue}>{groupTree ? truncateNumber(groupTree?.node.self.cost18, 2) : 0}</span>
        </>
      )
    },
    // {
    //   label: t('Hierarchy', 'Hierarchy'),
    //   value: groupTree?.node.hierarchy
    // },
    {
      label: t('Org structure', 'Org structure'),
      value: groupTree ? groupTree.node.orgStructure : 0
    },
    {
      label: t('Performance(self-excluded)', 'Performance(self-excluded)'),
      value: (
        <>
          USDT:
          <span className={styles.accentValue}>{groupTree ? truncateNumber(groupTree.node.group.cost18, 2) : 0}</span>
        </>
      )
    }
  ];

  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const signature = queryParameters.get('signature');
  if (!signature) {
    return null;
  }
  try {
    const signerAddress = ethers.utils.verifyMessage('group details', signature);
    if (signerAddress !== accountAddress) {
      return null;
    }
  } catch (error) {
    return null;
  }

  return (
    <Box className={useTheme(styles.container)}>
      <MainContainer className={styles.mainContainer}>
        <Box className={styles.contentContainer}>
          <Box className={styles.goBack} onClick={() => navigate('/profile')}>
            <AiOutlineLeft /> {t('Profile', 'Profile')}
          </Box>
          <Box className={styles.title}>{t('Group View', 'Group View')}</Box>
          <Box className={styles.card} mb={8}>
            <Box className={styles.cardTitle}>
              <img src={ChartIcon} alt="chart" />
              {t('Statistics', 'Statistics')}
            </Box>
            <Box className={styles.cardContent}>
              {statitsticsData.map((item, index) => (
                <Box key={item.label} className={classNames(styles.cardItem, index === 4 && styles.performanceItem)}>
                  <Box className={styles.cardItemTitle}>{item.label}</Box>
                  <Box className={styles.cardItemValue}>{item.value}</Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box className={styles.referralContainer}>
            <Box className={styles.searchContainer}>
              <Box className={styles.searchInput}>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <AiOutlineSearch color="gray.300" />
                  </InputLeftElement>
                  <Input
                    placeholder={t('search address', 'search address')}
                    value={inputSearch}
                    onChange={(e) => setInputSearch(e.target.value)}
                  />
                </InputGroup>
                <Button onClick={handleSearch}>{t('searh', 'search')}</Button>
              </Box>
            </Box>

            <Box className={styles.referralTreeContainer}>
              <ReferralInfo
                referralData={groupTree?.children}
                expandedAddresses={expandedAddresses}
                toggleAddress={handleReferalClicked}
                selectedNode={selectedNode}
                isTreeLoading={isTreeLoading}
              />
            </Box>

            <Box className={styles.referralDetails}>
              <Box className={classNames(styles.referralLabelContainer, windowScroll > 500 && styles.sticky)}>
                {selectedNode && (
                  <>
                    <Box flexGrow={2} width="100%" fontWeight={500} fontSize="1rem">
                      {selectedNode?.user.walletAddress}
                    </Box>
                    {/* <ReferralLabel label={t('Hierarchy', 'Hierarchy')} value={selectedNode.hierarchy} /> */}
                    <ReferralLabel label={t('Org structure', 'Org structure')} value={selectedNode.orgStructure} />
                    <ReferralLabel
                      label={t('Performance USDT', 'Performance USDT')}
                      value={truncateNumber(selectedNode.group.cost18, 2)}
                    />
                    <ReferralLabel
                      label={t('Cost USDT', 'Cost USDT')}
                      value={truncateNumber(selectedNode.self.cost18, 2)}
                    />
                    <ReferralLabel label={t('Self Nodes', 'Self Nodes')} value={selectedNode.self.nodeAmount} />
                    <ReferralLabel label={t('Group Nodes', 'Group Nodes')} value={selectedNode.group.nodeAmount} />
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </MainContainer>
    </Box>
  );
}

function ReferralInfo(props: {
  referralData: Tree<GroupInfoNode>[] | undefined;
  expandedAddresses: string[];
  toggleAddress: (address: GroupInfoNode) => void;
  selectedNode: GroupInfoNode | null;
  isTreeLoading: boolean;
}) {
  // @ts-ignore
  const { t } = useTranslation();
  return (
    <Box className={classNames(styles.card, styles.referrals)}>
      {props.referralData &&
        props.referralData.map((treeObject, index) => {
          const item = treeObject.node;
          const isExpanded = props.expandedAddresses.includes(item.user.walletAddress);
          const isLoading =
            props.isTreeLoading && compareAddress(item.user.walletAddress, props.selectedNode?.user.walletAddress);
          return (
            <Box key={item.user.walletAddress}>
              <Box className={styles.referralWrapper}>
                <Box className={styles.referralTitle} onClick={() => props.toggleAddress(item)}>
                  {isLoading && <Spinner size="sm" />}
                  {!isLoading && (isExpanded ? <AiOutlineMinusSquare size={32} /> : <AiOutlinePlusSquare size={32} />)}
                  <Box
                    className={classNames(
                      styles.referralAddress,
                      props.selectedNode?.user.walletAddress.toLowerCase() === item.user.walletAddress.toLowerCase() &&
                        styles.highLighted
                    )}>
                    {truncAddress(item.user.walletAddress)}
                  </Box>
                </Box>
                {isExpanded && (
                  <>
                    {/* <Box className={styles.referralLabelContainer}>
                      <ReferralLabel label={t('Hierarchy', 'Hierarchy')} value={item.hierarchy} />
                      <ReferralLabel label={t('Org structure', 'Org structure')} value={item.orgStructure} />
                      <ReferralLabel label={t('Performance USDT', 'Performance USDT')} value={truncateNumber(item.group.cost18,2)} />
                      <ReferralLabel label={t('Cost USDT', 'Cost USDT')} value={truncateNumber(item.self.cost18, 2)} />
                      <ReferralLabel label={t('Self Nodes', 'Self Nodes')} value={item.self.nodeAmount} />
                      <ReferralLabel label={t('Group Nodes', 'Group Nodes')} value={item.group.nodeAmount} />
                    </Box> */}
                    {treeObject.children.length > 0 && (
                      <ReferralInfo
                        toggleAddress={props.toggleAddress}
                        referralData={treeObject.children}
                        expandedAddresses={props.expandedAddresses}
                        selectedNode={props.selectedNode}
                        isTreeLoading={props.isTreeLoading}
                      />
                    )}
                  </>
                )}
              </Box>
            </Box>
          );
        })}
    </Box>
  );
}

function ReferralLabel(prop: { label: string; value: string | number }) {
  return (
    <Box className={styles.referralLabel}>
      <Box className={styles.referralLabelName}>{prop.label}:</Box>
      <Box className={styles.referralLabelValue}>{prop.value}</Box>
    </Box>
  );
}
