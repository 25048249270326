// import React useEffect and useState
import { useEffect, useState } from 'react';

// create a custom hook called useWindowScroll
export const useWindowScroll = () => {
  // create a state variable to store the current scroll position
  const [scrollPosition, setScrollPosition] = useState(0);

  // use useEffect to listen for scroll events and update the scroll position
  useEffect(() => {
    // add an event listener for the scroll event
    window.addEventListener('scroll', () => {
      // set the scroll position to the current window scroll position
      setScrollPosition(window.scrollY);
    });

    // return a cleanup function to remove the event listener when the component unmounts
    return () => window.removeEventListener('scroll', () => {});
  }, []);

  // return the scroll position
  return scrollPosition;
};
