import { tokenAxios } from '../index';
import { PurchaseNodeInfo, performanceResult, purchaseResult } from '../../utils/types';

const miniNodeBusinessName = process.env.REACT_APP_MINI_NODE_BUSINESS_NAME;

export const requestPurchaseNodeMini = async () => {
  const result = await tokenAxios.get<PurchaseNodeInfo[]>(`/purchaseNode?businessName=${miniNodeBusinessName}`);
  return result.data;
};

export const requestPurchaseBusinessMini = async () => {
  const result = await tokenAxios.post(`/purchaseNode/getPurchaseNodeBusiness?businessName=${miniNodeBusinessName}`);
  return result.data;
  // return {
  //   id: 1,
  //   businessName: 'PurchasePPENode',
  //   roundNumber: 1,
  //   costTokenId: '2',
  //   startPrice: '1000000000000000000000',
  //   nextPrice: '1050000000000000000000',
  //   givenNftId: '1',
  //   capacityNodeAmount: 2100,
  //   totalPurchasedNodeAmount: 270,
  //   totalTakenNodeAmount: 492,
  //   currentPurchasedNodeAmount: 270,
  //   currentTakenNodeAmount: 492,
  //   isActive: true
  // };
};

export const requestPerformanceMini = async () => {
  const result = await tokenAxios.get<performanceResult>(`/purchaseNode/${miniNodeBusinessName}/performance`);
  return result.data;
};

export const requestPurchaseMini = async (payload: { purchaseNodeAmount: number }) => {
  const result = await tokenAxios.post<purchaseResult>('/purchaseNode/purchase', {
    businessName: miniNodeBusinessName,
    purchaseNodeAmount: payload.purchaseNodeAmount
  });
  return result.data;
};
