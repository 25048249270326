/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  GenericNameServiceInterface,
  GenericNameServiceInterfaceInterface,
} from "../../../../contracts/dependant/nameService/GenericNameServiceInterface";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "keyName",
        type: "bytes32",
      },
    ],
    name: "getSingle",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "keyName",
        type: "bytes32",
      },
    ],
    name: "getSingleSafe",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "which",
        type: "address",
      },
    ],
    name: "isBlocked",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "keyName",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "which",
        type: "address",
      },
    ],
    name: "isMultiple",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "keyName",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "which",
        type: "address",
      },
    ],
    name: "isMultipleSafe",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "which",
        type: "address",
      },
    ],
    name: "isPrivileged",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "addr",
        type: "address",
      },
    ],
    name: "isTrusted",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "listMultipleEntries",
    outputs: [
      {
        components: [
          {
            internalType: "bytes32",
            name: "name",
            type: "bytes32",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bool",
                name: "trusted",
                type: "bool",
              },
            ],
            internalType: "struct GenericNameServiceType.AddressRecord[]",
            name: "records",
            type: "tuple[]",
          },
        ],
        internalType: "struct GenericNameServiceType.MultipleEntryRet[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "listSeparatedTrustEntries",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "addr",
            type: "address",
          },
          {
            internalType: "bool",
            name: "trusted",
            type: "bool",
          },
        ],
        internalType: "struct GenericNameServiceType.AddressRecord[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "listSingleEntries",
    outputs: [
      {
        components: [
          {
            internalType: "bytes32",
            name: "name",
            type: "bytes32",
          },
          {
            components: [
              {
                internalType: "address",
                name: "addr",
                type: "address",
              },
              {
                internalType: "bool",
                name: "trusted",
                type: "bool",
              },
            ],
            internalType: "struct GenericNameServiceType.AddressRecord",
            name: "record",
            type: "tuple",
          },
        ],
        internalType: "struct GenericNameServiceType.SingleEntryRet[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newAssociatedOperator",
        type: "address",
      },
      {
        internalType: "bool",
        name: "flag",
        type: "bool",
      },
    ],
    name: "setAssociatedOperator",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "list",
        type: "address[]",
      },
      {
        internalType: "bool",
        name: "flag",
        type: "bool",
      },
    ],
    name: "setBlockList",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "keyName",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "which",
        type: "address",
      },
      {
        internalType: "bool",
        name: "enable",
        type: "bool",
      },
      {
        internalType: "bool",
        name: "trusted",
        type: "bool",
      },
    ],
    name: "setMultiple",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32[]",
        name: "keyName",
        type: "bytes32[]",
      },
      {
        internalType: "address[]",
        name: "which",
        type: "address[]",
      },
      {
        internalType: "bool[]",
        name: "enable",
        type: "bool[]",
      },
      {
        internalType: "bool[]",
        name: "trusted",
        type: "bool[]",
      },
    ],
    name: "setMultipleEntries",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "list",
        type: "address[]",
      },
      {
        internalType: "bool",
        name: "flag",
        type: "bool",
      },
    ],
    name: "setPrivilegedList",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "which",
        type: "address",
      },
      {
        internalType: "bool",
        name: "enable",
        type: "bool",
      },
      {
        internalType: "bool",
        name: "trusted",
        type: "bool",
      },
    ],
    name: "setSeparatedTrustAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "which",
        type: "address[]",
      },
      {
        internalType: "bool[]",
        name: "enable",
        type: "bool[]",
      },
      {
        internalType: "bool[]",
        name: "trusted",
        type: "bool[]",
      },
    ],
    name: "setSeparatedTrustEntries",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "keyName",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "which",
        type: "address",
      },
      {
        internalType: "bool",
        name: "enable",
        type: "bool",
      },
      {
        internalType: "bool",
        name: "trusted",
        type: "bool",
      },
    ],
    name: "setSingle",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32[]",
        name: "keyName",
        type: "bytes32[]",
      },
      {
        internalType: "address[]",
        name: "which",
        type: "address[]",
      },
      {
        internalType: "bool[]",
        name: "enable",
        type: "bool[]",
      },
      {
        internalType: "bool[]",
        name: "trusted",
        type: "bool[]",
      },
    ],
    name: "setSingleEntries",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class GenericNameServiceInterface__factory {
  static readonly abi = _abi;
  static createInterface(): GenericNameServiceInterfaceInterface {
    return new utils.Interface(_abi) as GenericNameServiceInterfaceInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): GenericNameServiceInterface {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as GenericNameServiceInterface;
  }
}
