import { Box, Flex } from '@chakra-ui/react';
import styles from './index.module.scss';
import { useTheme } from '../../hooks/useTheme';
import MainContainer from '../UI/MainContainer/MainContainer';
import { Carousel } from './Carousel';
import { useTranslation } from 'react-i18next';
import { TextCarousel } from './TextCarousel';

export function Whyus() {
  const { t } = useTranslation();

  const whyUsIntro = [
    {
      imgSrc: require('../../assets/image/whyus/DEX.png'),
      title: 'DEX',
      intro: t('Seamless and Secure Trading', 'Seamless and Secure Trading'),
      desc: t(
        'ProsperEX offers both spot and perpetual future trading for different assets. Users enjoy a streamlined experience while ensuring top-tier security and privacy.',
        'ProsperEX offers both spot and perpetual future trading for different assets. Users enjoy a streamlined experience while ensuring top-tier security and privacy.'
      )
    },
    {
      imgSrc: require('../../assets/image/whyus/RWA.png'),
      title: 'RWA',
      intro: t('Beyond Cryptocurrency', 'Beyond Cryptocurrency'),
      desc: t(
        'Trading extends beyond just cryptocurrency. Users have the unique advantage of accessing a diverse market, including stocks, Forex, gold, and other key commodities, all on a single platform.',
        'Trading extends beyond just cryptocurrency. Users have the unique advantage of accessing a diverse market, including stocks, Forex, gold, and other key commodities, all on a single platform.'
      )
    },
    {
      imgSrc: require('../../assets/image/whyus/AI.png'),
      title: 'AI',
      intro: t('Smart, Responsive, Predictive', 'Smart, Responsive, Predictive'),
      desc: t(
        // eslint-disable-next-line quotes
        "Our innovated AI system intelligently assists users with queries, automates trades, offers in-depth market data, and even forecasts future prices, ensuring you're always a step ahead in the trading game.",
        // eslint-disable-next-line quotes
        "Our innovated AI system intelligently assists users with queries, automates trades, offers in-depth market data, and even forecasts future prices, ensuring you're always a step ahead in the trading game."
      )
    }
  ];
  return (
    <>
      <Box className={useTheme(styles.container)}>
        <MainContainer className={styles.contentContainer}>
          <Flex className={styles.mainFlex}>
            <Box className={styles.title}>{t('Why Choose ProsperEx?', 'Why Choose ProsperEx?')}</Box>
            <Box className={styles.description}>
              {t('Your Gateway to Safe and Efficient Trading', 'Your Gateway to Safe and Efficient Trading')}
            </Box>

            {/* {primaryItems.map((item) => (
              <PrimaryItem {...item} key={item.title} />
            ))} */}
            <video width="80%" height="100%" autoPlay muted loop playsInline>
              <source src={require('../../assets/image/whyus/media.webm')} type="video/webm"></source>
              <source src={require('../../assets/image/whyus/media.mp4')} type="video/mp4"></source>
              {t('Your browser does not support the video tag.', 'Your browser does not support the video tag.')}
            </video>
            {/* <Flex className={styles.sencondaryItemsContainer}>
              {secondaryItems.map((item) => (
                <SecondaryItem {...item} key={item.title} />
              ))}
            </Flex> */}
            <div className={styles.whyUsIntroWrapper}>
              <div className={styles.PhoneFrame}>
                <div className={styles.triangle}>
                  <div className={styles.content}>
                    <div className={styles.actuallyFrame}>
                      <Carousel items={whyUsIntro} />
                    </div>
                  </div>
                </div>
              </div>
              <TextCarousel items={whyUsIntro} />
            </div>
          </Flex>
        </MainContainer>
      </Box>
    </>
  );
}
