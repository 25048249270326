import React, { useEffect, useMemo, useState } from 'react';
import { Flex, Box, Divider, Input, InputGroup, InputLeftAddon, InputRightAddon, useToast } from '@chakra-ui/react';
import styles from './Sale.module.scss';
import classNames from 'classnames';
import { CommonButton } from '../UI/CommonButton';
import { useTheme } from '../../hooks/useTheme';
import { TermsAndConditions } from './TermsAndConditions';
import { useLocalStorage } from 'usehooks-ts';
import { useQuery } from '@tanstack/react-query';
import { isNaN } from 'lodash';
import { useWallet } from '../../store/wallet-context';
import { useNavigate } from 'react-router-dom';
import { useBalance, useSignMessage } from 'wagmi';
import { formatEther } from 'ethers/lib/utils.js';
import { padZero, truncateNumber } from '../../utils/utils';
import { Countdown, useTimer } from '../../hooks/useTimer';
import { useTranslation } from 'react-i18next';
import { requestApiGate } from '../../service/common';
// import { requestPurchaseMini, requestPurchaseBusinessMini } from '../../service/purchaseMiniNode';
import { requestPurchase, requestPurchaseBusiness } from '../../service/purchaseNode';
import { requestRegistered } from '../../service/account';
import { Deposit } from './Deposit';
import { requestBalance } from '../../service/balance';
import { CommonModalNew } from '../UI/CommonModalNew';

export function Sale({ countdown }: { countdown: Countdown }) {
  const { t } = useTranslation();
  const toast = useToast();
  const usdtAddress = process.env.REACT_APP_USDT_ADDRESS! as `${'0x'}${string}`;
  const [isOpen, setIsOpen] = React.useState(false);
  const [depositOpen, setDepositOpen] = React.useState(false);
  const { signMessageAsync } = useSignMessage();
  const navigate = useNavigate();
  const [inputAmount, setInputAmount] = React.useState('');
  const { accountAddress, isConnected } = useWallet();
  const { data: usdtBalance, isSuccess: usdtBalanceQuerySuccess } = useBalance({
    address: accountAddress,
    token: usdtAddress,
    enabled: isConnected
  });
  const [agreeed] = useLocalStorage(`__PROSPER_EX__agreedNodeSaleTermsAndConditions_${accountAddress}`, false);
  const [loggedIn, setLoggedIn] = useLocalStorage('__PROSPER_EX__loggedIn', false);
  const [loggedInTimestamp, setLoggedInTimestamp] = useLocalStorage('__PROSPER_EX__sign_timestamp', 0);
  const [loading, setLoading] = useState(false);

  const phase3CountDown = useTimer({
    deadline: new Date('2024-02-20T14:00:00+08:00')
  });

  useEffect(() => {
    if (phase3CountDown.timeIsDue) {
      refetchPurchaseBusiness();
    }
  }, [phase3CountDown.timeIsDue]);

  const { isSuccess: registerQuerySuccess, data: registerQueryData } = useQuery({
    queryKey: ['authQuery', accountAddress],
    queryFn: () => {
      return requestRegistered(accountAddress!);
    },
    enabled: isConnected
  });

  if (!loggedIn) {
    navigate('/login');
  }

  useEffect(() => {
    if (!isConnected) {
      navigate('/login');
    }
  }, [isConnected]);

  if (registerQueryData === false) {
    navigate('/register');
  }
  useEffect(() => {
    // 每过一段时间要重新验证
    if (Date.now() - loggedInTimestamp - 10 * 60 * 1000 > 0 && countdown.timeIsDue) {
      navigate('/login');
    }
  }, []);

  const { isSuccess: userQuerySuccess, data: userQueryData } = useQuery({
    queryKey: ['apiGate'],
    queryFn: () => {
      return requestApiGate();
    },
    enabled: isConnected && registerQuerySuccess && registerQueryData
  });

  const { data: balanceQueryData, refetch: refetchBalance } = useQuery({
    queryKey: ['balance'],
    queryFn: () => {
      return requestBalance([userQueryData.usdt.tokenId]);
    },
    enabled: isConnected && registerQuerySuccess && registerQueryData && userQuerySuccess
  });

  const balance = useMemo(() => {
    return balanceQueryData?.balances[0]?.balance
      ? truncateNumber(formatEther(balanceQueryData?.balances[0]?.balance), 2)
      : 0;
  }, [balanceQueryData?.balances[0]?.balance]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetchPurchaseBusiness();
      refetchBalance();
    }, 5 * 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const buyAddress = userQuerySuccess ? userQueryData.depositAddress : '';

  // const {
  //   isSuccess: nodeQuerySuccess,
  //   data: nodeQueryData,
  //   refetch: refetchPurchaseBusinessMini
  // } = useQuery({
  //   queryKey: ['nodeInfo'],
  //   queryFn: () => {
  //     return requestPurchaseBusinessMini();
  //   }
  // });
  const {
    isSuccess: nodeQuerySuccess,
    data: nodeQueryData,
    refetch: refetchPurchaseBusiness
  } = useQuery({
    queryKey: ['nodeInfo'],
    queryFn: () => {
      return requestPurchaseBusiness();
    }
  });
  const currentValue = Number(inputAmount);
  const currrentAmount = isNaN(currentValue) ? 0 : currentValue;
  const currentPhase = nodeQueryData?.roundNumber + 1 ?? 1;

  let restAmount = nodeQuerySuccess ? nodeQueryData.capacityNodeAmount - nodeQueryData.currentTakenNodeAmount : 0;
  const isActive = nodeQuerySuccess ? nodeQueryData.isActive : false;
  const totalAmount = nodeQuerySuccess ? nodeQueryData.capacityNodeAmount : 0;

  const price = nodeQuerySuccess && nodeQueryData?.nextPrice ? formatEther(nodeQueryData.nextPrice) : 0;
  const cost = +price * currrentAmount;

  const handleIncrease = () => {
    setInputAmount(String(currrentAmount + 1));
  };
  const handleDecrease = () => {
    if (currrentAmount > 0) {
      setInputAmount(String(currrentAmount - 1));
    }
  };
  const handleInputAmountChange = (e: any) => {
    const value = e.target.value;
    setInputAmount(value);
  };

  const handleBuyNode = async () => {
    if (!usdtBalanceQuerySuccess) {
      console.error('usdtBalanceQuery failed');
      return;
    }
    if (+inputAmount <= 0 || !Number.isInteger(+inputAmount)) {
      toast({
        title: t('Please input amount', 'Please input amount'),
        status: 'error',
        duration: 3000,
        isClosable: true
      });
      return;
    }
    if (+balance < +truncateNumber(cost, 2)) {
      toast({
        title: t('Insufficient Account balance', 'Insufficient Account balance'),
        status: 'error',
        duration: 3000,
        isClosable: true
      });
      return;
    }
    setLoading(true);
    try {
      await requestPurchase({ purchaseNodeAmount: +inputAmount });
      setLoading(false);
      toast({
        title: t('Purchase successfully', 'Purchase successfully'),
        status: 'success',
        duration: 5000,
        isClosable: true
      });
      await refetchBalance();
      await refetchPurchaseBusiness();
    } catch (err) {
      setLoading(false);
      toast({
        title: err.message,
        status: 'error',
        duration: 5000,
        isClosable: true
      });
      console.error(err);
    }
  };

  const soldOut = restAmount === 0 || !isActive;
  const inputTooMany = Number(inputAmount) > restAmount;

  //便于查看销售数据
  const showSaleDetailAddrList = ['0xdE00fa656925b5e4ad49499aC4d4e4D4c61ae56b'];
  const showSaleDetail = isConnected && showSaleDetailAddrList.includes((accountAddress || '').toString());
  return (
    <>
      <Flex className={useTheme(styles.container)}>
        <Box className={styles.sale}>
          <Flex className={styles.title}>
            {showSaleDetail ? (
              soldOut ? (
                <Flex fontWeight={700}>{t('Sold out', 'Sold out')}</Flex>
              ) : (
                <Flex className={styles.amountWrapper}>
                  {t('Amount', 'Amount')}:{' '}
                  <Box as="span" className={styles.amount}>
                    {restAmount}
                  </Box>
                  <Box as="span" fontWeight={700}>
                    /{totalAmount}
                  </Box>
                </Flex>
              )
            ) : null}

            <Flex className={styles.priceWrapper}>
              <Box>{t('Price', 'Price')}</Box>:
              <Box as="span" className={styles.price}>
                {price} USDT
              </Box>
              {/* <Tooltip label={t('Total supply is 10000', 'Total supply is 10000')}>
                <img src={require('../../assets/image/node/question.svg').default} alt="" />
              </Tooltip> */}
            </Flex>
          </Flex>
          <Divider borderColor="rgba(57, 77, 238, 0.2)" borderBottomWidth="1px" />

          <InputGroup className={styles.inputGroup}>
            <InputLeftAddon children="-" className={styles.inputAddon} onClick={handleDecrease} />
            <Input
              type="number"
              placeholder={t('input amount', 'input amount')}
              textAlign="center"
              fontWeight={700}
              value={inputAmount}
              onChange={handleInputAmountChange}
            />
            border-radius: 16px 0 0 16px;
            <InputRightAddon
              children="+"
              className={styles.inputAddon}
              onClick={handleIncrease}
              style={{ borderRadius: '0 16px 16px 0' }}
            />
          </InputGroup>

          <Flex className={styles.cost}>
            {t('Cost', 'Cost')}: {truncateNumber(cost, 2)} USDT
          </Flex>

          {agreeed ? (
            <Flex
              className={classNames(styles.termsAndConditions, styles.agreed)}
              alignItems="center"
              gap={2}
              justifyContent="center">
              <img src={require('../../assets/image/earlybird/tick.png')} alt="" width={'30px'} />
              <Flex wrap="wrap" className={styles.agreeText} columnGap={1}>
                {t('You have agreeed to', 'You have agreeed to')}
                <span className={styles.link} onClick={() => setIsOpen(true)}>
                  {t('Terms and Conditions', 'Terms and Conditions')}
                </span>
              </Flex>
            </Flex>
          ) : (
            <Flex className={styles.newTermsAndConditions} justifyContent="center" gap={1}>
              <Box>{t('Before joining you need to agree to', 'Before joining you need to agree to')}</Box>
              <Box as="span" onClick={() => setIsOpen(true)}>
                {t('Terms and Conditions', 'Terms and Conditions')}
              </Box>
            </Flex>
          )}

          {!countdown.timeIsDue && (
            <>
              <CommonButton
                handleClick={undefined}
                // name={`${t('Coming soon', 'Coming soon')}...`}
                name={t('begin countdown', 'Begins in {{day}}D:{{hour}}H:{{minutes}}M:{{second}}S', {
                  day: countdown.days,
                  hour: padZero(countdown.hours),
                  minutes: padZero(countdown.minutes),
                  second: padZero(countdown.seconds)
                })}
                thick
                className={styles.buyButton}
                disabled={true}
              />
            </>
          )}

          {agreeed && countdown.timeIsDue && (
            <CommonButton
              handleClick={handleBuyNode}
              name={t('Buy now', 'Buy now')}
              thick
              loading={loading}
              className={styles.buyButton}
              disabled={soldOut || inputTooMany}
            />
          )}

          <Flex className={styles.balance} alignItems="center">
            {t('Account Balance', 'Account Balance')}: {balance} USDT
            <Box as="span" className={styles.deposit} onClick={() => setDepositOpen(true)}>
              {t('Deposit', 'Deposit')}
            </Box>
          </Flex>
        </Box>
      </Flex>
      <CommonModalNew width="321px" isOpen={isOpen} setIsOpen={setIsOpen}>
        <TermsAndConditions onAgree={() => setIsOpen(false)} />
      </CommonModalNew>
      <CommonModalNew isOpen={depositOpen} setIsOpen={setDepositOpen}>
        <Deposit buyAddress={buyAddress} />
      </CommonModalNew>
    </>
  );
}
