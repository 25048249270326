import styles from './HitoryTable.module.scss';
import { Box, Flex, useMediaQuery, useToast } from '@chakra-ui/react';
import { useTheme } from '../../hooks/useTheme';
import { truncateNumber } from '../../utils/utils';
import { useTranslation } from 'react-i18next';
import { withdrawListResult } from '../../utils/types';
import format from 'date-fns/format';
import { formatEther } from 'ethers/lib/utils.js';

type Prop = {
  data: withdrawListResult[];
};

export default function WithdrawHistoryTable(prop: Prop) {
  //@ts-ignore
  const { t } = useTranslation();
  const toast = useToast();
  const isMobile = !!useMediaQuery('(max-width: 768px)')[0];

  const statusMap = new Map([
    [1, 'Processing'],
    [2, 'Failed'],
    [3, 'Successful']
  ]);

  const MobileItem = ({ item }: { item: withdrawListResult }) => (
    <Flex className={styles.mobileItemFlex}>
      <Flex className={styles.mobileItemAttr}>
        <Box className={styles.mobileItemAttrLabel}>{t('Time', 'Time')}:</Box>
        <Box className={styles.mobileItemAttrValue}>
          {format(new Date(item.createdAt), 'yyyy-MM-dd HH:mm:ss') ?? '-'}
        </Box>
      </Flex>
      <Flex className={styles.mobileItemAttr}>
        <Box className={styles.mobileItemAttrLabel}>{t('Amount', 'Amount')}:</Box>
        <Box className={styles.mobileItemAttrValue}>{truncateNumber(formatEther(item.amount), 2) ?? '-'}</Box>
      </Flex>
      <Flex className={styles.mobileItemAttr}>
        <Box className={styles.mobileItemAttrLabel}>{t('Token', 'Token')}:</Box>
        <Box className={styles.mobileItemAttrValue}>{item.token.name}</Box>
      </Flex>
      <Flex className={styles.mobileItemAttr}>
        <Box className={styles.mobileItemAttrLabel}>{t('Status', 'Status')}:</Box>
        <Box className={styles.mobileItemAttrValue}>
          {item.result && t(statusMap.get(item.result), statusMap.get(item.result))}
        </Box>
      </Flex>
    </Flex>
  );
  return (
    <Box className={useTheme(styles.container)}>
      {!isMobile && (
        <table className={styles.table}>
          <thead>
            <tr>
              <th>{t('Time', 'Time')}</th>
              <th>{t('Amount', 'Amount')}</th>
              <th style={{ width: '100px' }}>{t('Token', 'Token')}</th>
              <th>{t('Status', 'Status')}</th>
            </tr>
          </thead>
          <tbody>
            {prop.data.map((row) => (
              <tr key={row.id}>
                <td>{format(new Date(row.createdAt), 'yyyy-MM-dd HH:mm:ss')}</td>
                <td>{truncateNumber(formatEther(row.amount), 2) ?? '-'}</td>
                <td>{row.token.name}</td>
                <td>{row.result && t(statusMap.get(row.result), statusMap.get(row.result))}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {isMobile && (
        <Flex className={styles.mobileItemContainer}>
          {prop.data.map((row) => (
            <MobileItem item={row} key={row.id} />
          ))}
        </Flex>
      )}
    </Box>
  );
}
