import { Box, Flex } from '@chakra-ui/react';
import styles from './PriceCard.module.scss';
import classnames from 'classnames';

type Prop = {
  name: string;
  trend: 'up' | 'down';
  fluctuation: string | number;
  price: string | number;
  tradeLink: string;
};

export function PriceCard({ name, trend, fluctuation, price, tradeLink }: Prop) {
  const isPriceUp = trend === 'up';
  return (
    <>
      <a
        href={tradeLink.length > 0 ? tradeLink : undefined}
        style={{ cursor: 'pointer' }}
        rel="noreferrer noopener"
        target="_blank">
        <Flex className={styles.container}>
          <Flex className={styles.firstLine}>
            <Box className={styles.name}>{name}</Box>
            <Flex alignItems="center" gap="0.2rem" fontWeight={600}>
              <Box className={classnames(styles.priceTrendImg, isPriceUp ? styles.priceUpImg : styles.priceDownImg)} />
              <Box className={classnames(styles.fluctuation, isPriceUp ? styles.priceUp : styles.priceDown)}>
                {fluctuation}%
              </Box>
            </Flex>
          </Flex>
          <Box className={styles.price}>$ {price}</Box>
        </Flex>
      </a>
    </>
  );
}
