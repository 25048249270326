import React from 'react';

import styles from './index.module.scss';

type whyUsIntroType = {
  imgSrc: string;
  title: string;
  intro: string;
  desc: string;
};

const TextCard = ({ imgSrc, title, intro, desc }: whyUsIntroType) => {
  return (
    <div className={styles.dexDesc}>
      <h1>{title}</h1>
      <h6>{intro}</h6>
      <p>{desc}</p>
    </div>
  );
};

export default TextCard;
