import React from 'react';
import { EarlyBirdComponent } from '../components/EarlyBirdComponent';

export default function EarlyBird() {
  return (
    <div style={{ overflow: 'clip' }}>
      <div id="earlybird">
        <EarlyBirdComponent />
      </div>
    </div>
  );
}
