import { useEffect, useMemo } from 'react';
import { useWallet } from '../../store/wallet-context';
import { useQuery } from '@tanstack/react-query';
import { requestPurchaseStat } from '../../service/purchaseStat';
import { truncateNumber } from '../../utils/utils';
import { formatEther } from 'ethers/lib/utils.js';
import { BigNumber } from 'ethers';

export function useGetPerformance() {
  const { isConnected } = useWallet();

  const { data: purchaseStatData, refetch: refetchPurchaseStat } = useQuery({
    queryKey: ['useGetPurchaseStat'],
    queryFn: () => {
      return requestPurchaseStat(process.env.REACT_APP_BUSINESS_NAME);
    },
    enabled: isConnected
  });

  const { data: purchaseStatMiniData, refetch: refetchPurchaseStatMini } = useQuery({
    queryKey: ['useGetPurchaseStatMini'],
    queryFn: () => {
      return requestPurchaseStat(process.env.REACT_APP_MINI_NODE_BUSINESS_NAME);
    },
    enabled: isConnected
  });

  const directPerformance = useMemo(() => {
    const node = BigNumber.from(purchaseStatData?.inviteesPurchasedNodeCost || 0);
    const miniNode = BigNumber.from(purchaseStatMiniData?.inviteesPurchasedNodeCost || 0);
    return truncateNumber(formatEther(node.add(miniNode)), 2);
  }, [purchaseStatData?.inviteesPurchasedNodeCost, purchaseStatMiniData?.inviteesPurchasedNodeCost]);

  const groupPerformance = useMemo(() => {
    const node = BigNumber.from(purchaseStatData?.umbrellaPurchasedNodeCost || 0);
    const miniNode = BigNumber.from(purchaseStatMiniData?.umbrellaPurchasedNodeCost || 0);
    return truncateNumber(formatEther(node.add(miniNode)), 2);
  }, [purchaseStatData?.umbrellaPurchasedNodeCost, purchaseStatMiniData?.umbrellaPurchasedNodeCost]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetchPurchaseStat();
      refetchPurchaseStatMini();
    }, 30 * 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return {
    directPerformance,
    groupPerformance
  };
}
