import { bsc } from 'wagmi/chains';
import { tokenAxios } from '../index';
import { loginPayload } from '../../utils/types';

export const requestAuth = async (walletAddress: string) => {
  const result = await tokenAxios.post<{ messageToSign: string }>('/auth/prepareAuth', {
    walletAddress,
    chainId: String(bsc.id)
  });
  return result.data;
};

export const requestLogin = async (payload: loginPayload) => {
  const result = await tokenAxios.post<{
    token: string;
    accountId: string;
  }>('/auth/login', payload);
  return result.data;
};
