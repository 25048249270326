/* eslint-disable indent */
import { useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import {
  Box,
  Flex,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useMediaQuery,
  useToast,
  Tooltip
} from '@chakra-ui/react';
import avatar from '../../assets/image/profile/small-user.png';
import vipAvatar from '../../assets/image/profile/vip-avatar.png';
import vipBadge from '../../assets/image/profile/vip-badge.png';
import nodeIcon from '../../assets/image/profile/nodeIcon.svg';
import tokenIcon from '../../assets/image/profile/tokenIcon.svg';
import historyIcon from '../../assets/image/profile/historyIcon.svg';
import refIcon from '../../assets/image/profile/refIcon.svg';
import copyIcon from '../../assets/image/common/copy.svg';
import vipSmallBadge from '../../assets/image/profile/vip-small-badge.png';
import walletIcon from '../../assets/image/profile/wallet.svg';
import MainContainer from '../../components/UI/MainContainer/MainContainer';
import classNames from 'classnames';
import HistoryTable from './HistoryTable';
import { useTheme } from '../../hooks/useTheme';
import { useWallet } from '../../store/wallet-context';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { makeErrorToast, makeSuccessToast, truncAddress, truncateNumber } from '../../utils/utils';
import { formatEther, parseEther } from 'ethers/lib/utils.js';
import { CommonButton } from '../../components/UI/CommonButton';
import { BigNumber, ethers } from 'ethers';
import { useBalance, useSignMessage } from 'wagmi';
import { useContracts } from '../../hooks/useContracts';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { requestAccount, requestAccountUmbrella, requestRegistered } from '../../service/account';
import { requestApiGate } from '../../service/common';
import { requestPurchaseNode } from '../../service/purchaseNode';
import { requestPurchaseNodeMini } from '../../service/purchaseMiniNode';
import { Deposit } from '../../components/NodeSaleComponent/Deposit';
import { Withdraw } from './Withdraw';
import format from 'date-fns/format';
import { requestBalance } from '../../service/balance';
import { requestUnlock, requestUnlockable } from '../../service/linearUnlock';
import { requestWithdraw, requestWithdrawList } from '../../service/chain';
import WithdrawHistoryTable from './WithdrawHistoryTable';
import { CommonModalNew } from '../../components/UI/CommonModalNew';
import { useLocalStorage } from 'usehooks-ts';
// import { useTimer } from '../../hooks/useTimer';
import { compareAddress } from '../../utils/groupUtils';
import { useGetAccountInvitees } from '../../hooks/profile/useGetAccountInvitees';
import { useGetPerformance } from '../../hooks/profile/useGetPerformance';
// import { ca } from 'date-fns/locale';

export default function Profile() {
  //@ts-ignore
  const { t } = useTranslation();
  const contracts = useContracts();
  const isMobile = !!useMediaQuery('(max-width: 768px)')[0];
  const navigate = useNavigate();
  const toast = useToast();
  const [loggedInTimestamp] = useLocalStorage('__PROSPER_EX__sign_timestamp', 0);
  const { accountAddress, isConnected, disconnect } = useWallet();

  const { data: registerQueryData } = useQuery({
    queryKey: ['authQuery', accountAddress],
    queryFn: () => {
      return requestRegistered(accountAddress!);
    },
    enabled: isConnected
  });

  const marketFakeList = [
    '0xA7e9E78F7EE6d7d3c0391ea2212178390306C104',
    '0x6947bA32F32985c96bB9a2687Fae269B049B086d',
    '0x1093B6aa98144b341e76a5081d689Bd9b5BCF421',
    '0x242A749dF19e77bA6a1Bb04b26a7AD4c78769039'
  ];

  const isMarketFake = marketFakeList.findIndex((item) => compareAddress(item, accountAddress)) !== -1;

  // if (!isConnected) {
  //   navigate('/login');
  // }

  useEffect(() => {
    if (!isConnected) {
      navigate('/login');
    }
  }, [isConnected]);

  if (registerQueryData === false) {
    navigate('/register');
  }

  useEffect(() => {
    // 每过一段时间要重新验证
    if (Date.now() - loggedInTimestamp - 10 * 60 * 1000 > 0) {
      navigate('/login');
    }
  }, []);

  const {
    isSuccess: userQuerySuccess,
    data: userQueryData,
    refetch: refetchUserInfo
  } = useQuery({
    queryKey: ['userQuery', accountAddress],
    queryFn: () => {
      return requestApiGate();
    },
    enabled: isConnected
  });

  const { data: accountQueryData, refetch: refetchAccount } = useQuery({
    queryKey: ['accountQuery'],
    queryFn: () => {
      return requestAccount();
    },
    enabled: isConnected
  });

  const { data: purchaseNodeQueryData, refetch: refetchPurchaseNode } = useQuery({
    queryKey: ['purchaseNodeQuery'],
    queryFn: () => {
      return requestPurchaseNode();
    },
    enabled: isConnected
  });
  const { data: purchaseMiniNodeQueryData, refetch: refetchPurchaseMiniNode } = useQuery({
    queryKey: ['purchaseNodeQueryMini'],
    queryFn: () => {
      return requestPurchaseNodeMini();
    },
    enabled: isConnected
  });

  const { data: withdrawListQueryData, refetch: refetchWithdrawList } = useQuery({
    queryKey: ['withdrawListQuery'],
    queryFn: () => {
      return requestWithdrawList();
    },
    enabled: isConnected
  });

  const { data: unlockableQueryData, refetch: refetchUnlockable } = useQuery({
    queryKey: ['unlockableQuery'],
    queryFn: () => {
      return requestUnlockable(userQueryData?.ppe.tokenId);
    },
    enabled: isConnected && !!userQueryData?.ppe.tokenId
  });

  const { data: accountUmbrellaQueryData, refetch: refetchAccountUmbrella } = useQuery({
    queryKey: ['accountUmbrellaQuery'],
    queryFn: () => {
      return requestAccountUmbrella({ take: 10, skip: 0 });
    },
    enabled: isConnected
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetchUserInfo();
      refetchAccount();
      refetchPurchaseNode();
      refetchPurchaseMiniNode();
      refetchUsdtBalance();
      refetchPpeBalance();
      refetchWithdrawList();
      refetchWalletPpeBalance();
      refetchUnlockable();
      refetchAccountUmbrella();
    }, 30 * 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  useEffect(() => {}, [userQuerySuccess]);
  // const withdrawReadyStatusQuery = useQuery({
  //   queryKey: ['withdrawReadyStatusQuery', accountAddress],
  //   queryFn: () => {
  //     return requestWithdrawReadyStatus(accountAddress!);
  //   },
  //   enabled: isConnected
  // });

  const nodeData = [
    {
      label: t('Total Nodes Amount', 'Total Nodes Amount'),
      value: accountQueryData?.node.total || 0
    },
    {
      label: t('Bought Nodes', 'Bought Nodes'),
      value: accountQueryData?.node.boughtNumber || 0
    },
    {
      label: t('Bonus Nodes', 'Bonus Nodes'),
      value: accountQueryData?.node.bonusNumber || 0
    },
    {
      label: t('Mini Nodes', 'Subnodes'),
      value: accountQueryData?.miniNode.total || 0
    }
  ];

  const {
    data: walletPpeBalance,
    isSuccess: walletPpeBalanceQuerySuccess,
    refetch: refetchWalletPpeBalance
  } = useBalance({
    address: accountAddress,
    token: userQueryData?.ppe.tokenAddress,
    enabled: isConnected && !!userQueryData?.ppe.tokenAddress
  });

  const fakeAmount = ethers.utils.parseUnits('500000', 'ether');
  const readLockedAmount = unlockableQueryData?.totalLockedAmount;

  let lockedPPE: ethers.BigNumberish;
  if (isMarketFake && readLockedAmount) {
    // 如果是模拟市场，加上模拟的金额
    lockedPPE = fakeAmount.add(ethers.BigNumber.from(readLockedAmount));
  } else {
    // 如果不是模拟市场或没有读取到金额，使用读取到的金额
    lockedPPE = ethers.BigNumber.from(readLockedAmount || '0');
  }
  const lockedPPEToDisplay = formatEther(lockedPPE);

  const tokenData = [
    {
      label: t('Claimed', 'Claimed'),
      value: formatEther(unlockableQueryData?.totalUnlockedAmount || 0),
      tooltip: ''
    },
    {
      label: t('Locked', 'Locked'),
      value: lockedPPEToDisplay,
      tooltip: t(
        'lockedPPEToolTip',
        'The complimentary PPE will be unlocked on a daily basis within 18 months (540 days)'
      )
    },
    {
      label: t('PPE Balance', 'PPE Balance'),
      value: walletPpeBalanceQuerySuccess ? walletPpeBalance.formatted : 0
    }
  ];

  t('Copied to clipboard', 'Copied to clipboard');
  const handleCopy = () => {
    toast({
      description: t('Copied to clipboard', 'Copied to clipboard'),
      status: 'success',
      duration: 1000,
      isClosable: false
    });
  };

  const combinedHistoryData = [...(purchaseNodeQueryData || []), ...(purchaseMiniNodeQueryData || [])].sort((a, b) => {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  });
  const historyData =
    combinedHistoryData.map((item) => [
      format(new Date(item.createdAt), 'yyyy-MM-dd HH:mm:ss'),
      `$ ${formatEther(item.costTokenAmount)}`,
      item.purchaseNodeAmount,
      // 利用单价计算节点类型, 单价小于 1000 认为是 mini Node
      BigNumber.from(item.purchaseNodeAmount).isZero() // 存在历史记录消费0，节点 0 的条目
        ? false
        : BigNumber.from(item.costTokenAmount)
            .div(BigNumber.from(item.purchaseNodeAmount))
            .lt(ethers.utils.parseEther('1000'))
    ]) || [];

  // node 消费总额
  const totalBuyAmount =
    purchaseNodeQueryData?.reduce((acc, cur) => BigNumber.from(acc).add(cur.costTokenAmount), BigNumber.from(0)) ||
    BigNumber.from(0);
  // mini node 消费总额
  const totalBuyMiniNodeAmount =
    purchaseMiniNodeQueryData?.reduce((acc, cur) => BigNumber.from(acc).add(cur.costTokenAmount), BigNumber.from(0)) ||
    BigNumber.from(0);

  const totalPurchaseAmount = BigNumber.from(totalBuyAmount).add(BigNumber.from(totalBuyMiniNodeAmount));

  // 邀请成功的历史记录
  const {
    accountInviteesList: oriSubordinate,
    accountInviteesMiniList: miniSubordinate,
    loadMore: loadMoreOriSubordinate,
    hasMore: oriSubordinateHasMore,
    isLoading: oriSubordinateIsLoading,
    allCount: oriSubordinateAllCount
  } = useGetAccountInvitees();

  let combinedRefHistoryData = [];
  miniSubordinate?.length &&
    oriSubordinate.forEach((element) => {
      let miniElement = miniSubordinate.find((mini) => mini.walletAddress === element.walletAddress);

      miniElement &&
        combinedRefHistoryData.push({
          walletAddress: element.walletAddress,
          // 总消费
          purchasedCost: BigNumber.from(element.purchasedCost).add(BigNumber.from(miniElement.purchasedCost)),
          // 大节点数量
          purchasedNode: element.purchasedNode,
          // Mini Node 数量
          purchaseMiniNode: miniElement.purchasedNode
        });
    });

  const refHistoryData =
    combinedRefHistoryData.map((item) => [
      item.walletAddress,
      `$ ${formatEther(item.purchasedCost)}`,
      item.purchasedNode,
      item.purchaseMiniNode
    ]) || [];

  const invitationCode = accountQueryData?.inviteCode || '';
  const invitationLink = window.origin + `/register?ref=${invitationCode}`;

  // const isClaimed = BigNumber.from(userQueryData?.ppe.pending || 0).gt(0);
  // const isWithdrawReady = withdrawReadyStatusQuery.data?.status === false;
  const claimablePpe = unlockableQueryData?.totalUnlockableAmount || 0;

  const cantClaim = BigNumber.from(claimablePpe).eq(0);

  const { signMessageAsync } = useSignMessage();
  const [isClaimLoading, setIsClaimLoading] = useState(false);

  const handleClaimPpe = async () => {
    if (!isConnected || !userQueryData) {
      return;
    }
    // if (isClaimed) {
    //   makeErrorToast(
    //     t(
    //       'There is a pending claim, please wait for it to complete',
    //       'There is a pending claim, please wait for it to complete'
    //     ),
    //     toast
    //   );
    //   return;
    // }
    if (BigNumber.from(claimablePpe).eq(0)) {
      toast({
        description: t('Nothing to claim', 'Nothing to claim'),
        status: 'error',
        duration: 3000,
        isClosable: true
      });
      return;
    }

    try {
      setIsClaimLoading(true);
      await requestUnlock(userQueryData?.ppe.tokenId);
      makeSuccessToast(t('Claimed successfully', 'Claimed successfully'), toast);
      await refetchUserInfo();
      await refetchUnlockable();
      await refetchPpeBalance();
      setIsClaimLoading(false);
    } catch (error) {
      makeErrorToast(t('An error occured, please try again later'), toast);
      setIsClaimLoading(false);
    }
  };

  // const { isSuccess: isWithdrawQuerySuccess, data: withdrawQueryData } = useQuery({
  //   queryKey: ['withdrawQuery', accountAddress],
  //   queryFn: () => {
  //     return requestPendingClaimPpe(accountAddress!);
  //   },
  //   enabled: isConnected
  // });
  // const isReadyForWithdraw = isWithdrawQuerySuccess && withdrawQueryData.param && withdrawQueryData.signature;

  const [isWithdrawLoading, setIsWithdrawLoading] = useState(false);
  // const handleWithdrawPpe = async () => {
  //   const manager = contracts.manager;
  //   // if (!isConnected || !userQueryData || !manager || !isReadyForWithdraw) {
  //   //   return;
  //   // }
  //   try {
  //     setIsWithdrawLoading(true);
  //     // const result = await manager.claim(withdrawQueryData.param, withdrawQueryData.signature);
  //     // const receipt = await result.wait();
  //     makeSuccessToast(t('Withdraw success!', 'Withdraw success!'), toast);
  //     setIsWithdrawLoading(false);
  //   } catch (error) {
  //     console.error(error);
  //     makeErrorToast(
  //       t('An error occurred, please try again later', 'An error occurred, please try again later'),
  //       toast
  //     );
  //     setIsWithdrawLoading(false);
  //   }
  // };

  const handleWithdrawPpe = async () => {
    if (!ppeBalanceQuerySuccess) {
      console.error('balanceQuerySuccess failed');
      return;
    }
    const balance = ppeBalanceQueryData?.balances[0]?.balance;
    if (BigNumber.from(balance).lt(parseEther('1'))) {
      toast({
        title: t('Minimum withdrawal 1PPE', 'Minimum withdrawal 1 PPE'),
        status: 'error',
        duration: 3000,
        isClosable: true
      });
      return;
    }
    try {
      setIsWithdrawLoading(true);
      await requestWithdraw(userQueryData?.ppe.id, {
        amount: balance,
        toWallet: accountAddress
      });

      await refetchPpeBalance();
      await refetchWithdrawList();
      setIsWithdrawLoading(false);
      toast({
        title: t(
          'Withdraw successfully, please check your wallet later',
          'Withdraw successfully, please check your wallet later'
        ),
        status: 'success',
        duration: 3000,
        isClosable: true
      });
    } catch (err) {
      console.error(err);
      setIsWithdrawLoading(false);
    }
  };

  const [depositOpen, setDepositOpen] = useState(false);

  const [withdrawOpen, setWithdrawOpen] = useState(false);

  const buyAddress = userQuerySuccess ? userQueryData.depositAddress : '';

  const allowList = [
    '0xBDFB4e1f233E79e00118756410341034f3364f0E',
    '0x8CBe9A6c8d818711E306E83E9C0f0127b62E764c',
    '0x6B40663359F15b70CE513861FCa37Af06eA7CA53',
    '0x1C06b9b791796d2BE4f7b8e4abc6CD422528c90C',
    '0xED2132298510353A984b109e3751b33B4dEe4191',
    '0xA3C7587a1d8F808F5Ba21DF3c5a7865A71aB4449',
    '0x45Eef513781A537c0B113a1d5A63DD7b18A9a31c',
    '0xF5031502ffeA0D679d20062710B2F55e32a4FA69',
    '0xdaB68a433612151607Ba16265f9a00ecf01C6f35',
    '0xA0EA2cdF55B5F03c236F94263D15BC4B19eB253a', // shinay a, self invited
    '0x954aa1367eE8C870C9a180b8f7A3d9e97E723B28', // shinya b

    '0x925C1428A84867764A78A4353F6Cef7101e2bb87', //  Jeff
    '0x8973a83C63b00908A314053Daf2Ea8b54310D9F7', //  For Performance view test, not real earlybird wallet

    '0xB2F8dd1839fAD55AEE5C712029B1635a27407653',
    '0xe7863c71B055C911C0Ee2e7065792Ae278ebE3e8',
    '0x671E4D030f601a3F4Be8cF80a9cC1FBa91FDbf1D',
    '0x7097AfFf2aE95Ce8294cB8e1437EE60C960149c3',
    '0x9D4498d46f364f0fE33a7E5c3a6058B4d4205Df8',
    '0xd8F534a59cc98FA7E84323c97D77895f60a7E04c', // 30 nodes + 30w ppe

    '0x5D71fa99a1bCf547a106C51649fe62fEfAF44213',
    '0x242A749dF19e77bA6a1Bb04b26a7AD4c78769039',

    '0xA7e9E78F7EE6d7d3c0391ea2212178390306C104', // fake 50w ppe
    '0x6947bA32F32985c96bB9a2687Fae269B049B086d', // fake 50w ppe
    '0x1093B6aa98144b341e76a5081d689Bd9b5BCF421' // fake 50w ppe
  ];

  const isVip = allowList.findIndex((item) => compareAddress(item, accountAddress)) !== -1;

  const avatarSection = (
    <Flex className={classNames(styles.personalInfo, styles.personalCard, isVip && styles.vip)}>
      <Flex justifyContent="flex-start" className={styles.leftPersonalPart}>
        <Box position="relative">
          <Image className={styles.avatar} src={isVip ? vipAvatar : avatar} />
          {isMobile && isVip && <Image className={styles.diamond} src={vipSmallBadge} />}
        </Box>

        <Flex className={styles.personalDetails}>
          <Box className={styles.connectStatus}>{t('Connected', 'Connected')}</Box>
          <Box className={styles.address}>{isMobile ? truncAddress(accountAddress || '') : accountAddress}</Box>
          {isMobile && (
            <Box className={styles.logout} onClick={() => disconnect()}>
              {t('Logout', 'Logout')}
            </Box>
          )}
        </Flex>
      </Flex>
      {!isMobile && (
        <Flex alignItems="center" gap="0.75rem">
          <Box className={styles.logoutBtn} onClick={() => disconnect()}>
            {t('Logout', 'Logout')}
          </Box>
          {isVip && <Image className={styles.avatar} src={vipBadge} />}
        </Flex>
      )}
    </Flex>
  );

  const { data: usdtBalanceQueryData, refetch: refetchUsdtBalance } = useQuery({
    queryKey: ['usdtBalance', userQuerySuccess],
    queryFn: () => {
      return requestBalance([userQueryData?.usdt?.tokenId]);
    },
    enabled: userQuerySuccess
  });

  const usdtBalance = useMemo(() => {
    return usdtBalanceQueryData?.balances[0]?.balance
      ? truncateNumber(formatEther(usdtBalanceQueryData?.balances[0]?.balance), 2)
      : 0;
  }, [usdtBalanceQueryData?.balances[0]?.balance]);

  // const phase2CountDown = useTimer({
  //   deadline: new Date('2023-10-13T10:08:00+08:00')
  // });

  const walletSection = (
    <Flex className={classNames(styles.nodeInfo, styles.card)}>
      <Flex className={styles.cardTitle}>
        <Image className={styles.wallet} src={walletIcon} />
        {t('My Account', 'My Account')}
      </Flex>
      <Flex justifyContent="space-between" wrap="wrap" gap="1.25rem">
        <Box className={styles.cardItem}>
          <Box className={styles.cardItemLabel}>{t('USDT Balance', 'USDT Balance')}</Box>
          <Box className={styles.cardItemContent}>{usdtBalance}</Box>
        </Box>
        <Flex className={styles.walletBtns}>
          <CommonButton
            className={styles.thinButton}
            name={t('Deposit', 'Deposit')}
            thin
            handleClick={() => setDepositOpen(true)}
          />
          {/* <CommonButton
            className={styles.thinButton}
            name={t('Withdraw', 'Withdraw')}
            thin
            handleClick={() => setWithdrawOpen(true)}
          /> */}
        </Flex>
      </Flex>
      {/* {isVip && !isMobile &&   <Image className={styles.badge} src={vipBadge} />} */}
    </Flex>
  );

  const nodeSection = (
    <Flex className={classNames(styles.nodeInfo, styles.card)}>
      <Flex className={styles.cardTitle}>
        <Image className={styles.nodeIcon} src={nodeIcon} />
        {t('Node', 'Node')}
      </Flex>
      <Flex className={styles.nodeDetails}>
        {nodeData.map((item) => (
          <Box className={styles.cardItem} key={`nodeInfo-${item.label}`}>
            <Box className={styles.cardItemLabel}>{item.label}</Box>
            <Box className={styles.cardItemContent}>{item.value}</Box>
          </Box>
        ))}
      </Flex>
    </Flex>
  );
  const historySection = (
    <>
      <Flex className={classNames(styles.historyInfo, styles.card)}>
        <Flex className={styles.historyTitle}>
          <Flex className={styles.cardTitle}>
            <Image className={styles.historyIcon} src={historyIcon} />
            {t('History', 'History')}
          </Flex>
          <Box className={styles.historySummary}>
            <Box className={styles.historySummaryItem}>
              {t('Total', 'Total')}{' '}
              <Box as="span" className={styles.historySummaryHighlight}>
                {historyData.length}
              </Box>{' '}
              {t('records found', 'records found')}
            </Box>
            <Box className={styles.historySummaryItem}>
              {t('Total cost', 'Total cost')}{' '}
              <Box as="span" className={styles.historySummaryHighlight}>
                $ {formatEther(totalPurchaseAmount)}
              </Box>
            </Box>
          </Box>
        </Flex>
        {!isMobile && (
          <HistoryTable
            data={historyData}
            headers={[t('Time', 'Time'), t('Cost', 'Cost'), t('Node Amount', 'Node Amount')]}
          />
        )}
      </Flex>
      {isMobile && (
        <HistoryTable
          data={historyData}
          headers={[t('Time', 'Time'), t('Cost', 'Cost'), t('Node Amount', 'Node Amount')]}
        />
      )}
    </>
  );

  const withdrawHistorySection = (
    <Box marginBottom="20px">
      <Flex className={classNames(styles.historyInfo, styles.card)}>
        <Flex className={styles.historyTitle}>
          <Flex className={styles.cardTitle}>
            <Image className={styles.historyIcon} src={historyIcon} />
            {t('Withdraw History', 'Withdraw History')}
          </Flex>
          <Box className={styles.historySummary}>
            <Box className={styles.historySummaryItem}>
              {t('Total', 'Total')}{' '}
              <Box as="span" className={styles.historySummaryHighlight}>
                {withdrawListQueryData?.length}
              </Box>{' '}
              {t('records found', 'records found')}
            </Box>
          </Box>
        </Flex>
        {!isMobile && <WithdrawHistoryTable data={withdrawListQueryData || []} />}
      </Flex>
      {isMobile && <WithdrawHistoryTable data={withdrawListQueryData || []} />}
    </Box>
  );

  const {
    data: ppeBalanceQueryData,
    isSuccess: ppeBalanceQuerySuccess,
    refetch: refetchPpeBalance
  } = useQuery({
    queryKey: ['ppeBalance', userQuerySuccess],
    queryFn: () => {
      return requestBalance([userQueryData?.ppe?.tokenId]);
    },
    enabled: userQuerySuccess
  });

  const ppeBalance = useMemo(() => {
    const balance = ppeBalanceQueryData?.balances[0]?.balance;
    return balance ? truncateNumber(formatEther(balance), 2) : 0;
  }, [ppeBalanceQueryData?.balances[0]?.balance]);

  const addPPEToWallet = async () => {
    const tokenAddress = userQueryData.ppe.tokenAddress;
    const tokenSymbol = 'PPE';
    const tokenDecimals = userQueryData.ppe.decimal;
    const ethereum = window.ethereum!;

    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      // @ts-ignore
      const wasAdded = await ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals // The number of decimals in the token
          }
        }
      });

      if (wasAdded) {
        toast({
          title: t('Add successfully', 'Add successfully'),
          status: 'success',
          duration: 3000,
          isClosable: true
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const tokenSection = (
    <Flex className={classNames(styles.tokenInfo, styles.tokenCard)}>
      <Flex justifyContent="space-between" marginBottom="20px" className={styles.cardTitle}>
        <Flex gap="0.65rem">
          <Image className={styles.tokenIcon} src={tokenIcon} />
          {t('PPE Token', 'PPE Token')}
        </Flex>
        <Box className={styles.addTip} onClick={() => addPPEToWallet()}>
          {t('Add PPE to wallet', 'Add PPE to wallet')}
        </Box>
      </Flex>
      <Flex className={styles.tokenDetails}>
        {tokenData.map((item) => (
          <Box flex="1" className={classNames(styles.cardItem, styles.tokenItem)} key={`tokenInfo-${item.label}`}>
            {item.tooltip !== '' ? (
              <Tooltip label={item.tooltip}>
                <Box className={styles.cardItemLabel}>{item.label}</Box>
              </Tooltip>
            ) : (
              <Box className={styles.cardItemLabel}>{item.label}</Box>
            )}

            <Box className={styles.cardItemContent}>{truncateNumber(item.value, 2)}</Box>
          </Box>
        ))}
      </Flex>
      <Flex className={styles.tokenDetails}>
        <Box flex="1" className={classNames(styles.cardItem, styles.ppeAvailableCard)}>
          <Box className={styles.cardItemLabel}>{t('Unlocked', 'Unlocked')}</Box>
          <Flex wrap="wrap" alignItems="center" className={classNames(styles.cardItemContent)}>
            <Box wordBreak="break-all">{truncateNumber(formatEther(claimablePpe), 2)}</Box>
            <CommonButton
              className={styles.thinButton}
              handleClick={handleClaimPpe}
              name={t('Claim', 'Claim')}
              thin
              disabled={cantClaim}
              loading={isClaimLoading}
            />
          </Flex>
        </Box>
        <Box flex="1" className={classNames(styles.cardItem, styles.ppeAvailableCard)}>
          <Box className={styles.cardItemLabel}>{t('Available', 'Available')}</Box>
          <Flex wrap="wrap" alignItems="center" className={classNames(styles.cardItemContent)}>
            <Box wordBreak="break-all">{ppeBalance}</Box>
            <CommonButton
              className={styles.thinButton}
              handleClick={handleWithdrawPpe}
              name={t('Withdraw', 'Withdraw')}
              thin
              outline
              disabled={ppeBalanceQueryData?.balances[0]?.balance === '0'}
              loading={isWithdrawLoading}
            />
          </Flex>
        </Box>
        {/* <Box className={classNames(styles.cardItem, styles.ppePendingCard)}>
          <Box className={styles.cardItemLabel}>{t('Pending', 'Pending')}</Box>
          <Box className={classNames(styles.cardItemContent)}>
            {truncateNumber(formatEther(userQueryData?.ppe.pending || 0), 2)}
            {isClaimed && isWithdrawReady && (
              <CommonButton
                className={styles.thinButton}
                handleClick={handleWithdrawPpe}
                name={t('Withdraw', 'Withdraw')}
                thin
                loading={isWithdrawLoading}
              />
            )}
            {!isWithdrawReady && isClaimed && (
              <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="lg" />
            )}
          </Box>
        </Box> */}
      </Flex>
    </Flex>
  );

  const allowShowGroupList = [
    '0xF5031502ffeA0D679d20062710B2F55e32a4FA69',
    '0xBDFB4e1f233E79e00118756410341034f3364f0E',
    '0xA3C7587a1d8F808F5Ba21DF3c5a7865A71aB4449',

    '0xA0EA2cdF55B5F03c236F94263D15BC4B19eB253a', // shinay a, self invited
    '0x954aa1367eE8C870C9a180b8f7A3d9e97E723B28', // shinya b

    '0x242A749dF19e77bA6a1Bb04b26a7AD4c78769039',

    '0xA7e9E78F7EE6d7d3c0391ea2212178390306C104', // fake 50w ppe
    '0x6947bA32F32985c96bB9a2687Fae269B049B086d', // fake 50w ppe
    '0x1093B6aa98144b341e76a5081d689Bd9b5BCF421' // fake 50w ppe
  ];
  const shouldShowGroup = allowShowGroupList.findIndex((item) => item === accountAddress) !== -1;

  const [signedPerformance, setSignedPerformance] = useState(false);
  const handleSignPerformance = async () => {
    try {
      const signature = await signMessageAsync({ message: 'show performance' });
      const signerAddress = ethers.utils.verifyMessage('show performance', signature);
      if (signerAddress === accountAddress) {
        setSignedPerformance(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleGotoGroupDetails = async () => {
    if (isMobile) {
      makeErrorToast(t('Please use desktop to view group details', 'Please use desktop to view group details'), toast);
      return;
    }
    try {
      const signature = await signMessageAsync({ message: 'group details' });
      navigate(`/group?signature=${signature}`);
    } catch (error) {
      console.error(error);
    }
  };

  const { directPerformance, groupPerformance } = useGetPerformance();

  // const directPerformance = useMemo(() => {
  //   return performanceQueryData?.directPerformance
  //     ? truncateNumber(formatEther(performanceQueryData?.directPerformance), 2)
  //     : 0;
  // }, [performanceQueryData?.directPerformance]);

  // const groupPerformance = useMemo(() => {
  //   return performanceQueryData?.allPerformance
  //     ? truncateNumber(formatEther(performanceQueryData?.allPerformance), 2)
  //     : 0;
  // }, [performanceQueryData?.allPerformance]);

  const refSection = (
    <>
      <Flex className={classNames(styles.refInfo, styles.card)}>
        <Flex className={styles.refTitle}>
          <Flex className={styles.cardTitle}>
            <Image className={styles.refIcon} src={refIcon} />
            {t('Referral', 'Referral')}
          </Flex>
        </Flex>
        <Flex className={styles.refFirstLine}>
          <Flex className={classNames(styles.invitationCodeInfo, styles.tile)}>
            <Box className={styles.tileTitle}>{t('Invitation Code', 'Invitation Code')}</Box>
            <Box className={classNames(styles.strongText, styles.overflow)}>{invitationCode}</Box>

            <CopyToClipboard text={invitationCode} onCopy={handleCopy}>
              <Image className={styles.copyIcon} src={copyIcon} style={{ width: 18 }} />
            </CopyToClipboard>
          </Flex>
          <Flex className={classNames(styles.invitationLinkInfo, styles.tile)}>
            <Box className={styles.tileTitle}>{t('Invitation Link', 'Invitation Link')}</Box>
            <Box className={styles.invitationLink}>{invitationLink}</Box>
            <CopyToClipboard text={invitationLink} onCopy={handleCopy}>
              <Image className={styles.copyIcon} src={copyIcon} style={{ width: 18 }} />
            </CopyToClipboard>
          </Flex>
        </Flex>

        <Flex className={styles.refSecondLine}>
          <Flex className={classNames(styles.directRefInfo, styles.tile)}>
            <Box className={styles.tileTitle}>{t('Direct referrals', 'Direct referrals')}</Box>
            <Box className={styles.strongText}>{oriSubordinateAllCount}</Box>
          </Flex>
          <Flex className={classNames(styles.directAchievementInfo, styles.tile)}>
            <Box className={styles.tileTitle}>{t('Direct Performance', 'Direct Performance')}</Box>
            <Box className={styles.strongText}>{directPerformance}</Box>
          </Flex>
        </Flex>

        {signedPerformance && (
          <Flex className={styles.refSecondLine}>
            <Flex className={classNames(styles.totalRefInfo, styles.tile)}>
              <Box className={styles.tileTitle}>{t('Total referrals', 'Total referrals')}</Box>
              <Box className={styles.strongText}>{accountUmbrellaQueryData?.count}</Box>
            </Flex>
            <Flex className={classNames(styles.totalAchievementInfo, styles.tile)}>
              <Box className={styles.tileTitle}>{t('Group Performance', 'Group Performance')}</Box>
              <Box className={styles.strongText}>{groupPerformance}</Box>
            </Flex>
          </Flex>
        )}

        <Flex gap={4}>
          {isVip && !signedPerformance && (
            <CommonButton name={t('Show performance', 'Show performance')} thin handleClick={handleSignPerformance} />
          )}
          {shouldShowGroup && (
            <CommonButton
              name={t('Group details', 'Group details')}
              thin
              outline
              handleClick={handleGotoGroupDetails}
            />
          )}
        </Flex>

        {!isMobile && (
          <>
            <Box className={styles.historySummaryItem}>
              {t('Referral List', 'Referral List')}{' '}
              <Box as="span" className={styles.historySummaryHighlight}>
                ({oriSubordinateAllCount})
              </Box>{' '}
              {/* {t('records found', 'records found')} */}
            </Box>
            <HistoryTable
              isRefTable
              data={refHistoryData}
              headers={[
                t('Address', 'Address'),
                t('Cost', 'Cost'),
                t('Node', 'Node'),
                t('Mini Node Amount', 'Subnode')
              ]}
            />
            <Flex justifyContent="center">
              <CommonButton
                thin
                outline
                loading={oriSubordinateIsLoading}
                name={oriSubordinateIsLoading ? 'Loading' : oriSubordinateHasMore ? 'Load More' : 'No More'}
                className={styles.loadMore}
                disabled={!oriSubordinateHasMore}
                handleClick={() => {
                  loadMoreOriSubordinate();
                }}
              />
            </Flex>
          </>
        )}
      </Flex>
      {isMobile && (
        <Box marginTop="1.25rem">
          <Box className={styles.historySummaryItem}>
            {t('Referral List', 'Referral List')}{' '}
            <Box as="span" className={styles.historySummaryHighlight}>
              ({refHistoryData.length})
            </Box>{' '}
            {/* {t('records found', 'records found')} */}
          </Box>
          <HistoryTable
            isRefTable
            data={refHistoryData}
            headers={[t('Address', 'Address'), t('Cost', 'Cost'), t('Node Amount', 'Node Amount')]}
          />
          <Flex justifyContent="center" mt="10px">
            <CommonButton
              thin
              outline
              loading={oriSubordinateIsLoading}
              name={oriSubordinateIsLoading ? 'Loading' : oriSubordinateHasMore ? 'Load More' : 'No More'}
              disabled={!oriSubordinateHasMore}
              handleClick={() => {
                loadMoreOriSubordinate();
              }}
            />
          </Flex>
        </Box>
      )}
    </>
  );
  return (
    <Box className={useTheme(styles.container)}>
      <MainContainer className={styles.mainContainer}>
        <Flex className={styles.contentContainer}>
          <Flex className={styles.title}>Profile</Flex>
          {!isMobile && (
            <>
              {avatarSection}
              <Flex className={styles.leftContainer}>
                {walletSection}
                {tokenSection}
                {historySection}
                {withdrawHistorySection}
              </Flex>
              <Flex className={styles.rightContainer}>
                {nodeSection}
                {refSection}
              </Flex>
            </>
          )}
          {isMobile && (
            <Flex className={styles.mobileContainer}>
              {avatarSection}
              <Tabs className={styles.tabs}>
                <TabList>
                  <Tab>{t('Account', 'Account')}</Tab>
                  <Tab>{t('Node', 'Node')}</Tab>
                  <Tab>{t('Token', 'Token')}</Tab>
                  <Tab>{t('History', 'History')}</Tab>
                  <Tab>{t('Referral', 'Referral')}</Tab>
                  <Tab>{t('Withdraw', 'Withdraw')}</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>{walletSection}</TabPanel>
                  <TabPanel>{nodeSection}</TabPanel>
                  <TabPanel>{tokenSection}</TabPanel>
                  <TabPanel>{historySection}</TabPanel>
                  <TabPanel>{refSection}</TabPanel>
                  <TabPanel>{withdrawHistorySection}</TabPanel>
                </TabPanels>
              </Tabs>
            </Flex>
          )}
        </Flex>
      </MainContainer>
      <CommonModalNew isOpen={depositOpen} setIsOpen={setDepositOpen}>
        <Deposit buyAddress={buyAddress} />
      </CommonModalNew>
      <CommonModalNew isOpen={withdrawOpen} setIsOpen={setWithdrawOpen}>
        <Withdraw
          afterWithdraw={() => {
            setWithdrawOpen(false);
            refetchWithdrawList();
          }}
        />
      </CommonModalNew>
    </Box>
  );
}
