import React from 'react';
import { CommonButton } from '../UI/CommonButton';
import { useWallet } from '../../store/wallet-context';
import { truncAddress } from '../../utils/utils';
import { useTranslation } from 'react-i18next';

type WalletButtonProps = {
  onClick?: (connectResult: any) => void;
};

const WalletButton = ({ onClick = () => {} }: WalletButtonProps) => {
  // use multi wallet button instead
  return null;
  //@ts-ignore
  const { t } = useTranslation();
  const { connect, disconnect, accountAddress, isConnected } = useWallet();
  if (accountAddress && isConnected) {
    return <CommonButton name={truncAddress(accountAddress)} handleClick={disconnect} thick />;
  }
  return (
    <CommonButton
      name={t('Connect Wallet', 'Connect Wallet')}
      thick
      handleClick={async () => {
        try {
          const connectResult = await connect();
          onClick(connectResult);
        } catch (e) {
          console.log(e);
        }
      }}
    />
  );
};

export default WalletButton;
