import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL!;

const tokenAxios = axios.create({
  baseURL: baseUrl
});

tokenAxios.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.authorization = token;
    }
    return config;
  },
  (err) => Promise.reject(err)
);

tokenAxios.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (err) => Promise.reject(err?.response?.data?.error)
);

function getToken() {
  try {
    return window.localStorage?.['__PROSPER_EX__userInfo']
      ? JSON.parse(window.localStorage['__PROSPER_EX__userInfo'])?.token
      : '';
  } catch (err) {
    console.log('err', err);
    return '';
  }
}

export { tokenAxios };
