import React, { useEffect } from 'react';
import styles from './index.module.scss';
import { Box, useToast } from '@chakra-ui/react';
import MainContainer from '../../components/UI/MainContainer/MainContainer';
import { useTheme } from '../../hooks/useTheme';
import { useWallet } from '../../store/wallet-context';
import { useLocation, useNavigate } from 'react-router-dom';
import { CommonButton } from '../../components/UI/CommonButton';
import { useSignMessage } from 'wagmi';
import { useLocalStorage } from 'usehooks-ts';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ethers } from 'ethers';
import { requestAuth, requestLogin } from '../../service/auth';
import { requestRegistered } from '../../service/account';
import WalletButton from '../../components/WalletButton/WalletButton';
import MultiWalletButton from '../../components/MultiWalletButton';

export default function Login() {
  //@ts-ignore
  const { t } = useTranslation();

  const location = useLocation();
  const { accountAddress, isConnected } = useWallet();
  const queryParams = new URLSearchParams(location.search);
  const referrer = queryParams.get('referrer');
  const loginRedict = referrer ? `/${referrer}` : '/';

  console.log('loginRedict', loginRedict);

  const [loggedIn, setLoggedIn] = useLocalStorage('__PROSPER_EX__loggedIn', false);
  const [loggedInTimestamp, setLoggedInTimestamp] = useLocalStorage('__PROSPER_EX__sign_timestamp', 0);
  const [userInfo, setUserInfo] = useLocalStorage('__PROSPER_EX__userInfo', {});

  const toast = useToast();
  const navigate = useNavigate();
  const { signMessageAsync } = useSignMessage();

  useEffect(() => {
    if (accountAddress) {
      requestRegistered(accountAddress).then((registerResult) => {
        if (registerResult === false) {
          navigate('/register');
        }
      });
    }
  }, [accountAddress]);

  useEffect(() => {
    setLoggedInTimestamp(null);
    setLoggedIn(false);
  }, []);

  const handleLogin = async () => {
    const address = accountAddress!;
    const registerResult = await requestRegistered(address);
    const authResult = await requestAuth(address);
    try {
      if (registerResult) {
        const signature = await signMessageAsync({ message: String(authResult.messageToSign) });
        const declaredPublicKey = ethers.utils.recoverPublicKey(
          ethers.utils.hashMessage(authResult.messageToSign),
          signature
        );
        const loginResult = await requestLogin({
          signedMessage: authResult.messageToSign,
          declaredPublicKey,
          signature,
          inviteCode: null
        });
        setUserInfo(loginResult);
        setLoggedIn(true);
        setLoggedInTimestamp(Date.now());
        navigate(loginRedict);
      } else {
        navigate('/register');
      }
    } catch (error) {
      toast({
        title: 'Login failed',
        description: 'Please try again later',
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    }
  };
  return (
    <Box className={useTheme(styles.container)}>
      <MainContainer className={classNames(styles.contentContainer, styles.mainContainer)}>
        <Box className={styles.contentContainer}>
          <Box className={styles.title}>{t('Login', 'Login')}</Box>
          <Box className={styles.inputWrapper}>
            <Box className={styles.inputPrompt}>
              {isConnected ? '' : t('Connect wallet to login', 'Connect wallet to login')}
            </Box>
            <WalletButton />
            <MultiWalletButton />
            <CommonButton handleClick={handleLogin} name={t('Login', 'Login')} thick disabled={!isConnected} />
          </Box>
          <Box className={styles.gotoRegister}>
            {t('I don’t have an account', 'I don’t have an account')},{' '}
            <a href="register">{t('Register', 'Register')}</a>
          </Box>
        </Box>
      </MainContainer>
    </Box>
  );
}
