import { Box, Flex } from '@chakra-ui/react';
import styles from './TermsAndConditions.module.scss';
import React from 'react';
import classNames from 'classnames';
import { useLocalStorage } from 'usehooks-ts';
import { CommonButton } from '../UI/CommonButton';

type Props = {
  onAgree: () => void;
};

export function TermsAndConditions({ onAgree }: Props) {
  const [agreeed, setAgreed] = useLocalStorage('__PROSPER_EX__agreedTermsAndConditions', false);
  return (
    <Flex className={styles.container}>
      <Box className={styles.mainTitle}>Terms and Conditions of the "Early Bird Program"</Box>
      <Box className={styles.title}>Intent and Vision of the Early Bird Program</Box>
      <Box className={styles.detail}>
        ProsperEx cryptocurrency exchange, driven by its mission to build a universal exchange that combines the
        security of DeFi with the real-world assets (RWA), and we sincerely invite you to participate in our Early Bird
        Program, together pioneering a new era in cryptocurrency trading. The purpose of the Early Bird Program is to
        provide unique opportunities and benefits, enabling early team leaders and market pioneers to achieve success on
        our platform. We believe that through the Early Bird Program, you will be able to gain long-term returns and
        exclusive privileges on this exchange.
      </Box>
      <Box className={styles.detail}>
        Please carefully read the following "Terms and Conditions of the Early Bird Program" (hereinafter referred to as
        the "Terms"):
      </Box>

      <Box className={styles.title}>Overview of the Early Bird Program</Box>

      <Box className={styles.detail}>
        1.1 ProsperEx cryptocurrency exchange (hereinafter referred to as "ProsperEx") has launched the "Early Bird
        Program" aimed at inviting 50 early founding members to embark on the path to prosperity together.
      </Box>
      <Box className={styles.detail}>
        1.2 Members participating in the Early Bird Program will enjoy unique privileges, including the allocation of
        500,000 PPE tokens through an airdrop.
      </Box>
      <Box className={styles.detail}>
        1.3 The steps to apply for participation in the Early Bird Program are as follows:
        <ul>
          <li>1.3.1 Purchase 10 or more nodes.</li>
          <li>1.3.2 Take on additional responsibilities for actively promoting the community.</li>
        </ul>
      </Box>

      <Box className={styles.title}>Binding Terms</Box>
      <Box className={styles.detail}>
        2.1 This program is only applicable to node sales during the specified period.
      </Box>
      <Box className={styles.detail}>
        2.2 ProsperEx reserves the right to change or terminate the Early Bird Program at any time and will notify
        participants in advance.
      </Box>
      <Box className={styles.detail}>
        2.3 Participants must comply with all regulations and policies of the exchange.
      </Box>
      <Box className={styles.detail}>
        2.4 Participants must adhere to local laws and regulations and take responsibility for their participation.
      </Box>
      <Box className={styles.detail}>
        2.5 ProsperEx reserves the right to adjust node returns and privileges based on actual circumstances and will
        notify participants in advance.
      </Box>

      <Box className={classNames(styles.detail, styles.tip)}>
        Please ensure that you carefully read, understand, and agree to the above terms and conditions before
        participating in the Early Bird Program. By participating in the Early Bird Program, you indicate your agreement
        to comply with the above provisions and accept the related obligations.
      </Box>
      <Box className={styles.detail}>
        Let us join hands and forge a prosperous future together! Participate in the ProsperEx Early Bird Program and
        embark on your journey in digital currency trading.
      </Box>
      {!agreeed && (
        <CommonButton
          className={styles.agreeButton}
          handleClick={() => {
            setAgreed(true);
            onAgree();
          }}
          name="I agree"
        />
      )}
    </Flex>
  );
}
