import { Box, Flex } from '@chakra-ui/react';
import styles from './index.module.scss';
import { useTheme } from '../../hooks/useTheme';
import { CommonButton } from '../UI/CommonButton';
import MainContainer from '../UI/MainContainer/MainContainer';
// import { TabMenu } from './TabMenu';
import React from 'react';
import { TokenCardSkeleton } from './TokenCardSkeleton';
import { usePrice } from '../../hooks/usePrice';
import { Carousel } from './Carousel';
import { useTranslation } from 'react-i18next';

export function Trends() {
  //@ts-ignore
  const { t } = useTranslation();
  const menuItems = [
    t('All', 'All'),
    t('Top Gainers', 'Top Gainers'),
    t('New Listing', 'New Listing'),
    t('FX', 'FX'),
    t('Stock', 'Stock'),
    t('Commodity', 'Commodity')
  ];
  const { isSuccess, data: result } = usePrice();
  const [activeItem, setActiveItem] = React.useState(menuItems[0]);

  return (
    <>
      <Box className={useTheme(styles.container)}>
        <MainContainer className={styles.contentContainer}>
          <Flex className={styles.mainFlex}>
            <Flex className={styles.titleContainer}>
              <Box className={styles.title}>{t('Market Trends', 'Market Trends')}</Box>
              {/* <CommonButton
                handleClick={undefined}
                name={'Start trading'}
                outline
                className={styles.joinBtn}
              /> */}
              <a href="https://app.prosperex.xyz/futures" target="_blank" rel="noopener noreferrer">
                <CommonButton
                  handleClick={undefined}
                  name={t('Start trading', 'Start trading')}
                  className={styles.joinBtn}
                />
              </a>
            </Flex>
            {/* <TabMenu menuItems={menuItems} activeItem={activeItem} onTabChange={setActiveItem} /> */}
            <Box className={styles.divider} />

            <Box className={styles.tokenCardOuterContainer}>
              {isSuccess ? (
                <Flex className={styles.priceCardOuterContainer}>
                  <Carousel items={result!} />
                </Flex>
              ) : (
                <Flex className={styles.priceCardContainer} alignItems="center" mt="6rem">
                  {new Array(3).fill(0).map((_, index) => {
                    return <TokenCardSkeleton key={`trend-skeleton-${index}`} />;
                  })}
                </Flex>
              )}
            </Box>
          </Flex>
        </MainContainer>
      </Box>
    </>
  );
}
