export const isMetamaskInstalled = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  if (window.ethereum?.isMetaMask) {
    return true;
  }

  if (window.ethereum?.providers?.some((p) => p.isMetaMask)) {
    return true;
  }

  return false;
};
export const isTokenPocketInstalled = () => {
  return typeof window !== 'undefined' && Boolean(window.ethereum?.isTokenPocket);
};
export const isTrustWalletInstalled = () => {
  return getTrustWalletProvider() !== null;
};

export const getTokenPocketProvider = () => {
  const provider = window.ethereum;
  return provider;
};

export const isOkxInstalled = () => {
  const ua = navigator.userAgent;
  const isOKApp = /OKApp/i.test(ua);
  return isOKApp;
};
declare global {
  interface Window {
    okxwallet?: any;
  }
}
export const getOkxProvider = () => {
  const provider = window.okxwallet;
  return provider;
};
declare global {
  interface Window {
    bitkeep?: any;
  }
}
export const isBitkeepInstalled = () => {
  const provider = window.bitkeep && window.bitkeep.ethereum;
  return !!provider;
};

export const getBitgetProvider = () => {
  const provider = window.bitkeep && window.bitkeep.ethereum;
  return provider;
};

export function getTrustWalletProvider() {
  const isTrustWallet = (ethereum) => {
    // Identify if Trust Wallet injected provider is present.
    const trustWallet = !!ethereum.isTrust;

    return trustWallet;
  };

  const injectedProviderExist =
    typeof window !== 'undefined' && typeof window.ethereum !== 'undefined';

  if (!injectedProviderExist) {
    return null;
  }

  if (isTrustWallet(window.ethereum)) {
    return window.ethereum;
  }

  if (window.ethereum?.providers) {
    return window.ethereum.providers.find(isTrustWallet) ?? null;
  }

  return window['trustwallet'] ?? null;
};

