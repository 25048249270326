import React from 'react';
import { useTheme } from '../../hooks/useTheme';
import styles from './ExCard.module.scss';

type Prop = {
  imgSrc: string;
  title: string;
  detail: string;
};

const ExCard = ({ imgSrc, title, detail }: Prop) => {
  return (
    <div className={useTheme(styles.exCardWrapper)}>
      <img src={imgSrc} alt={title} />
      <h2>{title}</h2>
      <p>{detail}</p>
    </div>
  );
};

export default ExCard;
