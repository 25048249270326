import styles from './index.module.scss';
import { Box, Flex, Input, useToast,InputGroup, InputRightElement } from '@chakra-ui/react';
import { useTheme } from '../../hooks/useTheme';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import React,{ useState } from 'react';
import emailjs from '@emailjs/browser';
import { ReactComponent as TwitterIcon } from '../../assets/image/treasury/twitter.svg';
import { ReactComponent as MediumIcon } from '../../assets/image/treasury/medium.svg';
import { ReactComponent as TelegramIcon } from '../../assets/image/treasury/telegram.svg';
import { ReactComponent as EnterIcon } from '../../assets/image/treasury/enter.svg';
import { useIsMobile } from '../../hooks/useIsMobile';

export default function Treasury() {
  //@ts-ignore
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [email, setEmail] = useState('');

  const socialItems = [
    {
      name: 'Twitter',
      src: <TwitterIcon />,
      link: 'https://twitter.com/prosper_ex'
    },
    {
      name: 'Medium',
      src: <MediumIcon />,
      link: 'https://prosperex.medium.com/'
    },
    {
      name: 'Telegram',
      src: <TelegramIcon />,
      link: 'https://t.me/prosper_ex'
    }
  ];
  const toast = useToast();
  const toastIdRef = React.useRef<any>();

  function closeLoadingToast() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  }
  const handleSubmit = async () => {
    const emailError = !email || !email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
    if (emailError) {
      toast({
        description: t('Please check your inputs.', 'Please check your inputs.'),
        status: 'error',
        duration: 2000,
        isClosable: false
      });
    } else {
      const serviceId = process.env.REACT_APP_SERVICE_ID;
      const messageTemplateId = process.env.REACT_APP_MESSAGE_SUBSCRIBE_TEMPLATE_ID;
      // const thanksTemplateId = process.env.REACT_APP_THANKS_SUBSCRIBE_TEMPLATE_ID;
      const pubkey = process.env.REACT_APP_PUBKEY;
      if (!serviceId || !messageTemplateId || !pubkey) {
        console.error('Missing env variables');
        return;
      }
      // toastIdRef.current = toast({
      //   description: 'loading...',
      //   status: 'success',
      //   duration: 60_000
      // });

      await emailjs
        .send(
          serviceId,
          messageTemplateId,
          {
            from_name: 'prosperex landing page',
            to_name: 'prosperex',
            message: email
          },
          pubkey
        )
        .catch((err) => {
          console.error(err);
          closeLoadingToast();
          toast({
            description: 'fail to send email, please try again later',
            status: 'error',
            duration: 3_000
          });
        })
        .then(
          (result) => {
            closeLoadingToast();
            toast({
              description: 'Thanks for your subscription',
              status: 'success',
              duration: 2000
            });
          },
          (error) => {
            closeLoadingToast();
          }
        );

      // emailjs
      //   .send(
      //     serviceId,
      //     thanksTemplateId,
      //     {
      //       from_name: name,
      //       from_address: email
      //     },
      //     pubkey
      //   )
    }
  };

  return (
    <Box className={useTheme(styles.container)}>
      <div className={styles.mountainPlayer}></div>

      <Flex className={styles.contentWrapper}>
        <Flex className={styles.banner}>
          <Box className={styles.title}>U.S. Treasury</Box>
          <Flex className={styles.comingSoon}>
            <Box>COMING</Box>
            <Box>SOON</Box>
          </Flex>
        </Flex>
        <Flex className={styles.content}>
          <Flex className={classNames(styles.details)}>
            <Box className={classNames(styles.tBill)}>T-BILL</Box>
            <Box className={classNames(styles.divider)} />
            <Box className={classNames(styles.rwa)}>RWA</Box>
            <Box className={classNames(styles.divider)} />
            <Box className={classNames(styles.apy)}>APY: 5% + X%</Box>
          </Flex>
          <Flex className={styles.subscribeText}>
            <Flex className={styles.subscribeTextWrapper}>
              <Box className={styles.subscribeText1}>{t('Subscribe', 'Subscribe')}</Box>
              <Box className={styles.subscribeText2}>
                {t('to be the first to know about our launch', 'to be the first to know about our launch')}
              </Box>
            </Flex>
            <Box className={classNames(styles.inputEmailSection)}>
              <Box className={styles.inputWrapper}>
                <InputGroup>
                  <Input
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder={t('Enter your email address', 'Enter your email address')}
                    className={styles.input}
                    focusBorderColor={'none'}
                  />
                  <InputRightElement className={styles.enterIcon} onClick={handleSubmit}>
                    <EnterIcon />
                  </InputRightElement>
                </InputGroup>
              </Box>
            </Box>
          </Flex>

          <Flex className={styles.socialWrapper}>
            {socialItems.map((item, index) => {
              return (
                <a href={item.link} target="_blank" key={index} className={styles.social}>
                  {item.src}
                </a>
              );
            })}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}
