import React, { ChangeEvent } from 'react';
import { Input } from '@chakra-ui/react';
import classNames from 'classnames';
import styles from './index.module.scss';

type CommonInputProps = {
  placeholder?: string;
  className?: string;
  type?: 'text' | 'number';
  disabled?: boolean;
  value: string;
  onChange: (e: any) => void;
  isError?: boolean;
};

export function CommonInput({
  placeholder = '',
  value,
  onChange,
  className,
  type = 'text',
  disabled,
  isError
}: CommonInputProps) {
  const onValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (type === 'number') {
      if (value.length < 18 && !Number.isNaN(Number(value))) {
        onChange(e);
      }
    } else {
      onChange(e);
    }
  };
  return (
    <Input
      variant="outline"
      placeholder={placeholder}
      value={value}
      onChange={onValueChange}
      size="lg"
      borderRadius={40}
      paddingLeft={5}
      className={classNames(className, isError && styles.errorInput)}
      disabled={disabled}
    />
  );
}
