import React from 'react';
import { Box, Flex, Img, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../hooks/useTheme';
import styles from './History.module.scss';
import { useOtcHistorys } from '../../../hooks/otc/useOtcHistory';
import historyIcon from '../../../assets/image/otc/history.png';
import usdtIcon from '../../../assets/image/otc/usdt.png';
import classNames from 'classnames';
import { OtcBill as OtcHistoryType } from '../../../utils/types';
import { useContracts } from '../../../hooks/useContracts';
import { useMessage } from '../../../hooks/useToast';
import { useIsMobile } from '../../../hooks/useIsMobile';

export default function OtcHistory() {
  //@ts-ignore
  const { t } = useTranslation();
  const histories = useOtcHistorys();
  const contracts = useContracts();
  const message = useMessage();
  t('Finished', 'Finished');
  t('Cancelled', 'Cancelled');
  t('Listing', 'Listing');

  const handleGoToOtcHome = () => {
    window.location.href = '/otc?page=home';
  };

  const handleCancel = async (ticketId: string) => {
    const manager = contracts.manager;
    if (!manager) return;
    try {
      const tx = await manager.cancel(ticketId);
      const receipt = await tx.wait();
      console.log('receipt', receipt);
      message.success(t('Cancel successfully', 'Cancel successfully'));
    } catch (error) {
      console.error(error);
      message.error(t('Cancel failed', 'Cancel failed'));
    }
  };

  return (
    <Box className={useTheme(styles.container)}>
      <Flex className={styles.back}>
        <Box onClick={handleGoToOtcHome}>
          {'< '} {t('Back', 'Back')}
        </Box>
      </Flex>
      <Flex className={styles.header}>
        <Img src={historyIcon} className={styles.historyIcon} />
        <Box className={styles.historyTitle}>{t('History', 'History')}</Box>
      </Flex>
      <Tabs>
        <TabList justifyContent="center">
          <Tab>{t('Buy', 'Buy')}</Tab>
          <Tab>{t('Sell', 'Sell')}</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <BuyHistory histories={histories.buys} onCancel={handleCancel} />
          </TabPanel>
          <TabPanel>
            <SellHistory histories={histories.sells} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

const BuyHistory = (props: { histories: OtcHistoryType[]; onCancel: (ticketId: string) => void }) => {
  //@ts-ignore
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const desktopBuyHistoryComponent = (
    <Flex className={styles.tableContainer}>
      <Box>
        <table className={styles.table}>
          <thead>
            <tr>
              {/* <th>{t('Create Time', 'Create Time')}</th> */}
              {/* <th>{t('Hash', 'Hash')}</th> */}
              <th>{t('Buy Price', 'Buy Price')}</th>
              <th>{t('PPE Amount', 'PPE Amount')}</th>
              <th>{t('Total Amount', 'Total Amount')}</th>
              <th>{t('Status', 'Status')}</th>
              <th>{t('Operation', 'Operation')}</th>
            </tr>
          </thead>
          <tbody>
            {props.histories.map((row, rowIndex) => (
              <tr key={`row-${rowIndex}`}>
                {/* <td>{row.createdAt}</td> */}
                <td>$ {row.buyPrice}</td>
                <td>{row.ppeAmount}</td>
                <td>
                  <Flex justifyContent="center">
                    <Img className={styles.usdtIcon} src={usdtIcon} />
                    {row.usdtAmount}
                  </Flex>
                </td>
                {row.status === 'Listing' ? (
                  <td className={classNames(styles.status, row.status === 'Listing' && styles.ongoing)}>
                    {t('Listing', 'Listing')}
                  </td>
                ) : (
                  <td>{t(row.status, row.status)}</td>
                )}
                {row.status === 'Listing' ? (
                  <td className={styles.cancel} onClick={() => props.onCancel(row.ticketId)}>
                    {t('Cancel', 'Cancel')}
                  </td>
                ) : (
                  <td>-</td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Flex>
  );
  const mobileBuyHistoryComponent = (
    <Flex className={styles.mobileHistoryContainer}>
      {props.histories.map((row, rowIndex) => (
        <Box key={`row-${rowIndex}`} className={styles.mobilehHistoryItem}>
          <Flex className={styles.row}>
            <Box className={styles.rowTitle}>{t('Create Time', 'Create Time')}</Box>
            <Box className={styles.rowContent}>{row.createdAt}</Box>
          </Flex>
          <Flex className={styles.row}>
            <Box className={styles.rowTitle}>{t('Buy Price', 'Buy Price')}</Box>
            <Box className={styles.rowContent}>$ {row.buyPrice}</Box>
          </Flex>
          <Flex className={styles.row}>
            <Box className={styles.rowTitle}>{t('PPE Amount', 'PPE Amount')}</Box>
            <Box className={styles.rowContent}>{row.ppeAmount}</Box>
          </Flex>
          <Flex className={styles.row}>
            <Box className={styles.rowTitle}>{t('Total Amount', 'Total Amount')}</Box>
            <Box className={styles.rowContent}>
              <Flex justifyContent="center">
                <Img className={styles.usdtIcon} src={usdtIcon} />
                {row.usdtAmount}
              </Flex>
            </Box>
          </Flex>
          <Flex className={styles.row}>
            <Box className={styles.rowTitle}>{t('Status', 'Status')}</Box>
            <Box className={styles.rowContent}>{t(row.status, row.status)}</Box>
          </Flex>
          <Flex className={styles.row}>
            <Box className={styles.rowTitle}>{t('Operation', 'Operation')}</Box>
            {row.status === 'Listing' ? (
              <Box className={styles.mobileRowCancel} onClick={() => props.onCancel(row.ticketId)}>
                {t('Cancel', 'Cancel')}
              </Box>
            ) : (
              <Box className={styles.rowContent}>-</Box>
            )}
          </Flex>
        </Box>
      ))}
    </Flex>
  );
  return isMobile ? mobileBuyHistoryComponent : desktopBuyHistoryComponent;
};

const SellHistory = (props: { histories: OtcHistoryType[] }) => {
  //@ts-ignore
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const desktopSellHistoryComponent = (
    <Flex className={styles.tableContainer}>
      <Box>
        <table className={styles.table}>
          <thead>
            <tr>
              {/* <th>{t('Create Time', 'Create Time')}</th> */}
              <th>{t('Buy Price', 'Buy Price')}</th>
              <th>{t('PPE Amount', 'PPE Amount')}</th>
              <th>{t('Total Amount', 'Total Amount')}</th>
            </tr>
          </thead>
          <tbody>
            {props.histories.map((row, rowIndex) => (
              <tr key={`row-${rowIndex}`}>
                {/* <td>{row.createdAt}</td> */}
                <td>$ {row.buyPrice}</td>
                <td>{row.ppeAmount}</td>
                <td>
                  <Flex justifyContent="center">
                    <Img className={styles.usdtIcon} src={usdtIcon} />
                    {row.usdtAmount}
                  </Flex>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Flex>
  );
  const mobileSellHistoryComponent = (
    <Flex className={styles.mobileHistoryContainer}>
      {props.histories.map((row, rowIndex) => (
        <Box key={`row-${rowIndex}`} className={styles.mobilehHistoryItem}>
          <Flex className={styles.row}>
            <Box className={styles.rowTitle}>{t('Create Time', 'Create Time')}</Box>
            <Box className={styles.rowContent}>{row.createdAt}</Box>
          </Flex>
          <Flex className={styles.row}>
            <Box className={styles.rowTitle}>{t('Buy Price', 'Buy Price')}</Box>
            <Box className={styles.rowContent}>$ {row.buyPrice}</Box>
          </Flex>
          <Flex className={styles.row}>
            <Box className={styles.rowTitle}>{t('PPE Amount', 'PPE Amount')}</Box>
            <Box className={styles.rowContent}>{row.ppeAmount}</Box>
          </Flex>
          <Flex className={styles.row}>
            <Box className={styles.rowTitle}>{t('Total Amount', 'Total Amount')}</Box>
            <Box className={styles.rowContent}>
              <Flex justifyContent="center">
                <Img className={styles.usdtIcon} src={usdtIcon} />
                {row.usdtAmount}
              </Flex>
            </Box>
          </Flex>
          <Flex className={styles.row}>
            <Box className={styles.rowTitle}>{t('Status', 'Status')}</Box>
            <Box className={styles.rowContent}>{t(row.status, row.status)}</Box>
          </Flex>
        </Box>
      ))}
    </Flex>
  );
  return isMobile ? mobileSellHistoryComponent : desktopSellHistoryComponent;
};
