import React from 'react';
import { Box } from '@chakra-ui/react';
import './Carousel.module.scss';
import { PriceCard } from './PriceCard';
import { PriceApiResult } from '../../types';
type Prop = {
  item: PriceApiResult;
};
export function CarouselItem({ item }: Prop) {
  return (
    <Box className="keen-slider__slide">
      <PriceCard
        name={item.name}
        trend={item.trend}
        fluctuation={item.fluctuation}
        price={item.price}
        tradeLink={item.tradeLink}
      />
    </Box>
  );
}
