/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  ManagerEvent,
  ManagerEventInterface,
} from "../../../contracts/manager/ManagerEvent";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes32",
        name: "ticketId",
        type: "bytes32",
      },
    ],
    name: "TicketWithdraw",
    type: "event",
  },
] as const;

export class ManagerEvent__factory {
  static readonly abi = _abi;
  static createInterface(): ManagerEventInterface {
    return new utils.Interface(_abi) as ManagerEventInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ManagerEvent {
    return new Contract(address, _abi, signerOrProvider) as ManagerEvent;
  }
}
