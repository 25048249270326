import { requestAccountInvitees, requestAccountInviteesMini } from '../../service/account';
import { useEffect, useMemo, useState } from 'react';
import { useWallet } from '../../store/wallet-context';
import { useQuery } from '@tanstack/react-query';

export function useGetAccountInvitees() {
  const { isConnected } = useWallet();

  const [skip, setSkip] = useState(0);

  const [accountInviteesList, setAccountInviteesList] = useState([]);

  const [accountInviteesMiniList, setAccountInviteesMiniList] = useState([]);

  const [allCount, setAllCount] = useState(0);

  const { data: accountInviteesData, isFetching: accountInviteesLoading } = useQuery({
    queryKey: ['useGetAccountInvitees', skip],
    queryFn: () => {
      return requestAccountInvitees({ take: 10, skip: accountInviteesList?.length ?? 0 });
    },
    enabled: isConnected,
    keepPreviousData: true
  });

  const { data: accountInviteesMiniData, isFetching: accountInviteesMiniLoading } = useQuery({
    queryKey: ['useGetAccountInviteesMini', skip],
    queryFn: () => {
      return requestAccountInviteesMini({ take: 10, skip: accountInviteesMiniList?.length ?? 0 });
    },
    enabled: isConnected
  });

  useEffect(() => {
    setAccountInviteesList([...accountInviteesList, ...(accountInviteesData?.invitees || [])]);
  }, [accountInviteesData?.invitees]);

  useEffect(() => {
    setAccountInviteesMiniList([...accountInviteesMiniList, ...(accountInviteesMiniData?.invitees || [])]);
  }, [accountInviteesMiniData]);

  useEffect(() => {
    setAllCount(accountInviteesData?.count);
  }, [accountInviteesData?.count]);

  const hasMore = useMemo(() => {
    return allCount > accountInviteesList?.length;
  }, [allCount, accountInviteesList?.length]);

  const loadMore = () => {
    setSkip(skip + 1);
  };

  return {
    accountInviteesList,
    accountInviteesMiniList,
    isLoading: accountInviteesLoading && accountInviteesMiniLoading,
    hasMore,
    allCount,
    loadMore
  };
}
