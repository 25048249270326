import { useTranslation } from 'react-i18next';
import { CommonModal } from '../UI/CommonModal';
import styles from './WalletsPanel.module.scss';
import { Box, Flex, Img, useToast } from '@chakra-ui/react';
import { useWeb3Modal } from '@web3modal/react';
// import walletconnectIcon from '../../assets/image/wallet/walletconnect.png';
import tokenPocketIcon from '../../assets/image/wallet/tokenpocket.png';
import metaMaskIcon from '../../assets/image/wallet/metamask.png';
import trustIcon from '../../assets/image/wallet/trust.png';
import okxIcon from '../../assets/image/wallet/okx.png';
import bitgetIcon from '../../assets/image/wallet/bitget.png';
import { bsc } from 'wagmi/chains';
import { useConnect } from 'wagmi';
// import { metaMaskWallet, okxWallet, bitgetWallet, tokenPocketWallet } from '@rainbow-me/rainbowkit/wallets';
import { useIsMobile } from '../../hooks/useIsMobile';
// import { useLocation } from 'react-router-dom';
import {
  getBitgetProvider,
  getOkxProvider,
  getTokenPocketProvider,
  getTrustWalletProvider,
  isBitkeepInstalled,
  isMetamaskInstalled,
  isOkxInstalled,
  isTokenPocketInstalled,
  isTrustWalletInstalled
} from './utils';
import { InjectedConnector } from 'wagmi/connectors/injected';
// import { get } from 'lodash';
import { makeErrorToast } from '../../utils/utils';
// import { log } from 'console';

type Props = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  afterLogin?: () => void;
};

export const WalletsPanel = (props: Props) => {
  const { isOpen, setIsOpen } = props;
  const toast = useToast();
  const isMobile = useIsMobile();
  //@ts-ignore
  const { t } = useTranslation();
  const { open: openWeb3Modal } = useWeb3Modal();
  const chains = [bsc];

  const tryConnectWallet = (connectMethod: any) => {
    return async () => {
      try {
        await connectMethod();
        props.afterLogin?.();
      } catch (e) {
        makeErrorToast(t('Connect wallet failed', 'Connect wallet failed'), toast);
      }
    };
  };
  const metaMaskConnector = new InjectedConnector({ chains });
  const { connectAsync: connectInjected } = useConnect({
    connector: new InjectedConnector({ chains }),
    chainId: bsc.id
  });
  const { connectAsync: connectMetaMask } = useConnect({
    connector: metaMaskConnector,
    chainId: bsc.id
  });
  //https://www.okx.com/web3/build/docs/sdks/web-detect-okx-wallet
  const { connectAsync: connectOkx } = useConnect({
    connector: new InjectedConnector({
      chains,
      options: {
        name: 'okx',
        getProvider: getOkxProvider
      }
    }),
    chainId: bsc.id
  });
  //https://web3.bitget.com/en/docs/guide/wallet/quickly.html#evm
  const { connectAsync: connectBitget } = useConnect({
    connector: new InjectedConnector({
      chains,
      options: {
        name: 'bitget',
        getProvider: getBitgetProvider
      }
    }),
    chainId: bsc.id
  });
  //   https://help.tokenpocket.pro/developer-en/extension-wallet/guide/getting-started
  const { connectAsync: connectTokenPocket } = useConnect({
    connector: new InjectedConnector({
      chains,
      options: {
        name: 'tokenpocket',
        getProvider: getTokenPocketProvider
      }
    }),
    chainId: bsc.id
  });

  // https://developer.trustwallet.com/developer/develop-for-trust/browser-extension/evm
  const { connectAsync: connectTrustWallet } = useConnect({
    connector: new InjectedConnector({
      chains,
      options: {
        name: 'tokenpocket',
        getProvider: getTrustWalletProvider
      }
    }),
    chainId: bsc.id
  });

  const handleConnectMetaMask = async () => {
    if (isMobile && !isMetamaskInstalled()) {
      const deepLink = 'https://metamask.app.link/dapp/prosperex.org';
      window.open(deepLink, '_blank', 'noopener,noreferrer');
    } else {
      tryConnectWallet(connectMetaMask)();
    }
    setIsOpen(false);
  };
  const handleOpenWalletConnect = async () => {
    await openWeb3Modal();
    props.afterLogin?.();
    setIsOpen(false);
  };
  const handleConnectOkx = async () => {
    if (isMobile && !isOkxInstalled()) {
      const deepLink = 'okx://wallet/dapp/details?dappUrl=https://www.prosperex.org';
      window.open(deepLink, '_blank', 'noopener,noreferrer');
    } else {
      tryConnectWallet(connectInjected)();
    }
    connectOkx();
    setIsOpen(false);
  };
  const handleConnectBitget = async () => {
    if (isMobile && !isBitkeepInstalled()) {
      const deepLink = 'bitkeep://bkconnect?action=dapp&url=https://www.prosperex.org';
      window.open(deepLink, '_blank', 'noopener,noreferrer');
    } else {
      tryConnectWallet(connectBitget)();
    }
    setIsOpen(false);
  };


  const handleConnectTokenPocket = async () => {
    if (isMobile && !isTokenPocketInstalled()) {
      // ref: https://github.com/TP-Lab/tp-wallet-sdk
      const deepLinkParams = {
        // url: encodeURI('https://www.prosperex.org'),
        url: encodeURI('http://192.168.51.192:9152'),
        chain: bsc.network,
        source: 'prosperex'
      };
      const param = encodeURIComponent(JSON.stringify(deepLinkParams));
      const deepLink = `tpdapp://open?params=${param}`;
      window.open(deepLink, '_blank', 'noopener,noreferrer');
    } else if (!isMobile && !isTokenPocketInstalled()) {
      makeErrorToast(t('Please install TokenPocket', 'Please install TokenPocket'), toast);
    } else {
      tryConnectWallet(connectTokenPocket)();
    }
    setIsOpen(false);
  };

  const handleConnectTrust = async () => {
    if (isMobile && !isTrustWalletInstalled()) {
      const deepLink = 'https://link.trustwallet.com/open_url?coin_id=714&url=https://www.prosperex.org';
      window.open(deepLink, '_blank', 'noopener,noreferrer');
    } else if (!isMobile && !isTrustWalletInstalled()) {
      makeErrorToast(t('Please install Trust Wallet', 'Please install Trust Wallet'), toast);
    } else {
      tryConnectWallet(connectTrustWallet)();
    }
    setIsOpen(false);
  };

  return (
    <CommonModal isOpen={isOpen} setIsOpen={setIsOpen} className={styles.modal}>
      <Flex className={styles.title}>{t('Connect Wallet')}</Flex>
      <Flex className={styles.walletsPanel}>
        <Box className={styles.walletIcon} onClick={handleConnectMetaMask}>
          <Img src={metaMaskIcon} />
        </Box>
        <Box className={styles.walletIcon} onClick={handleConnectOkx}>
          <Img src={okxIcon} />
        </Box>
        <Box className={styles.walletIcon} onClick={handleConnectBitget}>
          <Img src={bitgetIcon} />
        </Box>
        <Box className={styles.walletIcon} onClick={handleConnectTokenPocket}>
          <Img src={tokenPocketIcon} />
        </Box>
        <Box className={styles.walletIcon} onClick={handleConnectTrust}>
          <Img src={trustIcon} />
        </Box>
      </Flex>
    </CommonModal>
  );
};
