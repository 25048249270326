import { useLocalStorage } from 'usehooks-ts';
import classnames from 'classnames';

export const useTheme = (className: string) => {
  const location = window.location.pathname;
  const isTreasuryPage = location.indexOf('treasury') !== -1;
  const [themeName] = useLocalStorage('color-mode', 'light');
  if (themeName === 'dark' || isTreasuryPage) {
    return classnames(className, 'theme-dark');
  }
  return className;
};
