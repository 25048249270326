import { Box, Flex } from '@chakra-ui/react';
import classnames from 'classnames';
import styles from './Readme.module.scss';
import React from 'react';

export function Readme() {
  // auto play video, loop it
  const handleEnded = () => {
    setTimeout(function () {
      // @ts-ignore
      vRef.current?.play();
    }, 1000);
  };
  const vRef = React.useRef(null);
  return (
    <Flex className={styles.mainFlex}>
      <div id="player">
        <video
          src={'assets/logo.mp4'}
          style={{ objectFit: 'cover' }}
          className={styles.video}
          width="100%"
          height="100%"
          autoPlay
          muted
          playsInline
          onEnded={handleEnded}
          ref={vRef}
        >
          您的浏览器不支持 video 标签。
        </video>
      </div>
      <Flex className={classnames(styles.introFlex, styles.intro1Colors)}>
        <Box className={styles.title}>Unlock more benefits with ProsperEx Early Bird Programme!</Box>
        <Box className={styles.detail}>
          We are calling upon 50 early-bird team leaders to pave the way to prosperity.
        </Box>
        <Box className={styles.detail}>
          By participating in the early bird program, team leaders will enjoy exclusive perks, including a vested
          airdrop of 500,000 PPE tokens.
        </Box>
        <Box className={styles.detail}>To apply for the Early Bird Programme, follow these steps:</Box>
        <Box className={styles.listItem}>
          1.Purchase 10 or more Nodes.
          <br />
          2.Take on the additional responsibility of actively promoting the community.
        </Box>
      </Flex>
      <Flex className={classnames(styles.introFlex, styles.intro2Colors)}>
        <img src={require('../../assets/image/earlybird/logo.png')} alt="earlybird" />
        <Box className={styles.title}>21,000 nodes worldwide.</Box>
        <Box className={styles.detail}>
          Cost: 1,000 USDT, increasing by 50 USDT for every 10% sold. <br />
          Additional nodes for free based on purchase quantity.
          <br />
          After every 10% of the total nodes are sold, the price increases by $50 (e.g., 1-2,100 nodes are sold at
          $1,000 each; 2,101-4,200 nodes are sold at $1,050 each, and so on) until all nodes are sold.
        </Box>
      </Flex>
      <Flex className={classnames(styles.introFlex, styles.intro3Colors)}>
        <img src={require('../../assets/image/earlybird/gift.png')} alt="earlybird" />
        <Box className={styles.title}>Reap the Benefits of Nodes</Box>
        <Box className={styles.detail}>
          <ul>
            <li>Each node receives 10,000 PPE tokens linearly over 18 months.</li>
            <li>Nodes enjoy a 50% discount on exchange transaction fees and commissions on shared trading fees.</li>
            <li>Nodes receive priority airdrops for newly listed tokens on the exchange.</li>
            <li>
              Nodes can participate in Initial Exchange Offerings (IEOs). <br />
            </li>
            <li>Nodes can join in ProsperEx's Decentralized Autonomous Organization (DAO) governance.</li>
          </ul>
        </Box>
        <img src={require('../../assets/image/earlybird/reward.png')} alt="earlybird" />
        <Box className={styles.title}>Advanced Participant Rewards</Box>
        <Box className={styles.detail}>
          <ul>
            <li>
              Purchase{' '}
              <Box className={styles.nodesCount} as="span">
                1-9
              </Box>{' '}
              nodes: No additional nodes as a gift.
            </li>
            <li>
              Purchase{' '}
              <Box className={styles.nodesCount} as="span">
                10-49
              </Box>{' '}
              nodes: Receive an additional 10% of nodes, count in round number.
            </li>
            <li>
              Purchase{' '}
              <Box className={styles.nodesCount} as="span">
                50-99
              </Box>{' '}
              nodes: Receive an additional 20% of nodes, count in round number.
            </li>
            <li>
              Purchase{' '}
              <Box className={styles.nodesCount} as="span">
                100 or more
              </Box>{' '}
              nodes: Receive an additional 30% of nodes, count in round number.
            </li>
          </ul>
        </Box>
      </Flex>
    </Flex>
  );
}
