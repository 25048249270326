import React from 'react';
import styles from './HitoryTable.module.scss';
import { Box, Flex, Tooltip, useMediaQuery, useToast } from '@chakra-ui/react';
import { useTheme } from '../../hooks/useTheme';
import { truncAddress, truncateNumber } from '../../utils/utils';
// import copyIcon from '../../assets/image/common/copy.svg';
// import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

type Item = Array<string | number | undefined | boolean>;
type Prop = {
  headers: string[];

  // 对于 data
  // 买节点历史记录 0： 事件， 1：花费， 2：节点数量
  // 邀请记录 0：被邀请人地址， 1：被邀请人总的花费， 2：被邀请人节点数量
  data: Item[];
  // 默认是买节点历史记录
  isRefTable?: boolean;
};

export default function HistoryTable(prop: Prop) {
  //@ts-ignore
  const { t } = useTranslation();
  const toast = useToast();
  const isMobile = !!useMediaQuery('(max-width: 768px)')[0];
  // const handleCopy = () => {
  //   toast({
  //     description: t('Copied to clipboard', 'Copied to clipboard'),
  //     status: 'success',
  //     duration: 1000,
  //     isClosable: false
  //   });
  // };

  const AddressTooltip = ({ address }: { address: string }) => (
    <Tooltip label={address}>
      <Flex gap={2} justifyContent="center">
        {truncAddress(address)}
        {/* <CopyToClipboard text={address} onCopy={handleCopy}>
          <img className={styles.copyIcon} src={copyIcon} style={{ width: 18, cursor: 'pointer' }} />
        </CopyToClipboard> */}
      </Flex>
    </Tooltip>
  );

  const MobileItem = ({ item }: { item: Item }) => (
    <Flex className={styles.mobileItemFlex}>
      {/* <Box>{item[3]}</Box> */}
      {prop.isRefTable && (
        <Flex className={styles.mobileItemAttr}>
          <Box className={styles.mobileItemAttrLabel}>Address:</Box>
          <Box className={styles.mobileItemAttrValue}>
            <AddressTooltip address={String(item[0])} />
          </Box>
        </Flex>
      )}
      <Flex className={styles.mobileItemAttr}>
        <Box className={styles.mobileItemAttrLabel}>Cost:</Box>
        <Box className={styles.mobileItemAttrValue}>{item[1] ?? '-'}</Box>
      </Flex>
      <Flex className={styles.mobileItemAttr}>
        <Box className={styles.mobileItemAttrLabel}>Node Amount:</Box>
        <Box className={styles.mobileItemAttrValue}>
          {item[3] && !prop.isRefTable && (
            <img src={require('../../assets/image/node/miniNodeIcon.png')} style={{ width: '40px', float: 'right' }} />
          )}
          {item[2] ?? '-'}
        </Box>
      </Flex>
      {prop.isRefTable && (
        <Flex className={styles.mobileItemAttr}>
          <Box className={styles.mobileItemAttrLabel}> Mini Node Amount:</Box>
          <Box className={styles.mobileItemAttrValue}>
            {/* <img src={require('../../assets/image/node/miniNodeIcon.png')} style={{ width: '40px', float: 'right' }} /> */}
            {item[3]}
          </Box>
        </Flex>
      )}
    </Flex>
  );
  return (
    <Box className={useTheme(styles.container)}>
      {!isMobile && (
        <table className={styles.table}>
          <thead>
            <tr>
              {prop.headers.map((header, index) => (
                <th key={`header-${header}-${index}`}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {prop.data.map((row, index) => (
              <tr key={`row-${row[0]}-${index}`}>
                <td>{prop.isRefTable ? <AddressTooltip address={String(row[0])} /> : row[0]}</td>
                <td>{truncateNumber(row[1] as string | number, 2) ?? '-'}</td>
                <td style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                  {row[3] && !prop.isRefTable && (
                    <img
                      src={require('../../assets/image/node/miniNodeIcon.png')}
                      style={{ width: '40px', float: 'right' }}
                    />
                  )}
                  {row[2] ?? '-'}
                </td>
                {prop.isRefTable && (
                  <td>
                    {/* <img
                      src={require('../../assets/image/node/miniNodeIcon.png')}
                      style={{ width: '40px', float: 'left' }}
                    /> */}
                    {row[3]}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {isMobile && (
        <Flex className={styles.mobileItemContainer}>
          {prop.data.map((row, index) => (
            <MobileItem item={row} key={`mobile-row-${row[0]}-${index}`} />
          ))}
        </Flex>
      )}
    </Box>
  );
}
